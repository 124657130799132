<h1 style="padding-top: 4pt;padding-left: 116pt;text-indent: 0pt;text-align: center;">Artiox Çerez Aydınlatma Metni</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol id="l1">
    <li data-list-text="1."><h3 style="padding-left: 15pt;text-indent: -10pt;text-align: justify;">Giriş</h3>
        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;"><a href="http://www.artiox.com/"
                                                                                                                 style=" color: black; font-family:Montserrat, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt;"
                                                                                                                 target="_blank">Web sitemiz, </a><span
            style=" color: #1154CC; font-family:Montserrat, serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">www.artiox.com</span> (Bundan böyle
            “site”olarak anılacaktır.) çerezleri ve diğer benzer teknolojileri (Bundan böyle “çerezler” olarak anılacaktır) kullanmaktadır. Çerezler, etkileşimde olduğumuz üçüncü taraflardan da
            gelebilir. Bu dökümanda size çerez kullanımımız ile ilgili <b>6698 Sayılı Kişisel Verilerin Korunması Kanunu (KVKK) </b>ve <b>Avrupa Veri Koruma Tüzüğü (GDPR) </b>kapsamında detaylı bilgi
            vereceğiz.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="2."><h3 style="padding-left: 17pt;text-indent: -12pt;text-align: justify;">Çerez Nedir?</h3>
        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Çerez, bu web sitesinin sayfalarıyla birlikte gönderilen ve tarayıcınız tarafından
            bilgisayarınızın sabit sürücüsünde veya başka bir cihazda saklanan küçük, basit bir dosyadır. Burada saklanan bilgiler bir sonraki ziyaret sırasında sunucularımıza veya ilgili üçüncü
            tarafların sunucularına iletilebilir.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="3."><h3 style="padding-left: 17pt;text-indent: -12pt;text-align: justify;">Açık Rıza</h3>
        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Web sitemizi ilk kez ziyaret ettiğinizde, size çerezler hakkında bir açıklama içeren
            pencere göstereceğiz. “Çerez Aydınlatma Metni” Düğmesini tıklattığınızda, burada açıklandığı gibi, açılır pencerede sadece zorunlu çerezleri kullandığımızı göreceksiniz. <b>Çerez
                kullanımını tarayıcınız üzerinden devre dışı bırakabilirsiniz, ancak web sitemizin çalışması için gerekli zorunlu çerezler etkili olmaya devam edecektir. Eğer bunu da istemiyorsanız
                siteyi ziyareti sonlandırmalısınız.</b></p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="4."><h3 style="padding-left: 18pt;text-indent: -13pt;text-align: justify;">Çerezler</h3>
        <ol id="l2">
            <li data-list-text="4.1"><h3 style="padding-top: 1pt;padding-left: 22pt;text-indent: -17pt;text-align: justify;">Zorunlu(Fonksiyonel) Çerezler :</h3>
                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Bazı çerezler, web sitesinin belirli bölümlerinin düzgün çalışmasını ve kullanıcı
                    tercihlerinizin bilinmesini sağlar. İşlevsel çerezler yerleştirerek web sitemizi ziyaret etmenizi kolaylaştırıyoruz. Bu çerezleri açık rızaya gerek duymaksızın, <b>Kişisel
                        Verilerin Korunması Kanunu 5/2-e ve f uyarınca, Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, İlgili kişinin temel hak ve özgürlüklerine
                        zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine dayanarak kullanıyoruz. </b>Aydınlatma metnimizin 5.
                    maddesinde mevcut çizelgelerde hangi zorunlu çerezleri kullandığımızı göstermekteyiz.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="5."><h3 style="padding-left: 17pt;text-indent: -12pt;text-align: justify;">Sitemizde Kullanılan Çerezler</h3>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l3">
            <li data-list-text="1-"><h3 style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">loggedIn çerezi, kullanıcı <span class="p">siteye üye giriş yaptığında kurulur. Sayfanın yeniden yüklendiğinde, kullanıcının tekrar üye girişi yapmasına gerek kalmaz.</span>
            </h3></li>
            <li data-list-text="2-"><h3 style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">sessionKey çerezi<span class="p">, kullanıcı oturum açtığında onun adına ayarlanan çerezdir. Güvenlik için kullanılan anahtar kodudur. Kullanıcılar login olduktan sonra onların adına oturumun açık kalma süresini belirler.</span>
            </h3></li>
            <li data-list-text="3-"><h3 style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">clientIP çerezi, <span class="p">kullanıcının güvenliği için ayarlanan bir çerezdir. Kullanıcıların her üye girişi yaptığında IP’lerini saklayarak, farklı IP algılandığında kullanıcıya uyarı göndermek için kullanılır.</span>
            </h3></li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="6."><h3 style="padding-left: 18pt;text-indent: -12pt;text-align: left;">Haklarınız</h3>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Veri Sorumlusu Artiox Teknoloji Yazılım Anonim Şirketi <span class="p">tarafından verilerinizin işlendiği ve Veri Sorumlusunun verilerinizi veri sorumlusu sıfatı ile işlediği ölçüde kişisel verileriniz bakımından aşağıda bulunan haklara sahipsiniz:</span>
        </h3>
        <ul id="l4">
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -17pt;line-height: 13pt;text-align: left;">Herhangi bir kişisel verinizin işlenip işlenmediğini öğrenme;</p></li>
            <li data-list-text="●"><p style="padding-top: 1pt;padding-left: 41pt;text-indent: -17pt;text-align: left;">Kişisel verilerinizin işlenme faaliyetlerine ilişkin olarak bilgi talep etme;</p>
            </li>
            <li data-list-text="●"><p style="padding-top: 1pt;padding-left: 41pt;text-indent: -17pt;text-align: left;">Kişisel verilerinizin işlenme amaçlarını öğrenme;</p></li>
            <li data-list-text="●"><p style="padding-top: 1pt;padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Kişisel verilerin yurt içinde veya yurt dışında üçüncü
                kişilere aktarılmış olması durumunda bu kişileri öğrenme;</p></li>
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların
                düzeltilmesini isteme;</p></li>
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması veya
                Şirket’in söz konusu verileri işleyebilmek için hukuki dayanağı veya meşru menfaatinin bulunmaması halinde kişisel verilerin silinmesini veya yok edilmesini isteme;</p></li>
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Şirket’ten, yine Şirket tarafından yetkilendirilen ve kişisel verileri
                işleyen üçüncü kişilerin bu bölüm kapsamındaki haklarınıza saygı duymasını sağlamasını talep etme;</p></li>
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Kişisel verilerin otomatik sistemler vasıtasıyla işlenmesi sonucu ortaya
                çıkabilecek aleyhte sonuçlara itiraz etme;</p></li>
            <li data-list-text="●"><p style="padding-left: 41pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Kişisel verilerinizin kanuna aykırı bir şekilde işlenmesi sebebiyle zarara
                uğramanız halinde bu zararın tazmin edilmesini isteme.</p></li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Veri sorumlusuna Başvuru<span class="p">- Kanunun ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi, “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre Veri Sorumlusunun fiziki adresine adresine yazılı olarak veya üyeliğinizin teyit edildiği elektronik posta üzerinden  elektronik posta adresimize iletebilirsiniz.</span>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="7."><h3 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">Çerezleri Devre Dışı Bırakma</h3>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">İnternet tarayıcınızı çerezleri otomatik veya manuel olarak silmek için kullanabilirsiniz. Ayrıca belirli
            çerezlerin yerleştirilemeyeceğini de belirtebilirsiniz. Başka bir seçenek, internet tarayıcınızın ayarlarını değiştirerek her çerez yerleştirildiğinde bir mesaj almanızı sağlamaktır. Bu
            seçenekler hakkında daha fazla bilgi için lütfen tarayıcınızın Yardım bölümündeki talimatlara bakın. Tüm çerezler devre dışı bırakılırsa web sitemizin düzgün çalışmayabileceğini lütfen
            unutmayın.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Bazı tarayıcılardan çerezlere ilişkin ayarları yapabilmeniz adına bağlantılar aşağıdaki gibidir:</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse;margin-left:5.43545pt">
            <tr style="height:56pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Adobe Analytics</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;"><a class="s4" href="http://www.adobe.com/uk/privacy/opt-out.html" target="_blank">https://www.adobe.com/uk/privacy/opt-out.html</a>
                    </p></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Google Adwords</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;line-height: 112%;text-align: left;">https://support.google.com/ads/answer/2662922?hl=
                        tr</p></td>
            </tr>
            <tr style="height:26pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Google Analytics</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">https://tools.google.com/dlpage/gaoptout</p></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Google Chrome</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;line-height: 112%;text-align: left;">https://support.google.com/chrome/answer/95647? hl=tr</p></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Internet Explorer</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;padding-right: 9pt;text-indent: 0pt;line-height: 112%;text-align: left;">https://support.microsoft.com/tr-tr/help/17442/wind
                        ows-internet-explorer-delete-manage-cookies</p></td>
            </tr>
            <tr style="height:56pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Mozilla Firefox</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;line-height: 112%;text-align: left;">
                        https://support.mozilla.org/tr/kb/cerezler-web-siteler inin-bilgisayarinizda-depoladi?redirectlocale=en-US &amp;redirectslug=Cookies</p></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Opera</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;line-height: 112%;text-align: left;">https://help.opera.com/en/latest/web-preferences/# cookies</p></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:151pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Safari</p></td>
                <td style="width:299pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;line-height: 112%;text-align: left;">https://support.apple.com/tr-tr/guide/safari/sfri11471/ mac</p></td>
            </tr>
        </table>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="8."><h3 style="padding-top: 5pt;padding-left: 18pt;text-indent: -13pt;text-align: left;">Veri Sorumlusu Bilgileri</h3></li>
</ol>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: left;">Çerez Aydınlatma Metni ve bu bildirimle ilgili sorularınız ve / veya yorumlarınız için lütfen aşağıdaki iletişim
    bilgilerini kullanarak bizimle iletişime geçin:</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Şirket Unvanı: Artiox Teknoloji Yazılım Anonim Şirketi</h2>
<h2 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 113%;text-align: left;">Adres: <span class="s5">Mustafa Kemal Mah. Dumlupınar Blv. No: 280 G İç Kapı No: 1233 Çankaya/ Ankara</span>
</h2>
<p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;"><a class="a" href="http://www.artiox.com/" target="_blank">Site: </a><a href="http://www.artiox.com/" target="_blank">www.artiox.com</a>
</p>
<h2 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 112%;text-align: left;"><a class="a" href="mailto:info@artiox.com" target="_blank">E Posta: </a>info&#64;artiox.com Telefon:
</h2>
