import {announcements_config_tr} from './announcements_config_tr';
import {announcements_titles} from './announcements_router';
import {art_products_config_tr} from './art_products_config_tr';
import {auction_config_tr} from './auction_config_tr';
import {artists_config_tr} from './artists_config_tr';

export const config_tr = {
  ZENDESK_ACTIVE: announcements_titles.zendesk_active,
  COOKIE: {
    HEADER: 'Çerez Politikası',
    BODY: 'Web sitemizdeki içeriklerden en iyi şekilde yararlanmanızı sağlamak için 6698 Sayılı Kişisel Verilerin Korunması Kanunu\'na (KVKK)' +
      ' ve Avrupa Birliği Veri Koruma Tüzüğü\'ne (GDPR) uygun olarak çerezleri kullanıyoruz.' +
      ' Yalnızca kullanıcı güvenliği, web sitemizin düzgünce çalışabilmesi ve kullanılabilmesi için zorunlu olan çerezleri kullanıyoruz.' +
      ' Detaylı bilgi için <a class="cookie-clarification-text" href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Çerez Aydınlatma Metni</a>\'ni inceleyebilirsiniz.' +
      ' Bu çerezlerin kullanımını onaylamıyorsanız ziyaretinizi sonlandırmalısınız.',
    BODY_SHORT: 'Yalnızca kullanıcı güvenliği, web sitemizin düzgünce çalışabilmesi ve kullanılabilmesi için zorunlu olan çerezleri kullanıyoruz.' +
      ' Detaylı bilgi için <a class="cookie-clarification-text" href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Çerez Aydınlatma Metni</a>\'ni inceleyebilirsiniz.' +
      ' Bu çerezlerin kullanımını onaylamıyorsanız ziyaretinizi sonlandırmalısınız.',
    ACCEPT: 'Kabul Et',
    DENY: 'Reddet'
  },
  HEADER: {
    SIGNUP: 'Üye Ol',
    LOGIN: 'Giriş Yap',
    LOGIN_SHORT: 'Giriş',
    EXCHANGE: 'Parçalı Al - Sat',
    BASIC: 'Kolay',
    ADVANCED: 'Gelişmiş',
    BASIC_TEXT: 'Dilediğin sanat eserini kolayca al-sat',
    ADVANCED_TEXT: 'Gelişmiş borsa arayüzüyle derinlemesine işlem yap',
    ACADEMY: 'Akademi',
    AUCTION: 'Müzayede',
    CATALOG: 'Katalog',
    DAILY: 'Günlük',
    CATALOG_TEXT: 'Kataloglardaki sanat eserlerine teklif ver',
    DAILY_TEXT: 'Günlük müzayedelerdeki sanat eserlerine teklif ver',
    MENU: 'Menü',
    ADMIN: 'Yönetici',
    DASHBOARD: 'Panel',
    USER_INFORMATION: 'Kullanıcı Bilgilerim',
    USER_INFORMATION_SHORT: 'Bilgilerim',
    WALLET: 'Cüzdan',
    DEPOSIT: 'Yatırma',
    WITHDRAW: 'Çekme',
    SECURITY: 'Güvenlik',
    ARTIOX: 'Artiox',
    ARTIOX_LINK: {url: '/guidebook', current_component_index: 0},
    ANNOUNCEMENTS: 'Duyurular',
    ANNOUNCEMENTS_LINK: {
      url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular',
      is_inner: false,
      target: '_blank'
    },
    BLOG: 'Artiox Blog',
    BLOG_LINK: {
      url: 'https://support.artiox.com/hc/tr/categories/4414328818577-Artiox-Blog',
      is_inner: false,
      target: '_blank'
    },
    ABOUT_ARTISTS: 'Sanatçılar Hk.',
    ABOUT_ARTISTS_LINK: {url: '/about-artist', current_component_index: 0},
    SUPPORT: 'Destek',
    SUPPORT_LINK: {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_blank'},
    LOGOUT: 'Çıkış Yap',
    LANGUAGES: [
      {SHORT: 'TR', FULL_NAME: 'Türkçe'},
      {SHORT: 'EN', FULL_NAME: 'İngilizce'}
    ],
    ACCOUNT_LEVEL: 'Hesap Seviyesi',
    GUIDEBOOK: 'Rehber',
    ANNOUNCEMENT: {
      TEXT: 'Ergin İnan’ın &nbsp  <span class="exo subtitle1 bold"> “Yüzler” </span> &nbsp adlı eseri 18 Ekim’de Genel Satışta. Bu fırsatı kaçırma  🖼️',
      LINK: {url: '/exchange/advanced', current_component_index: 0}
    },
    // CHANGE_LANGUAGE: 'Dil Seçeneği',
    // VERSION: 'Sürüm ',
    OR: ' veya ',
    TO_INSTALL_APP: 'Uygulama ile devam etmek için',
    LATER: 'Sonra',
    INSTALL: 'Uygulamaya Git'
  },
  FOOTER: {
    // ADDRESS_HEADER: 'ARTIOX',
    ADDRESS: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş. Mustafa Kemal Mahallesi Bilişim İnovasyon Merkezi ODTÜ Teknokent 280, D:G, 06510 Çankaya/Ankara',
    // MAIL: 'info@artiox.com',
    // ARTIOX: 'Artiox',
    // ARTIOX_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414391792401-Artiox-Rehberi', is_inner: false, target: '_blank'},
    // INFO_BANK: 'Bilgi Bankası',
    // INFO_BANK_LINK: {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_blank'},
    LEGAL: {
      HEADING: 'Yasal',
      LINKS: [
        {
          TEXT: 'Kullanıcı Sözleşmesi',
          URL: {url: 'https://www.artiox.com/legal/user-agreement', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'KVKK Politikası',
          URL: {url: 'https://www.artiox.com/legal/protection-law-policy', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Bilgi Güvenliği Politikası',
          URL: {url: 'https://www.artiox.com/legal/information-security-policy', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Kişisel Veri Saklama ve İmha Politikası',
          URL: {url: 'https://www.artiox.com/legal/data-deletion-policy', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Kişisel Veri Sahibi Başvuru Formu',
          URL: {url: 'https://www.artiox.com/legal/kvkk-application-form', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Çerez Aydınlatma Metni',
          URL: {url: 'https://www.artiox.com/legal/cookie-clarification-text', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'KVKK Aydınlatma Metni',
          URL: {url: 'https://www.artiox.com/legal/kvkk-clarification', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Pazarlama Açık Rıza Metni',
          URL: {url: 'https://www.artiox.com/legal/marketing-consent', is_inner: false, target: '_blank'}
        },
        {
          TEXT: 'Yurtdışı Açık Rıza Metni',
          URL: {url: 'https://www.artiox.com/legal/foreign-consent', is_inner: false, target: '_blank'}
        },

        {
          TEXT: 'Komisyonlar',
          URL: {url: 'https://www.artiox.com/legal/commissions', is_inner: false, target: '_blank'}
        },
        {TEXT: 'Limitler', URL: {url: 'https://www.artiox.com/legal/limits', is_inner: false, target: '_blank'}},
      ],
    },
    ARTIOX_SECTION: {
      HEADER: 'Artiox',
      BASIC: 'Basit Al - Sat',
      ADVANCED: 'Gelişmiş Al - Sat',
      CATALOG: 'Katalog Müzayede',
      DAILY: 'Günlük Müzayede',
      SEND_ARTWORK: 'Eser Gönder',
    },
    ARTISTS: {
      HEADER: 'Sanatçılar',
      ALL_ARTISTS: 'Tüm Sanatçılar...',
    },
    AUCTION: {
      HEADER: 'Müzayede',
      DISTANCE_SELLING: 'Mesafeli Satiş Sözleşmesi',
      AUCTION_CANCELLATION: 'Müzayede İptal ve İade Kuralları',
      AUCTION_USER_AGREEMENT: 'Müzayede Kullanıcı Sözleşmesi',
      AUCTION_RULES: 'Müzayede Kuralları',
    },
    // LEGAL_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414300039953-Yasal', is_inner: false, target: '_blank'},
    // ANNOUNCEMENTS: 'Duyurular',
    // ANNOUNCEMENTS_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular', is_inner: false, target: '_blank'},
    // ABOUT_ARTISTS: 'Sanatçılar Hk.',
    // ABOUT_ARTISTS_LINK: {url: '/about-artist', current_component_index: 0},
    // CAREER: 'Kariyer',
    // CAREER_LINK: {url: '/career', current_component_index: 0},
    // CORPORATE_HEADER: 'Kurumsal',  // obsolete
    // CORPORATE_BODY: [
    //     {TEXT: 'Hakkımızda', LINK: {url: '/corporate/about', current_component_index: 0}, HREF: '/corporate/about', INNER: true, TARGET: '_blank'},
    //     {TEXT: 'Duyurular', LINK: {url: '/corporate/announcements', current_component_index: 0}, HREF: '/corporate/announcements', INNER: true, TARGET: '_blank'},
    //     {TEXT: 'Kariyer', LINK: {url: '/corporate/career', current_component_index: 0}, HREF: '/corporate/career', INNER: true, TARGET: '_blank'},
    //     {TEXT: 'İletişim', LINK: {url: '/corporate/contact', current_component_index: 0}, HREF: '/corporate/contact', INNER: true, TARGET: '_blank'},
    // ],  // obsolete
    // LEGAL_HEADER: 'Yasal',  // obsolete
    // LEGAL_BODY: [
    //     '<a href="./assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank">Kullanıcı Sözleşmesi</a>',
    //     '<a href="./assets/pdfs/Artiox KVKK Politikası.pdf" target="_blank">Kişisel Verilerin Korunması Politikası</a>',
    //     '<a href="./assets/pdfs/Artiox Bilgi Güvenliği Politikası.pdf" target="_blank">Bilgi Güvenliği Politikası</a>',
    //     '<a href="./assets/pdfs/Artiox Kişisel Veri Saklama ve İmha Politikası.pdf" target="_blank">İmha Politikası</a>',
    //     '<a href="./assets/pdfs/Artiox Kişisel Veri Sahibi Başvuru Formu.pdf" target="_blank">KVKK İlgili Kişi Başvuru Formu</a>',
    //     '<a href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Çerez Aydınlatma Metni</a>',
    // ],
    // SERVICE_HEADER: 'Hizmet',
    // SERVICE_BODY: [
    //     {TEXT: 'Limitler ve Kurallar', LINK: {url: '/service/rules', current_component_index: 0}, HREF: '/service/rules', INNER: true, TARGET: '_blank'},
    //     {TEXT: 'Komisyonlar', LINK: {url: '/service/commissions', current_component_index: 0}, HREF: '/service/commissions', INNER: true, TARGET: '_blank'},
    // ],
    // SUPPORT_HEADER: 'Destek',
    // SUPPORT_BODY: [
    //     '<a href="https://support.artiox.com/hc/tr" target="_blank" rel="noreferrer">Yardım Merkezi</a>',
    //     '<a href="https://support.artiox.com/hc/tr" target="_blank" rel="noreferrer">S.S.S.</a>',
    // ],
    RIGHTS: '© current_year Artiox Kripto Varlık Alım Satım Platformu A.Ş. - Tüm Hakları Saklıdır.',
    VERSION: 'Versiyon: 2.0.2 Son Güncelleme: 04.12.2024',
    // RIGHTS_MOBILE: '© 2019 - current_year Artiox.com.<br> Tüm Hakları Saklıdır.<br><small>Versiyon: 2.0.0<br>Son Güncelleme: 12.10.2023</div>',
  },
  ANNOUNCEMENTS_SLIDER: [
    // {
    //   HEADING: 'BLOG',
    //   TOPIC: 'Osman Hamdi Bey Hk.',
    //   TEXT: 'Osman Hamdi Bey Osman Hamdi Bey Osman Hamdi Bey',
    //   IMAGE_SRC: './assets/artists/test_artist_3.jpg',
    //   TEXT_LIMIT_DESKTOP: 30,
    //   TEXT_LIMIT_MOBILE: 30,
    //   LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
    //   RELEASE_DATE: 0,
    // },
    // {
    //   HEADING: 'BLOG',
    //   TOPIC: 'Gustav Klimt Hk.',
    //   TEXT: 'Gustav Klimt Gustav Klimt Gustav Klimt Gustav Klimt',
    //   IMAGE_SRC: './assets/artists/test_artist_2.jpg',
    //   TEXT_LIMIT_DESKTOP: 30,
    //   TEXT_LIMIT_MOBILE: 30,
    //   LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
    //   RELEASE_DATE: 0,
    // },
    // {
    //   HEADING: 'BLOG',
    //   TOPIC: 'Vincent Van Gogh Hk.',
    //   TEXT: 'Vincent Van Gogh Vincent Van Gogh Vincent Van Gogh',
    //   IMAGE_SRC: './assets/artists/test_artist_1.jpg',
    //   TEXT_LIMIT_DESKTOP: 30,
    //   TEXT_LIMIT_MOBILE: 30,
    //   LINK: {url: '/about-artist', artist_announcement_index: 10, current_component_index: 0},
    //   RELEASE_DATE: 0,
    // },
    {
      HEADING: 'BLOG',
      TOPIC: 'Ergin İnan Hk.',
      TEXT: 'Ergin İnan, uluslararası tanınırlığa sahip çok sayıda sergilerde bulunmuş ve ödüle layık görülmüş bir ressamdır.' +
        ' Çağdaş Türk sanatında alışagelmedik bir yaklaşıma sahip sanatçı, eserlerinde genelde karışık medyum ve baskı teknikleri kullanarak kavramsal çalışmalar yapmıştır.' +
        ' Ergin İnan’ın eserlerinde sık rastlanan imgelere; soyutlanmış insan figürleri, yazı imgeleri ve böcek tasvirleri örnek gösterilebilir.',
      IMAGE_SRC: './assets/artists/ergin_inan.webp',
      TEXT_LIMIT_DESKTOP: 73,
      TEXT_LIMIT_MOBILE: 73,
      LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Ahmet Yeşil Hk.',
      TEXT: 'Ahmet Yeşil, 1954 yılında Mersin\'de doğdu ve burada büyüdü.' +
        ' Sanat hayatına Mersin\'de başlayan ve bu şehirde yaşamını sürdüren Yeşil, resim eğitimini Ressam Nuri Abaç, İlhan Çevik ve Heykeltraş Ernür Tüzün gibi önemli isimlerden aldı.',
      IMAGE_SRC: './assets/artists/ahmet_yesil.webp',
      TEXT_LIMIT_DESKTOP: 74,
      TEXT_LIMIT_MOBILE: 74,
      LINK: {url: '/about-artist', artist_announcement_index: 17, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Özdemir Altan Hk.',
      TEXT: '1931’de Giresun’da doğan Özdemir Altan, İstanbul Devlet Güzel Sanatlar Akademisi Resim Bölümü’nden 1956’da mezun oldu.' +
        ' Mezunu olduğu okulda 1956 yılında akademisyen olarak çalışmaya  başladı ve 1988’deki emekliliğine kadar bu görevini sürdürdü.',
      IMAGE_SRC: './assets/artists/ozdemir_altan.webp',
      TEXT_LIMIT_DESKTOP: 98,
      TEXT_LIMIT_MOBILE: 98,
      LINK: {url: '/about-artist', artist_announcement_index: 16, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Devrim Erbil Hk.',
      TEXT: 'Devrim Erbil, ulusal ve uluslararası birçok sergide yer almış ve ödüle layık bulunmuş ressam ve' +
        ' akademisyendir. Yağlıboya, baskı, vitray, mozaik gibi pek çok medyum ile eserler üretmiştir. Devrim Erbil’in' +
        ' sanatı; çağdaş sanatı gelenekle bağlantılı bir temele oturtmak isteyen yöresel kaynaklı eğilimler içinde' +
        ' değerlendirilebilir.',
      IMAGE_SRC: './assets/artists/devrim_erbil.webp',
      TEXT_LIMIT_DESKTOP: 64,
      TEXT_LIMIT_MOBILE: 64,
      LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Mustafa Ata Hk.',
      TEXT: '18 Mart 1945 tarihinde Trabzon’da doğan Mustafa Ata,' +
        ' Mimar Sinan Güzel Sanatlar Fakültesi (o zamanki adıyla İstanbul Devlet Güzel Sanatlar Akademisi) Resim Bölümü Adnan Çoker Atölyesi’nde öğrenim gördü.',
      IMAGE_SRC: './assets/artists/mustafa_ata.webp',
      TEXT_LIMIT_DESKTOP: 89,
      TEXT_LIMIT_MOBILE: 89,
      LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Mona Lisa Dünyanın En Ünlü Tablosu Olmasını İtalyan Bir Suçluya Borçlu Olabilir mi?',
      TEXT: 'Leonardo da Vinci\'nin La Gioconda olarak da bilinen Mona Lisa tablosu, dünyanın en ünlü tablosudur.',
      IMAGE_SRC: './assets/general_operations/tr/34.webp',
      TEXT_LIMIT_DESKTOP: 21,
      TEXT_LIMIT_MOBILE: 21,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/8727098544157-Mona-Lisa-D%C3%BCnyan%C4%B1n-En-%C3%9Cnl%C3%BC-Tablosu-Olmas%C4%B1n%C4%B1-%C4%B0talyan-Bir-Su%C3%A7luya-Bor%C3%A7lu-Olabilir-mi-',
          is_inner: false,
          target: '_blank'
        }
        : {url: '/corporate/general-announcements', current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Nuri İyem Hk.',
      TEXT: 'Çocukken ölümüne tanık olduğu ablasının gözlerinden ve yüzünden esinlenen sanatçı Nuri İyem,' +
        ' sanatının ikonik ve ayrılmaz bir parçası haline gelen \'çekingen, güzel ve melankolik\' yüzlerin ressamıdır.',
      IMAGE_SRC: './assets/artists/nuri_iyem.webp',
      TEXT_LIMIT_DESKTOP: 81,
      TEXT_LIMIT_MOBILE: 81,
      LINK: {url: '/about-artist', artist_announcement_index: 14, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Ömer Uluç Hk.',
      TEXT: '1931 yılında doğan Ömer Uluç dünya çapında ünlü bir Türk ressamdır.' +
        ' Farklı ülkelerde 30’u aşkın kişisel sergi açmıştır ve katıldığı organizasyonlarda sunduğu eserleriyle tüm dünyayı kendine hayran bırakmıştır.',
      IMAGE_SRC: './assets/artists/omer_uluc.webp',
      TEXT_LIMIT_DESKTOP: 84,
      TEXT_LIMIT_MOBILE: 84,
      LINK: {url: '/about-artist', artist_announcement_index: 13, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'DUYURU',
      TOPIC: 'Yatırım Turu',
      TEXT: '2019 yılında Tuncay Dakdevir ve Cemal Doğan tarafından kurulan Artiox, 15 Milyon Dolar değerleme üzerinden 1 Milyon Dolar yatırım aldı.',
      IMAGE_SRC: './assets/general_operations/tr/33.webp',
      TEXT_LIMIT_DESKTOP: 69,
      TEXT_LIMIT_MOBILE: 69,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/7203761619613-Hedef-GSYO-ve-RePie',
          is_inner: false,
          target: '_blank'
        }
        : {url: '/corporate/general-announcements', current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Haluk Akakçe Hk.',
      TEXT: 'Haluk Akakçe çağdaş sanat dünyasının en önemli isimlerinden, her sergisi bir olay… Sıradışı, yaratıcı ve dahi…',
      IMAGE_SRC: './assets/artists/haluk_akakce.webp',
      TEXT_LIMIT_DESKTOP: 82,
      TEXT_LIMIT_MOBILE: 82,
      LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Fahrelnissa Zeid Hk.',
      TEXT: '7 Ocak 1901’de Büyükada’da doğan ressam Fahrelnissa Zeid, pek çok sanatçı yetiştirmiş Kabaağaçlı ailesindendir.' +
        ' Ailenin geçmişi asker kökenli olsa da Kabaağaçlı ailesinin üyeleri sanatın farklı dallarında isimlerini tarihe yazdırmıştır.' +
        ' Halikarnas Balıkçısı Cevat Şakir’in ve ressam Aliye Berger’in kardeşi, oyuncu Şirin Devrim ve ressam Nejad Devrim’in annesi, ilk kadın seramik sanatçımız Füreya Koral’ın teyzesi olan' +
        ' ünlü ressam Fahrelnissa Zeid sanatı ve karakteriyle adını tüm dünyaya duyurmuş yetenekli bir Türk kadınıdır.',
      IMAGE_SRC: './assets/artists/fahrelnissa_zeid.webp',
      TEXT_LIMIT_DESKTOP: 85,
      TEXT_LIMIT_MOBILE: 85,
      LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Atomsal Leda’nın Hikayesi',
      TEXT: 'Klasik mitolojiye göre, tanrı Zeus, Aetolia kralının kızı Leda’ya aşıktır fakat Leda Sparta’nın kralı Tyndareus ile evlenecektir.' +
        'Zeus güzel Leda’yla birlikte olabilmenin planlarını yapmaktadır.',
      IMAGE_SRC: './assets/general_operations/tr/31.webp',
      TEXT_LIMIT_DESKTOP: 79,
      TEXT_LIMIT_MOBILE: 79,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/5930360872209-Atomsal-Leda-n%C4%B1n-Hikayesi',
          is_inner: false,
          target: '_blank'
        }
        : {url: '/corporate/general-announcements', current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Van Gogh Kulağını Neden Kesti?',
      TEXT: 'Ünlü Hollandalı ressam Vincent Van Gogh, 1888 yılının Aralık ayında 35 yaşındayken Fransa’nın Arles şehrinde keskin bir jiletle sol kulağını kesti.',
      IMAGE_SRC: './assets/general_operations/tr/30.webp',
      TEXT_LIMIT_DESKTOP: 39,
      TEXT_LIMIT_MOBILE: 39,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/5461294836753-Van-Gogh-Kula%C4%9F%C4%B1n%C4%B1-Neden-Kesti-',
          is_inner: false,
          target: '_blank'
        }
        : {url: '/corporate/general-announcements', current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'En ünlü savaş karşıtı tablo: Guernica',
      TEXT: '26 Nisan 1937’de Nazi ve İtalyan kuvvetleri yeni uçaklarıyla İspanya’nın Guernica kasabasını bombalamaya başlamıştır.' +
        ' Bu saldırı yeni bir bombalama taktiğini test etmek için tasarlanmış soğukkanlı bir eğitim görevidir ve tarihte sivil halka karşı havadan yapılan ilk bombardımandır.',
      IMAGE_SRC: './assets/general_operations/tr/29.webp',
      TEXT_LIMIT_DESKTOP: 48,
      TEXT_LIMIT_MOBILE: 48,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/5082128068753-En-%C3%BCnl%C3%BC-sava%C5%9F-kar%C5%9F%C4%B1t%C4%B1-tablo-olan-Guernica-nin-hikayesini-biliyor-musunuz-',
          is_inner: false, target: '_blank'
        }
        : {url: '/corporate/general-announcements', current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Pablo Picasso Hk.',
      TEXT: '20. yüzyılın en büyük ve en etkili sanatçılarından biri olan Pablo Picasso, İspanya\'nın Malaga kentinde doğdu.' +
        'Picasso\'nun babası bir çizim profesörüydü ve oğlunu akademik sanat alanında bir kariyer için yetiştirdi.' +
        ' Picasso ilk sergisini 13 yaşında açtı ve daha sonra modern sanat tarzlarıyla tam zamanlı deneyler yapabilmek için sanat okulunu bıraktı.',
      IMAGE_SRC: './assets/artists/pablo_picasso.webp',
      TEXT_LIMIT_DESKTOP: 74,
      TEXT_LIMIT_MOBILE: 74,
      LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Değerini 35 Kat Artıran Eser: Four Marilyns',
      TEXT: 'Andy Warhol’un “Four Marilyns” resmini hepimiz biliriz.' +
        ' Pop art akımının en bilinen çalışmalarından biri olan bu resim, hayatımız boyunca defalarca karşımıza çıkmıştır.',
      IMAGE_SRC: './assets/general_operations/tr/28.webp',
      TEXT_LIMIT_DESKTOP: 83,
      TEXT_LIMIT_MOBILE: 83,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4420356336529-De%C4%9Ferini-Zaman-%C4%B0%C3%A7inde-20-Kat-Art%C4%B1ran-Andy-Warhol-Eseri-Four-Marilyns',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('degerini_20_kat_artiran_eser_four_marilyns'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'NFT Sanat Eseri Satışları 3.5 Milyar Dolara Ulaştı',
      TEXT: 'Muhafazakâr sanat çevreleri tarafından bir balon olarak görülen NFT’lerin, 2021 yılında yarattığı ekonomi 3,5 milyar dolar seviyesine ulaştı.',
      IMAGE_SRC: './assets/general_operations/tr/27.webp',
      TEXT_LIMIT_DESKTOP: 38,
      TEXT_LIMIT_MOBILE: 38,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414481722513-NFT-Sanat-Eseri-Sat%C4%B1%C5%9Flar%C4%B1-3-5-Milyar-Dolara-Ula%C5%9Ft%C4%B1',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nft_satislari_3.5_milyar_dolara_ulasti'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Empresyonizm Nedir?',
      TEXT: '1874 yılında, eserleri Paris Salonu tarafından sergilenmeye layık görülmeyen bir grup ressam kendi sergilerini düzenlemeye karar verdiler.' +
        ' Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir ve Edgar Degas’ın katıldığı bu sergi, empresyonizmin doğuşunu müjdeliyordu.',
      IMAGE_SRC: './assets/general_operations/tr/26.webp',
      TEXT_LIMIT_DESKTOP: 76,
      TEXT_LIMIT_MOBILE: 76,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414489445649-Empresyonizm-Nedir-En-Pahal%C4%B1-Empresyonist-Tablolar',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('empresyonizm_nedir'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: '2021 Yılı Sanat Pazarında Öne Çıkan Veriler',
      TEXT: '2021 yılı, tıpkı 2020 yılı gibi COVID-19 salgınının etkisinde bir yıl oldu.' +
        ' Yatırımcıların sağlıklı öngörüler üretmede güçlük çektiği bu yılda tıpkı diğer endüstrilerde olduğu gibi sanat endüstrisinde de belirsizlik hâkimdi.',
      IMAGE_SRC: './assets/general_operations/tr/25.webp',
      TEXT_LIMIT_DESKTOP: 51,
      TEXT_LIMIT_MOBILE: 51,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414481625873-2021-Y%C4%B1l%C4%B1-Sanat-Pazar%C4%B1nda-%C3%96ne-%C3%87%C4%B1kan-Veriler',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_yatirimi_hakkinda_raporlar'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Artiox’a Kimler Yatırım Yaptı?',
      TEXT: 'Daha önceki yazılarımızda Artiox’un hikâyesinden bahsetmiştik.' +
        ' Şimdi de Artiox’un bugünlere gelmesini sağlayan yatırımcılardan bahsedeceğiz.',
      IMAGE_SRC: './assets/general_operations/tr/24.webp',
      TEXT_LIMIT_DESKTOP: 61,
      TEXT_LIMIT_MOBILE: 61,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/sections/4414300225041-Yat%C4%B1r%C4%B1mc%C4%B1lar%C4%B1m%C4%B1z',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('artioxa_kimler_yatirim_yapti'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanat Piyasasına Yön Verecek 5 Trend',
      TEXT: 'Gelecek hakkında öngörülerde bulunmak hiçbir zaman kolay bir iş olmadı.' +
        ' Ancak son iki yıldır, COVID-19 salgını nedeniyle gelecek tahminleri yapmak daha da güç hale geldi.',
      IMAGE_SRC: './assets/general_operations/tr/23.webp',
      TEXT_LIMIT_DESKTOP: 70,
      TEXT_LIMIT_MOBILE: 70,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414496774417-Sanat-Piyasas%C4%B1na-Y%C3%B6n-Verecek-5-Trend',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_piyasasina_yon_verecek_5_trend'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Teknoloji Sanat Dünyasını Demokratik Hale Getirir mi?',
      TEXT: 'Sanat, hepimiz için var. Ancak sanat dünyasının büyük bir bölümünün ekonomik bakımdan güçlü, elit bir tabakanın elinde olduğu da gerçek.' +
        ' Geniş kitlelerin sanata ulaşabilmesi, sanat eseri sahipliğinin belirli bir zümrenin kontrolünden kurtulması ve sanatın demokratikleşmesi anlamına geliyor.',
      IMAGE_SRC: './assets/general_operations/tr/22.webp',
      TEXT_LIMIT_DESKTOP: 47,
      TEXT_LIMIT_MOBILE: 47,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414489313553-Teknoloji-Sanat-D%C3%BCnyas%C4%B1n%C4%B1-Daha-Demokratik-Hale-Getirir-mi-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('teknoloji_sanat_dunyasi_ve_demokrasi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanat Eserlerini Nasıl Seçiyoruz?',
      TEXT: 'Artiox olarak teknoloji sayesinde, düşük bütçelerle bile geniş kitlelerin sanata kolaylıkla yatırım yapmasını sağlıyoruz.' +
        ' Ancak sanat, çok katmanlı bir yatırım alanı.',
      IMAGE_SRC: './assets/general_operations/tr/21.webp',
      TEXT_LIMIT_DESKTOP: 73,
      TEXT_LIMIT_MOBILE: 73,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414488967441-Artiox-ta-Listelenecek-Sanat-Eserlerini-Nas%C4%B1l-Se%C3%A7iyoruz-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_eserlerini_nasil_seciyoruz'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Frida Kahlo’nun Otoportresinin Değeri',
      TEXT: 'Eylül 1925’te Mexico City’de bir otobüs, erkek arkadaşıyla birlikte okula giden doktor adayı bir kadına çarptı.' +
        ' Bu talihsiz kazanın 20. yüzyılın en önemli sanatçılarından birinin doğuşuna vesile olacağı henüz bilinmiyordu.',
      IMAGE_SRC: './assets/general_operations/tr/20.webp',
      TEXT_LIMIT_DESKTOP: 58,
      TEXT_LIMIT_MOBILE: 58,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414481114001-Frida-Kahlo-nun-Otoportresinin-De%C4%9Feri',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('frida_kahlonun_otoportresinin_degeri'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanatseverlere Alabileceğiniz 8 Kitap',
      TEXT: 'Yeni yıl yaklaşıyor. Sevdiklerinize hediyeler alacaksınız ancak ne almanız gerektiğini bilmiyorsunuz.' +
        ' Eğer hediye almayı düşündüğünüz kişi bir sanatseverse endişelenmenize gerek yok.',
      IMAGE_SRC: './assets/general_operations/tr/19.webp',
      TEXT_LIMIT_DESKTOP: 63,
      TEXT_LIMIT_MOBILE: 63,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414481072017-Sanatsever-Dostlar%C4%B1n%C4%B1za-Y%C4%B1lba%C5%9F%C4%B1-Hediyesi-Olarak-Alabilece%C4%9Finiz-En-%C4%B0yi-8-Kitap'
          , is_inner: false, target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanatseverlere_alabileceginiz_8_kitap'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Pablo Picasso Eserlerinin Değeri',
      TEXT: '\'Bazı ressamlar güneşi bir sarı noktaya dönüştürürler, bazıları ise bir sarı noktayı güneşe...\'' +
        'Böyle diyordu Pablo Picasso.',
      IMAGE_SRC: './assets/general_operations/tr/18.webp',
      TEXT_LIMIT_DESKTOP: 67,
      TEXT_LIMIT_MOBILE: 67,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414488849681-Pablo-Picasso-Eserlerinin-De%C4%9Feri',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('pablo_picasso_eserlerinin_degeri'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanat Eserlerine Talep',
      TEXT: 'Sanat eserleri için ödenen astronomik rakamlar hakkındaki haberler hepimizin ilgisini çekiyor.' +
        ' Dünyaca ünlü müzayede evlerinin düzenlediği açık artırmalarda birbirleriyle kıyasıya yarışan varlıklı koleksiyonerler' +
        ' her yıl milyarlarca doları sanat eserlerine yatırıyor.',
      IMAGE_SRC: './assets/general_operations/tr/17.webp',
      TEXT_LIMIT_DESKTOP: 57,
      TEXT_LIMIT_MOBILE: 57,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414477224337-Son-Y%C4%B1llarda-Sanat-Eserlerine-Olan-Talep-Neden-Artt%C4%B1-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_eserlerine_talep'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'NFT, Sanat Dünyasının Geleceği Olabilir mi?',
      TEXT: 'Tarih boyunca sanatla para kavramı arasında vazgeçilemez bir ilişki olmuştur.' +
        ' Sanatı ve sanatçıları sahiplenen varlıklı kesim kimi zaman sanata olan ilgilerinden kimi zaman imajlarını güçlendirmek için' +
        ' kimi zaman da kâr elde etmek için sanat eserlerinin üretimini finanse etmiş ve bu eserlerin ticaretini yapmıştır.',
      IMAGE_SRC: './assets/general_operations/tr/16.webp',
      TEXT_LIMIT_DESKTOP: 67,
      TEXT_LIMIT_MOBILE: 67,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414480921489-NFT-Sanat-D%C3%BCnyas%C4%B1n%C4%B1n-Gelece%C4%9Fi-Olabilir-mi-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nft_sanat_dunyasinin_gelecegi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanat Pazarı, Pandemi ve Sonrası',
      TEXT: 'Global sanat pazarı, diğer tüm endüstriler gibi COVID-19 salgınından etkilendi.' +
        ' 2020 yılında, 2019 yılına göre pazardaki toplam işlem hacminde yaklaşık %30’luk bir değer kaybı yaşandı.',
      IMAGE_SRC: './assets/general_operations/tr/15.webp',
      TEXT_LIMIT_DESKTOP: 68,
      TEXT_LIMIT_MOBILE: 68,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414480898321-Sanat-Pazar%C4%B1-Pandemi-ve-Sonras%C4%B1',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_pazari_pandemi_ve_sonrasi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Artiox\'taki Tokenler ile NFT\'lerin Farkı',
      TEXT: 'Son zamanlarda kripto paralarla ya da sanat piyasalarıyla ilgilenmeyen insanlar bile NFT’lerden bahsediyor.' +
        ' Bunun en büyük nedeni de NFT’ler hakkında çıkan sansasyonel haberlerin sosyal medyada hızla yayılması.',
      IMAGE_SRC: './assets/general_operations/tr/14.webp',
      TEXT_LIMIT_DESKTOP: 70,
      TEXT_LIMIT_MOBILE: 70,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414419408401-Artiox-taki-Tokenler-ile-NFT-lerin-Fark%C4%B1',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('artioxtaki_tokenler_ile_nftlerin_farki'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Acemiler İçin Sanat Yatırımcılığı',
      TEXT: 'Neredeyse hepimiz borsa ya da döviz piyasaları gibi alışılmış yatırım araçlarına aşinayız.' +
        ' Doğal olarak birikimlerimizin de büyük bir bölümünü bu yatırım araçlarında değerlendiriyoruz.',
      IMAGE_SRC: './assets/general_operations/tr/13.webp',
      TEXT_LIMIT_DESKTOP: 69,
      TEXT_LIMIT_MOBILE: 69,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414419269905-Acemiler-%C4%B0%C3%A7in-Sanat-Yat%C4%B1r%C4%B1mc%C4%B1l%C4%B1%C4%9F%C4%B1',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('acemiler_icin_sanat_yatirimciligi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Bir Sanat Eserinin Ticari Değeri',
      TEXT: '15 Kasım 2017 günü New York’ta, dünyanın en önemli müzayede evlerinden biri olan Christie\'s oldukça önemli bir müzayedeye ev sahipliği yapıyordu.' +
        ' Müzayedede Leonardo’nun “Salvator Mundi” tablosu satışa çıkarılmıştı.',
      IMAGE_SRC: './assets/general_operations/tr/12.webp',
      TEXT_LIMIT_DESKTOP: 59,
      TEXT_LIMIT_MOBILE: 59,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414418818705-Sanat-Eserinin-Ticari-De%C4%9Feri-Nas%C4%B1l-Belirlenir-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_eserinin_ticari_degeri'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanatçılar İçin NFT Kavramı',
      TEXT: '2011 yılının Nisan ayında kuyruğundan gökkuşağı çıkan bir kedi animasyonu YouTube’a yüklendi.' +
        ' Arka planda bir Japon pop şarkısının çalındığı bu video açıklanamaz bir şekilde popüler hale geldi ve 2011 yılının YouTube’da en çok izlenen 5. videosu oldu.',
      IMAGE_SRC: './assets/general_operations/tr/11.webp',
      TEXT_LIMIT_DESKTOP: 73,
      TEXT_LIMIT_MOBILE: 73,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414431676817-Sanat%C3%A7%C4%B1lar-%C4%B0%C3%A7in-NFT-Kavram%C4%B1',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanatcilar_icin_nft_kavrami'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Bir Türk Sanatçıya Ait En Pahalı Tablo',
      TEXT: '“Kaplumbağa Terbiyecisi” hemen hemen hepimizin aşina olduğu bir tablo.' +
        ' Bu tablonun ressamı Osman Hamdi Bey’in “Kur’an Okuyan Kız” tablosu,' +
        ' 2019 yılında Bonhams Müzayede Evi’nde gerçekleştirilen bir açık artırmada 6 milyon 315 bin sterline alıcı buldu.',
      IMAGE_SRC: './assets/general_operations/tr/10.webp',
      TEXT_LIMIT_DESKTOP: 69,
      TEXT_LIMIT_MOBILE: 69,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414418391057-Bir-T%C3%BCrk-Sanat%C3%A7%C4%B1ya-Ait-En-Pahal%C4%B1-Tablo',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('bir_turk_sanatciya_ait_en_pahali_tablo'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Nasıl Yatırımcı Olunur: Başlangıç Rehberi',
      TEXT: 'Türk Dil Kurumu “yatırım” kelimesini şöyle tanımlıyor: “Parayı, gelir getirici taşınır ya da taşınmaz bir mala yatırma, mevduat, plasman.”',
      IMAGE_SRC: './assets/general_operations/tr/9.webp',
      TEXT_LIMIT_DESKTOP: 78,
      TEXT_LIMIT_MOBILE: 78,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414431498385-Nas%C4%B1l-Yat%C4%B1r%C4%B1mc%C4%B1-Olunur-Ba%C5%9Flang%C4%B1%C3%A7-Rehberi',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nasil_yatirimci_olunur_baslangic_rehberi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sanat Eseri Nasıl NFT’ye Dönüştürülür?',
      TEXT: 'Son zamanlarda NFT’lerin popüler hale gelmesiyle birlikte geleneksel kanallardan satış yapmakta zorlanan ya da yeni bir şeyler denemek isteyen sanatçıların bu alana ilgisi arttı.',
      IMAGE_SRC: './assets/general_operations/tr/8.webp',
      TEXT_LIMIT_DESKTOP: 68,
      TEXT_LIMIT_MOBILE: 68,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414418213905-Fiziksel-Sanat-Eseri-Nas%C4%B1l-NFT-ye-D%C3%B6n%C3%BC%C5%9Ft%C3%BCr%C3%BCl%C3%BCr-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('sanat_eseri_nasil_nftye_donusturulur'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Nedir Bu Blockchain?',
      TEXT: 'Bitcoin’in geleceği hakkında şüpheleriniz olabilir.' +
        ' Bitcoin’e alternatif tüm coin’lerin geleceği hakkında daha da büyük şüpheleriniz olabilir.',
      IMAGE_SRC: './assets/general_operations/tr/7.webp',
      TEXT_LIMIT_DESKTOP: 72,
      TEXT_LIMIT_MOBILE: 72,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414431297425-Nedir-Bu-Blockchain-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nedir_bu_blockchain'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Geleneksel Yatırım ve Kripto Para',
      TEXT: 'Ekonomik durum gözetmeksizin hepimizin altına imzamızı atacağımız bir cümle var: Para kolay kazanılmıyor.' +
        'Dolayısıyla kazanmak için bolca zaman ve emek harcadığımız paramızı, bizim için en iyi olan yatırım aracında değerlendirmek istiyoruz.',
      IMAGE_SRC: './assets/general_operations/tr/6.webp',
      TEXT_LIMIT_DESKTOP: 65,
      TEXT_LIMIT_MOBILE: 65,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414418048913-Geleneksel-Yat%C4%B1r%C4%B1m-ve-Kripto-Para',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('geleneksel_yatirim_ve_kripto_para'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Artiox’un Kısa Hikâyesi',
      TEXT: 'Kabul etmek gerekirse sanat ve teknolojinin yan yana durması pek de alışık olduğumuz bir durum değil.' +
        ' Özellikle Türkiye’de. Artiox olarak bu iki kavramın bir arada olabileceğine inandık ve yola çıktık.',
      IMAGE_SRC: './assets/general_operations/tr/5.webp',
      TEXT_LIMIT_DESKTOP: 74,
      TEXT_LIMIT_MOBILE: 74,
      LINK: announcements_titles.zendesk_active
        ? {url: 'https://support.artiox.com/hc/tr/articles/4414417569937-Hikayemiz', is_inner: false, target: '_blank'}
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('artioxun_kisa_hikayesi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'S.S.S.',
      TOPIC: 'Geleneksel Sanat Yatırımı ile Farkımız',
      TEXT: 'Artiox; sermaye büyüklüğü gözetmeksizin herkesin sanat yatırımcısı olabilmesini amaçlayan yeni nesil bir sanat yatırım platformdur.' +
        ' Artiox’un bu amaca ulaşmak için geleneksel sanat piyasalarının çalışma prensiplerinden farklı bir modeli vardır.',
      IMAGE_SRC: './assets/general_operations/tr/4.webp',
      TEXT_LIMIT_DESKTOP: 64,
      TEXT_LIMIT_MOBILE: 64,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414309587601-Geleneksel-Sanat-Yat%C4%B1r%C4%B1m%C4%B1-ile-Fark%C4%B1m%C4%B1z',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('geleneksel_sanat_yatirimi_ile_farkimiz'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'S.S.S.',
      TOPIC: 'Artiox Nedir?',
      TEXT: 'Artiox; değerli sanat eserlerine istediğiniz zaman, dilediğiniz miktarda yatırım yapabileceğiniz ve bu yatırımlarınızı kolayca yönetebileceğiniz yeni nesil sanat yatırım platformudur.' +
        'Artiox; sanat yatırımcılığını, teknolojinin sunduğu imkânlar sayesinde demokratikleştirmeyi amaçlar. ' +
        'Bu sayede sanat yatırımları için ayırabileceği büyük bir sermayesi olmayan yatırımcılar da her geçen gün büyüyen sanat pazarının bir parçası haline gelebilirler.',
      IMAGE_SRC: './assets/general_operations/tr/3.webp',
      TEXT_LIMIT_DESKTOP: 80,
      TEXT_LIMIT_MOBILE: 80,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414430588433-Artiox-Nedir-',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('artiox_nedir'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Adnan Çoker Hk.',
      TEXT: '20. yüzyıl Türkiye sanatında önemli bir yeri olan Adnan Çoker; en çok Bizans, Selçuklu ve Osmanlı mimari formlarını resimsel konturlara dönüştürerek özgün bir estetik üslup yaratmasıyla tanınır.' +
        ' 1970ler sonrası bu çizgide işler üreten sanatçı resimlerinde kalıp-biçim olarak nitelendirdiği biçimleri, siyah üzerinde soyut bir mekânsal boyut içerisinde işler.' +
        ' Anıtsal mimarinin, iç uzamını dış dünyaya açan sivri kemerli kapı ve pencere motifinden yola çıkılarak oluşturulan uyum, sanatçının sözleriyle bir kalıp biçime dayanır.',
      IMAGE_SRC: './assets/artists/adnan_coker.webp',
      TEXT_LIMIT_DESKTOP: 76,
      TEXT_LIMIT_MOBILE: 76,
      LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'DUYURU',
      TOPIC: 'Yatırım Turu',
      TEXT: 'Geçtiğimiz Mart ayında 7 milyon TL değerleme üzerinden aldığımız yatırım ardından kısa sürede değerimizi 3 katına yükselten yepyeni bir yatırıma imza atmış bulunmaktayız.' +
        ' İdeal Finansal Teknolojiler ve Danışmanlık A.Ş.\'den 2.5 milyon dolar değerleme ile almış olduğumuz yatırıma Alesta ve Sertaç Özinal da devam yatırımında bulundular.',
      IMAGE_SRC: './assets/general_operations/tr/2.webp',
      TEXT_LIMIT_DESKTOP: 64,
      TEXT_LIMIT_MOBILE: 64,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414430968081-%C4%B0deal-Finansal-Teknolojiler-A-%C5%9E',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('yatirim_turu'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Sauna Emerging 1 Sepeti Hk.',
      TEXT: 'Artiox ve Art.Ist Sauna işbirliği çerçevesinde oluşturulan “Sauna Emerging 1” sepeti, genç ve bağımsız 11 sanatçının  eserlerinden oluşmaktadır.' +
        ' Bu sepet ile birlikte gelecek vadeden sanatçıların üretmiş oldukları sanat eserlerine yatırım yaparken aynı zamanda titizlikle seçilmiş bu sanatçılara da destek olmuş olacaksınız. ',
      IMAGE_SRC: './assets/artists/Art_Ist_Sauna.webp',
      TEXT_LIMIT_DESKTOP: 65,
      TEXT_LIMIT_MOBILE: 65,
      LINK: {url: '/about-artist', artist_announcement_index: 6, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'DUYURU',
      TOPIC: 'Yeni İş Birliği',
      TEXT: 'Resim, heykel, fotoğraf, video sanatı, karikatür, illüstrasyon, sokak sanatı, performans, sinema gibi' +
        ' sanatın birçok disiplinlerini ihtiva eden ve Türkiye’nin tek kat üzerindeki en büyük sanat ve etkinlik' +
        ' alanı UniqExpo’da faaliyet gösteren Art.Ist Sauna ile gerçekleştirdiğimiz iş birliğini tüm kullanıcılarımız ' +
        've sanatseverler ile paylaşmaktan mutluluk duyuyoruz.',
      IMAGE_SRC: './assets/general_operations/tr/1.webp',
      TEXT_LIMIT_DESKTOP: 76,
      TEXT_LIMIT_MOBILE: 76,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414417604241-Art-Ist-Sauna-ile-%C4%B0%C5%9F-Birli%C4%9Fi',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('art.ist_sauna_ile_is_birligi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'DUYURU',
      TOPIC: 'TL Yatırma İşlemi',
      TEXT: 'Para yatırma işleminize başlamak için sağ üstte bulunan kullanıcı panelini kullanınız.' +
        ' Açılan listede yatırma butonuna tıklayınız.' +
        ' Para yatırmak için tercih ettiğiniz bankayı seçenekler arasından işaretleyiniz. ',
      IMAGE_SRC: './assets/general_operations/tr/0.webp',
      TEXT_LIMIT_DESKTOP: 65,
      TEXT_LIMIT_MOBILE: 65,
      LINK: announcements_titles.zendesk_active
        ? {
          url: 'https://support.artiox.com/hc/tr/articles/4414682372113-T%C3%BCrk-Liras%C4%B1-Yat%C4%B1rma-%C4%B0%C5%9Flemi',
          is_inner: false,
          target: '_blank'
        }
        : {
          url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('turk_lirasi_yatirma_islemi'),
          current_component_index: 0
        },
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Nuri Abaç Hk.',
      TEXT: 'Resimlerini gelenekler, gündelik hayat ve Anadolu söylenceleri ile bağdaştırıp dekoratif nitelikli özgün bir' +
        ' gerçeküstücülük ile ortaya koyan Nuri Abaç, 20. Yüzyıl Türkiye sanatının önemli ressamlarından olmuştur.' +
        ' Tarzının öne çıkan özelliği Karagöz-Hacivat estetiğine sahip bir yaklaşım benimsemesi ve musiki unsurlara sık sık yer vermesidir.',
      IMAGE_SRC: './assets/artists/nuri_abaç.webp',
      TEXT_LIMIT_DESKTOP: 62,
      TEXT_LIMIT_MOBILE: 62,
      LINK: {url: '/about-artist', artist_announcement_index: 5, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Adnan Turani Hk.',
      TEXT: 'Türkiye sanatının ve sanat akademisinin önemli isimlerinden olan Adnan Turani, soyut odaklı resmin Türkiye’deki en önemli temsilcilerindendir.' +
        ' Erken dönem eserlerinde soyut bir anlayış gösteren Turani, son dönem eserlerinde figürlere ve doğaya yer verse de' +
        ' resimlerine yaklaşımı soyut bir kurguyu ve biçimin ifadesine dayalı olmayı sürdürmüştür.',
      IMAGE_SRC: './assets/artists/adnan_turani.webp',
      TEXT_LIMIT_DESKTOP: 64,
      TEXT_LIMIT_MOBILE: 64,
      LINK: {url: '/about-artist', artist_announcement_index: 3, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Ahmet Güneştekin Hk.',
      TEXT: '21. yüzyıl Türkiye sanatının en önemli isimlerinden kabul edilen Ahmet Güneştekin; resim, kavramsal sanat ve heykel alanlarında eserler üretmektedir.' +
        ' Kendi kendini yetiştirmiş bu sanatçı işlerinde coğrafi öğelere ve Anadolu, Yunan, Mezopotamya efsanelerine yer vermektedir.' +
        ' Geleneksel ve çağdaş referanslarla yüklü işleri ile öne çıkan Güneştekin, 2000\'lerin başında figüratifi terk etmiş ve anlatıya odaklanan soyut bir tarz benimsemiştir.',
      IMAGE_SRC: './assets/artists/ahmet_güneştekin.webp',
      TEXT_LIMIT_DESKTOP: 81,
      TEXT_LIMIT_MOBILE: 81,
      LINK: {url: '/about-artist', artist_announcement_index: 2, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Nejad Melih Devrim Hk.',
      TEXT: 'Nejad Melih Devrim, çağdaş sanat alanındaki çalışmalarıyla döneminin önemli ressamları arasında yer almaktadır.' +
        ' Coğrafi ve kültürel öğelerden etkilenen sanatçı, figüratif ve soyut arasında gidip gelen bir yol izlemiştir.' +
        ' Özellikle geç dönem soyut resimleriyle Türk sanatında önemli bir yere sahiptir.' +
        ' Amerika’da “Soyut Dışavurumculuk“ olarak bilinen ve çoğunluğu Avrupa’dan gelen sanatçılar ile başlayan hareketin' +
        ' Avrupa’daki karşılığı olan lirik soyut ve kısmen geometrik soyut içinde yer almaktadır.',
      IMAGE_SRC: './assets/artists/nejad_melih_devrim.webp',
      TEXT_LIMIT_DESKTOP: 68,
      TEXT_LIMIT_MOBILE: 68,
      LINK: {url: '/about-artist', artist_announcement_index: 1, current_component_index: 0},
      RELEASE_DATE: 0,
    },
    {
      HEADING: 'BLOG',
      TOPIC: 'Yalçın Gökçebağ Hk.',
      TEXT: 'Yalçın Gökçebağ “Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” ve “Resmin Yaşar Kemal’i” olarak bilinir.' +
        ' Geniş bakış açısı, ritmik düzeni, detaycılığı, fırça ve renk kullanımı ile Anadolu’yu kendi tavrıyla yansıtır.' +
        ' Genellikle Hasat ve Bodrum serileri gibi geniş bakış açıları ile kurguladığı peyzaj kompozisyonları ile bilinir.',
      IMAGE_SRC: './assets/artists/yalçın_gökçebağ.webp',
      TEXT_LIMIT_DESKTOP: 66,
      TEXT_LIMIT_MOBILE: 66,
      LINK: {url: '/about-artist', artist_announcement_index: 0, current_component_index: 0},
      RELEASE_DATE: 0,
    },
  ],
  ANNOUNCEMENT_POPUP: {
    HEADING: 'ÖNEMLİ DUYURU',
    BODY: 'Devrim Erbil - "Süleymaniye\'den İstanbul\'a" ($IST) eserine ait tüm tokenler hızlıca' +
      ' tükenmiştir. Satış öncesi ilan ettiğimiz listelenme tarihi gösterilen' +
      ' yoğun ilgi sebebiyle öne çekilmiştir.<br><br>' +
      ' Yeni listelenme tarihi: <strong>15 Nisan 2021, 14.00 </strong><br><br>' +
      ' İlginiz için teşekkür ederiz. ',
    SRC: './assets/animations/move_to_amazon.webm',
    SRC_MP4: './assets/animations/move_to_amazon.mp4'
  },
  LOGIN: {
    WELCOME_TO_ARTIOX: 'Artiox\'a Hoş Geldin!',
    SUBTITLE: 'E-posta adresin ve şifreni kullanarak Artiox’a giriş yap.',
    LOGIN: 'Giriş Yap',
    E_MAIL: 'E-posta',
    E_MAIL_PLACEHOLDER: 'example@artiox.com',
    PASSWORD: 'Şifre',
    PASSWORD_PLACEHOLDER: '********',
    AUTH_TITLE: 'Çift Aşamalı Doğrulama',
    AUTH_PLACEHOLDER: '******',
    AUTH_SUBTITLE: 'Doğrulama kodunu girerek Artiox’a giriş yap.',
    AUTH: 'Doğrulama Kodu',
    PASTE: 'Yapıştır',
    FORGOT_PASSWORD: 'Şifremi Unuttum',
    SUBMIT: 'Onayla',
    CAPTCHA_HEADER: 'Lütfen puzzle\'ı tamamlayınız.',
    CAPTCHA_BODY: 'Oku sürükleyiniz.',
    NO_ACCOUNT: 'Hesabınız yoksa',
    CREATE_NEW_ACCOUNT: 'Yeni Üyelik Oluşturun!',
    ART_INVESTMENT: 'Sanat Yatırımı Yapmanın En Kolay Yolu'
  },
  FORGOT_PASSWORD: {
    HEADING: 'Şifremi Unuttum!',
    INFO: 'Lütfen e-posta adresinizi giriniz. Ardından e-posta adresinize gelen bağlantı ile yeni bir şifre oluşturabilirsiniz. ',
    E_MAIL: 'E-posta',
    E_MAIL_PLACEHOLDER: 'example@artiox.com',
    SEND: 'Gönder',
    SEND_SUCCESFULLY_BEGGINING: 'Bağlantı ',
    SEND_SUCCESFULLY_END: ` adresine başarılı bir şekilde gönderilmiştir.`,
    RESEND_REQUEST: 'Yeniden Bağlantı Gönder',
    SECOND_ABBREVIATION: 'sn'
  },
  SIGN_UP: {
    SIGN_UP: 'Hesap Oluştur',
    SIGN_UP_TEXT: 'Artiox’a katıl ve onlarca sanatçının değerli sanat eserlerine yatırım yapma fırsatını yakala.',
    CREATE_ACCOUNT: 'Artiox Hesabını Oluştur',
    E_MAIL: 'E-posta',
    E_MAIL_PLACEHOLDER: 'example@artiox.com',
    PASSWORD: 'Şifre',
    PASSWORD_PLACEHOLDER: '*********',
    PASSWORD_AGAIN: 'Şifre (Tekrar)',
    PASSWORD_PLACEHOLDER_AGAIN: 'Şifreniz (Tekrar)',
    KVKK_ACCEPTED: '<p class="mb-0">Kişisel Verilerin Korunması Kanunu uyarınca hazırlanan <a class="sign-up-agreements-color"  href="./assets/pdfs/Artiox KVKK Aydınlatma Metni.pdf" target="_blank">KVKK Aydınlatma Metni</a>\'ni okudum. <a class="sign-up-agreements-color" href="./assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank">Kullanıcı Sözleşmesi</a>\'ni onaylıyorum. </p>',
    CONSENT_TEXT_ACCEPTED: '<p class="mb-0"> <a class="sign-up-agreements-color" href="./assets/pdfs/Artiox Yurtdışı Açık Rıza Metni.pdf" target="_blank">Açık Rıza Metni</a>\'nde belirtilen şekillerde yurt dışı kişi ve kuruluşlara kişisel verilerimin aktarılmasını onaylıyorum. </p>',
    AUCTION_RULES_ACCEPTED: '<p class="mb-0"> Artiox Kripto Varlık Alım Satım Platformu A.Ş.\'nin düzenlediği online müzayede katılım için <a class="sign-up-agreements-color" href="./assets/pdfs/Müzayede Kuralları.pdf" target="_blank"> Müzayede Kuralları</a>, <a class="sign-up-agreements-color" href="./assets/pdfs/Mesafeli Satış Sözleşmesi.pdf" target="_blank"> Mesafeli Satış Sözleşmesi</a> ve <a class="sign-up-agreements-color" href="./assets/pdfs/Müzayede Kullanıcı Sözleşmesi.pdf" target="_blank"> Müzayede Kullanıcı Sözleşmesini</a> okudum, onaylıyorum.</p>',
    COMMERCIALS_ACCEPTED: 'Bilgilendirme, mal ve hizmet tanıtımı, reklam ve promosyon gibi amaçlarla ticari elektronik ileti gönderilmesini kabul ediyorum.',
    DATA_PROCESSING_ACCEPTED: 'E-posta adresi bilgilerimin bilgilendirme, reklam, tanıtım ve pazarlama amaçlı işlenmesine ve aktarılmasına onay veriyorum.',
    HAVE_ACCOUNT: 'Hesabınız varsa',
    LOGIN: 'Giriş Yapın!',
    PASSWORD_RULES: 'En az 8 karakter, 1 büyük harf, 1 küçük harf, 1 sayı ve 1 özel karakter içermelidir.',
    SIGN_UP_LOADER: 'Hesabınız Oluşturuluyor. Lütfen Bekleyiniz.',
    SIGN_UP_SUCCESSFUL: 'TEBRİKLER, KAYDINIZI OLUŞTURDUNUZ.',
    ACTIVATION_MAIL: 'Hesabınızı aktifleştirmek için lütfen e-posta adresinizden onay veriniz. ',
    ACTIVATION_MAIL_NOT_SENT: 'Aktivasyon E-Postanız Gelmediyse',
    ACTIVATION_MAIL_NOT_SENT_CHECK_LIST: ['- Spam klasörünüzü veya çöp kutunuzu kontrol edin.', '- E-posta adresinizi doğru yazdığınızdan emin olun.', '- İnternet bağlantınızın çalıştığından emin olun.', '- E-postanızın doğru çalıştığından emin olun.'],
    CREATE_PASSWORD: 'Şifre Oluşturma',
    CREATE_PASSWORD_TEXT: 'Yeni şifreni belirtilen kurallara göre oluşturabilirsin.',
    COMPLETE: 'Tamamla',
    SIGN_UP_HEADER: 'Artiox’a Üye Ol',
    SIGN_UP_HEADER_TEXT: 'Artiox hesabını şimdi <span class="free-green"> ücretsiz </span> bir şekilde oluşturabilirsin.'
  },
  ACCOUNT_VERIFICATION: {
    HEADER: 'Hesap Doğrulama',
    FIRST_INFO: {
      STRING: '$EMAIL adresine gelen <span class="verification-info-bold">6 haneli doğrulama kodunu</span> aşağıdaki alana girerek Artiox hesabınızı aktive edebilirsiniz.',
      TOKENS: ['$EMAIL']
    },
    SECOND_INFO: 'Doğrulama kodunu içeren maili göremiyorsanız <span class="verification-info-bold"> spam / junk kutusunu kontrol edin. </span>',
    INPUT_HEADER: '6 haneli doğrulama kodu',
    INPUT_PLACEHOLDER: '___ - ___',
    RESEND_REQUEST: 'Yeniden Bağlantı Gönder',
    SECOND_ABBREVIATION: 'sn',
    BUTTON_TEXT: 'Hesabını Doğrula',
    ART_INVESTMENT: 'Sanat Yatırımı Yapmanın En Kolay Yolu'
  },
  LANDING_PAGE: {
    GREETER: {
      HEADING: 'SANAT YATIRIMINI KEŞFET',
      HEADING_INFO: 'Değerli Sanat Eserlerine Parçalı Yatırım Yapmanın En Kolay Yolu',
      PRE_SALE: 'Ön Satış',
      PUBLIC_SALE: 'Genel Satış',
      TRADE: 'Al-Sat',
      ADVANTAGES: [
        {
          HEADING: 'Sanat Marketi',
          CONTENT: [
            'Farklı Eserler ile Pörtföy',
            'Parçalı Alım-Satım'
          ]
        },
        {
          HEADING: 'Güvenli Yatırım',
          CONTENT: [
            'Blockchain Teknolojisi',
            'Eksper Onayı ve Sigorta'
          ]
        },
        {
          HEADING: 'Kolay Ticaret',
          CONTENT: [
            '7/24 Online İşlem',
            'Kullanıcı Dostu Arayüz'
          ]
        }
      ],
      INFO: [
        {
          HEADING: '2020 Sanat Marketi Hacmi',
          HIGHLIGHT: '50.1 Milyar $',
        },
        {
          HEADING: '2020 Online Sanat Marketi Hacmi',
          HIGHLIGHT: '12.4 Milyar $',
        },
        {
          HEADING: 'Online Sanat Marketi Hacim Artışı',
          HIGHLIGHT: '%110',
        }
      ],
    }
  },
  HOME: {
    SLIDER: {
      PRE_SALE_STARTS_IN: 'ÖN SATIŞ BAŞLAMASINA',
      PRE_SALE_ENDS_IN: 'ÖN SATIŞ BİTİMİNE',
      PUBLIC_SALE_STARTS_IN: 'GENEL SATIŞ BAŞLAMASINA',
      PUBLIC_SALE_ENDS_IN: 'GENEL SATIŞ BİTİMİNE',
      LISTED: 'LİSTELENME TARİHİ',
      YEAR: 'YIL',
      MONTH: 'AY',
      DAY: 'GÜN',
      HOUR: 'SAAT',
      MIN: 'DK',
      SEC: 'SN',
      TOKENMETRICS: {
        HEADING: 'TOKENMETRİK BİLGİLERİ',
        SYMBOL: 'Sembol',
        TOTAL_SUPPLY: 'Toplam Token',
        CIRCULATING_SUPPLY: 'Dolaşımdaki Token',
        PRE_SALE_CIRCULATING_SUPPLY: 'Ön Satış Miktarı',
        PRE_SALE_PRICE: 'Ön Satış Fiyatı',
        PUBLIC_SALE_CIRCULATING_SUPPLY: 'Genel Satış Miktarı',
        PUBLIC_SALE_PRICE: 'Genel Satış Fiyatı',
        PUBLIC_SALE_PRICE_UPPERCASE: 'GENEL SATIŞ FİYATI',
      },
      ART_PRODUCT_INFO: {
        HEADING: 'ESER BİLGİLERİ',
        LAST_PRICE: 'Son Fiyat',
        CHANGE_24_HOUR: '24S Değişim',
        VOLUME_24_HOUR: '24S Hacim',
        LOW_24_HOUR: '24S En Düşük',
        HIGH_24_HOUR: '24S En Yüksek',
      }
    },
    PAIR_HEADER: {
      ART_PRODUCT: 'ESER',
      LAST_PRICE: 'Son Fiyat',
      ART_PRODUCT_MARKET_VALUE: 'Eserin Piyasa Değeri',
      CHANGE_24_HOUR: '24S Değişim',
      MARKET: 'Market'
    },
    SUMMARY: {
      HEADING_MOBILE: 'Sanat Eserlerine Ortak Ol!',
      HEADING_DESKTOP: 'ARTIOX İle Sanat Eserlerine Ortak Ol!',
      TOPICS: [
        {
          SRC: './assets/images/insured_art_pieces.svg#insured_art_pieces',
          TEXT: 'Artiox\'ta listelenen eserler eksper onaylı ve sigortalıdır.'
        },
        {
          SRC: './assets/images/high_security.svg#high_security',
          TEXT: 'Artiox\'taki bilgileriniz ve fonlarınız güvenli bir şekilde saklanmaktadır.'
        },
        {
          SRC: './assets/images/easy_to_use.svg#easy_to_use',
          TEXT: 'Kullanıcı dostu arayüzü ile Artiox\'ta sanat eserlerine ait tokenlerin ticaretini kolayca yapabilirsiniz.'
        },
        {
          SRC: './assets/images/7_24_online.svg#7_24_online',
          TEXT: 'Artiox\'ta alım-satım işlemlerinizi günün her saatinde gerçekleştirebilirsiniz.'
        }
      ]
    }
  },
  APP_ANNOUNCEMENT: {
    DESCRIPTION: 'ARTIOX Mobil ile<br>sanat yatırımı<br>artık hep yanında!',
    APP_STORE_LINK_DESKTOP: 'https://apps.apple.com/tr/app/artiox/id1579037676?l=tr',
    APP_STORE_LINK_MOBILE: 'itms-apps://apple.com/app/id1579037676?l=tr',
    GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.artiox.artiox',
    APP_STORE_BADGE: './assets/misc/app_announcement/app_store.svg#app_store',
    GOOGLE_PLAY_BADGE: './assets/misc/app_announcement/google_play.svg#google_play',
    DESKTOP_IMAGE_THUMBNAIL: './assets/misc/app_announcement/tr.d.thumbnail.webp',
    MOBILE_IMAGE_THUMBNAIL: './assets/misc/app_announcement/tr.m.thumbnail.webp',
  },
  ART_MARKET: {
    HEADING: 'SANAT MARKETİNE BAKIŞ',
    TOPIC: 'SANAT MARKETİ',
    INFO: 'Sanat insanın kendini ifade etme biçimi olmanın dışında, büyük bir pazar olarak karşımıza çıkıyor.' +
      ' Şöyle ki; dünya üzerinde $3 trilyon değerinde likid olmayan sanat varlığı olmasının yanında,' +
      ' 2019 yılında $64 milyara ulaşan sanat marketi hacmi bu pazarın büyüklüğünü açıkça gösteriyor.' +
      ' Grafikte de gözlemlendiği üzere geleneksel yatırım araçlarına göre oldukça karlı ve güvenli görünen bu market' +
      ' online tarafta ise yüksek bir ivmeyle büyümeye devam ediyor. 2016 yılında $3,75 milyar hacme ulaşan online sanat marketinin,' +
      ' 2024 yılında $9,1 milyar büyüklüğe ulaşacağı tahmin ediliyordu.<span class="citation h6 text-info">1</span>',
    CARDS: [
      'Online Sanat Marketi<br> Büyüme Oranı<br><strong class="d-inline-block h5 mt-1 mb-0">Yıllık %110</strong>', 'Online Sanat Marketi<br> 2020 Hacmi<br><strong class="h5"> 12.4 Milyar $<span class="citation h6 text-info">2</span></strong>'
    ],
    SOURCE: [
      'The Art Basel and UBS Global Art Market Report 2020', 'The Art Basel and UBS Global Art Market Report 2021'
    ]
  },
  WHAT_IS_ARTIOX: {
    HEADING: 'ARTIOX NEDİR?',
    TOPIC: 'HERKES İÇİN TASARLANMIŞ KOLAY SANAT YATIRIM PLATFORMU',
    INFO: 'Artiox platformunda hisse senedi alıp satar gibi eksper onaylı ve sigortalı sanat eserlerine ait ‘tokenleri’ kolayca alıp satabilirsiniz. Böylece:',
    ADVANTAGES: [
      'Bir eserin tümüne yüklü bir fon yatırmak yerine bir kısmına ortak olabilir, portföyünüzü diğer eserlerle çeşitlendirebilirsiniz.',
      'İstediğiniz kadarını anında piyasa fiyatından alıp satabilir, dilediğiniz noktalara alım ya da satım emirleri verebilirsiniz.',
      'Sanat yatırımlarınızı geleneksel yöntemlere göre daha etkin yönetebilirsiniz.'
    ]
  },
  HOW_IT_WORKS: {
    HEADING: 'NASIL ÇALIŞIR?',
    TOPICS: [
      {
        HEADING: 'ESER SEÇME',
        INFO: 'Uzmanların değerlendirmesi sonucunda değerli eser seçilir.',
        SRC: './assets/animations/choose.d.webm',
        SRC_MP4: './assets/animations/choose.d.mp4'
      },
      {
        HEADING: 'TOKENİZASYON',
        INFO: 'Sigortası yapılan eser blokzincir üzerinde oluşturulan akıllı kontrat ile tokenize edilir ve kullanıcılara ilan edilir.',
        SRC: './assets/animations/tokenization.d.webm',
        SRC_MP4: './assets/animations/tokenization.d.mp4'
      },
      {
        HEADING: 'PLATFORMDA LİSTELEME',
        INFO: 'Ön satış ve satış sürecinin ardından eser markette listelenerek 7/24 ticarete hazır hale gelir.',
        SRC: './assets/animations/trade.d.webm',
        SRC_MP4: './assets/animations/trade.d.mp4'
      }
    ]
  },
  DASHBOARD: {
    SUCCESS: 'Başarı',
    SUCCESSFUL_LOGIN: 'Başarılı Giriş',
    SUCCESSFUL_OPERATION: 'Başarılı İşlem',
    WARNING: 'Uyarı',
    WELCOME: 'Hoş geldin,',
    LAST_ACTIVITIES: 'SON AKTİVİTELERİNİZ',
    PORTFOLIO: 'PORTFÖY',
    TOTAL_BALANCE: 'Toplam Bakiye',
    TOTAL_BALANCE_HISTORY: 'Toplam Bakiye Geçmişi',
    DAILY_LIMIT: 'Günlük Limit',
    MONTHLY_LIMIT: 'Aylık Limit',
    LIMITS: 'Kalan Limitler',
    LIMITS_MOBILE: 'Kalan Limitler',
    DAILY_REMAINING: 'Günlük Kalan',
    DAILY: 'Günlük',
    MONTHLY_REMAINING: 'Aylık Kalan',
    MONTHLY: 'Aylık',
    DEPOSIT: 'Yatırma',
    WITHDRAWAL: 'Çekme',
    BUY: 'Al',
    SELL: 'Sat',
    LEVEL: 'Seviye',
    LEVEL_UP: 'Şimdi Yükselt',
    LEVEL_UP_MOBILE: 'Yükselt',
    HIGHEST_LEVEL: 'Hesabınız en yüksek seviyededir.',
  },
  USER_INFORMATION: {
    INVALID_PHONE: 'Geçersiz telefon numarası',
    INVALID_ADDRESS: 'Geçersiz adres',
    INVALID_CITY: 'Geçersiz şehir',
    NO_KYC_HEADING: 'Hesabınız Seviye 1 olmalıdır.',
    NO_KYC_DESCRIPTION: 'Kullanıcı bilgilerim sayfası işlemlerinde güvenlik sebebiyle “Kimlik Beyanı” yapmanız gerekmektedir.',
    HEADING: 'Kullanıcı Bilgilerim',
    ACCOUNT_INFORMATION: 'Hesap Bilgileri',
    PROFILE: 'Profil',
    AUCTION_ADDRESS: 'Müzayede Adresi',
    MY_ADDRESSESS: 'Adreslerim',
    DELIVERY_ADDRESS: 'Teslimat Adresi',
    BILLING_ADDRESS: 'Faturlandırma Adresi',
    ADD_ADDRESS: 'Adres Ekle',
    EDIT_ADDRESS: 'Adresi Düzenle',
    DELETE_ADDRESS: 'Adresi Sil',
    HOME: 'Ev',
    WORK: 'İş',
    OTHER: 'Diğer',
    NAME: 'İsim',
    SURNAME: 'Soyisim',
    TC_NUMBER: 'T.C. Kimlik No',
    CITY: 'Şehir',
    ADDRESS: 'Adres',
    EMAIL: 'E-posta',
    PHONE_NUMBER: 'Telefon Numarası',
    DISCLAIMER: '<p><span class="underline">İsim, Soyisim, T.C Kimlik No ve Email</span> bilgileriniz güvenliğinizden dolayı değiştirilemez. Hesabınızın silinmesi veya bu bilgilerin değiştirilmesi için <a class="link" href="mailto:support@artiox.com">support@artiox.com</a> ile iletişime geçebilirsiniz.</p>',
    CANCEL: 'Vazgeç',
    CHANGE: 'Bilgileri Güncelle',
    APPROVE: 'Değişiklikleri Kaydet'
  },
  BILLING_ADDRESS: {
    HEADER: 'Adres Ekle',
    MODAL_INFO: 'Fatura Bilgilerim',
    INDIVIDUAL_OPTION: 'Bireysel',
    CORPORATE_OPTION: 'Kurumsal',
    INDIVIDUAL_HEADERS: {
      ADDRESS_HEADER: 'Adres Başlığı',
      NAME: 'İsim',
      SURNAME: 'Soyisim',
      ID_NUMBER: 'T.C. Kımlık No',
      PHONE_NUMBER: 'Telefon Numarası',
      PROVINCE: 'İl',
      DISTRICT: 'İlçe',
      ADDRESS: 'Adres',
    },
    CORPORATE_HEADERS: {
      ADDRESS_HEADER: 'Adres Başlığı',
      CORPORATE_NAME: 'Firma Adı',
      TAX_ADMINISTRATION: 'Vergı Dairesi',
      TAX_NUMBER: 'Vergi Numarası',
      PHONE_NUMBER: 'Telefon Numarası',
      PROVINCE: 'İl',
      DISTRICT: 'İlçe',
      ADDRESS: 'Adres',
      E_INVOICE: 'E-fatura mükellefiyim.',
    }

  },
  ACCOUNT_AUCTION: {
    HEADING: 'MÜZAYEDE',
    MY_BIDS: 'Tekliflerim',
    MY_FOLLOWINGS: 'Takip Ettiklerim',
    MY_WONS: 'Kazandıklarım',
    PRODUCT_DETAIL: 'Eser Detay',
    MAKE_PAYMENT: 'Ödeme Yap',
    PAYMENT_DETAIL: 'Ödeme Bilgileri',
    GIVEN_BID: 'Verilen Teklif',
    CURRENT_BID: 'Güncel Teklif',
    TOTAL_PRICE: 'Ödenecek Tutar',

    BUYER_COST: 'Alıcıya Maliyet',
    NO_USER_WON_HEADING: 'Kazandığınız eser bulunmamaktadır.',
    NO_USER_WON_DESCRIPTION: 'Eserleri görmek için tıklayın.',
    NO_USER_FOLLOWING_HEADING: 'Takip ettiğiniz eser bulunmamaktadır.',
    NO_USER_FOLLOWING_DESCRIPTION: 'Eserleri görmek için tıklayın.',
    NO_USER_BID_HEADING: 'Teklif verdiğiniz eser bulunmamaktadır.',
    NO_USER_BID_DESCRIPTION: 'Eserleri görmek için tıklayın.',
    SEE_ARTWORKS: 'Eserleri gör',
  },
  SUPPORT: {
    SUPPORT_HEADER: 'Destek Türünü Seç',
    QUESTIONS_HEADER: 'Popüler Sorular',
    QUESTIONS: [
      {
        TITLE: 'Artiox nedir?',
        BODY: 'Artiox, değerli sanat eserlerini eksper onaylı ve sigortalı bir şekilde blockchain teknolojisiyle tokenize ederek kullanıcılara arz eden ve bu sayede kullanıcıların tbu sanat eserlerine diledikleri miktarlarda yatırım yapabilmelerini sağlayarak sanat yatırımlarını daha erişilebilir ve demokratik hale getiren yeni nesil bir sanat yatırım platformdur.'
      },
      {
        TITLE: 'Artiox\'a nasıl üye olurum?',
        BODY: 'Artiox Web sitesine veya uygulamasına girdikten sonra üye ol / başla butonuna tıklayıp gerekli bilgileri doldurarak üye olabilirsiniz.'
      },
      {
        TITLE: 'Artiox\'a üyelik ücretli mi?',
        BODY: 'Artiox’a üye olmak tamamen ücretsizdir.'
      },
      {
        TITLE: 'Yatırım sınıfı sanat eseri nedir?',
        BODY: 'Yatırım sınıfı sanat eseri, orta/uzun vadede değer kazanması beklenen sanat eseridir. Bu eserler tanınmış sanatçılar tarafından yapılmış, iyi korunmuş ve nadir bulunan eserlerdir. Yatırım sınıfı sanat eserleri yüksek fiyatlı olsa da, orta/uzun vadede değer kazanarak yatırımcısına kar sağlama potansiyeline sahiptir.' +
          '\n' +
          'Yatırım sınıfı sanat eserleri, diğer sanat eserlerinden farklı olarak finansal yatırım amacı taşıyan özellikleriyle ayrılır ve yatırımcılar için alternatif bir varlık sınıfı sunar. Bunlar, finansal değer artışı potansiyeline sahip olan ve portföy çeşitlendirmek amacıyla kullanılan sanat eserleridir'
      },
      {
        TITLE: 'Varlığa dayalı token nedir?',
        BODY: 'Varlığa dayalı token(asset-backed tokens), fiziksel bir varlığın dijital temsilidir. Bu varlık, sanat eseri, gayrimenkul, hisse senedi, emtia veya başka herhangi bir fiziksel varlık olabilir. Varlığa dayalı tokenler, blok zinciri teknolojisi kullanılarak oluşturulur. Bu, tokenlerin güvenli ve şeffaf bir şekilde saklanmasına, transfer edilmesine veya temsil ettiği fiziksel varlığa paydaş olunabilmesine olanak tanır. ' +
          '\n' +
          'Varlığa dayalı tokenler (asset-backed tokens), gerçek dünya varlıklarını kripto para veya dijital varlık dünyasında entegre eden araçlardır. Bölünebilirlik ve şeffaflık gibi çeşitli faydalar sunmakla birlikte fiziksel varlıklara yatırım yapmayı sağladıkları için daha güvenilirlerdir.'
      },
      {
        TITLE: 'Online sanat marketinin büyüklüğü nedir?',
        BODY: 'Sanat dünyası ile ilgili analizler ve öngörülerde bulunan Art Basel’ın güncel raporuna göre 2023 yılında online sanat piyasasının tahmini değeri 10.8 milyar dolar olarak kabul edilmekte ve 2030 yılına kadar 23.79 milyar dolara kadar büyümesi öngörülmektedir.'
      },
      {
        TITLE: 'Artiox müzayede nedir?',
        BODY: 'Artiox Müzayede, açık artırmayla online eser satın almak için kullanabileceğiniz bir platformdur. Artiox Müzayede’de günlük ve katolog olmak üzere iki müzayede türü vardır. Katolog Müzayedeler toplu olarak açık artırmaya çıkarılan eserlerin tümü satılıncaya dek devam eder. Günlük Müzayede’de ise eserler tek tek açık artırmaya çıkarılır ve her gün bir müzayede olacak şekilde devam eder. Bu şekilde Artiox’ta her gün açık artımaya katılma fırsatınız olur.'
      },
      {
        TITLE: 'Artiox’ta 7/24 işlem yapabilir miyim?',
        BODY: 'Artiox\'ta alım-satım işlemlerinizi 7/24 gerçekleştirebilirsiniz.'
      },
      {
        TITLE: 'Artiox komisyon oranları nelerdir?',
        BODY: 'Artiox\'ta üyelik tamamen ücretsizdir. Alış ve satış komisyonları Türk Lirası ile yapılan işlemlerde %0,5(binde beş)\'tir. Tether (USDT) işlemlerinde ise %1 olarak belirlenmiştir.'
      },
      {
        TITLE: 'Eserler nasıl tokenize ediliyor?',
        BODY: 'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir. Belirlenen ticari değer blok zincir üstünde tokenize edilir. Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.'
      },
    ],
    CATEGORIES: [
      {
        HEADER: 'Artiox Hakkında',
        BODY: 'Artiox nedir? Parçalı sanat yatırımı nedır',
        SVG_PATH: './assets/images/v2/ic_artiox.svg#ic_artiox',
        PATH: 'about-artiox',
        QUESTIONS: [
          {
            TITLE: 'Artiox nedir?',
            BODY: 'Artiox, değerli sanat eserlerini eksper onaylı ve sigortalı bir şekilde blockchain teknolojisiyle tokenize ederek kullanıcılara arz eden ve bu sayede kullanıcıların tbu sanat eserlerine diledikleri miktarlarda yatırım yapabilmelerini sağlayarak sanat yatırımlarını daha erişilebilir ve demokratik hale getiren yeni nesil bir sanat yatırım platformdur.'
          },
          {
            TITLE: 'Neden Artiox?',
            BODY: 'Sanat eseri yatırımcılığı, alternatif yatırım seçenekleri arasında oldukça düşük risk taşıyan bir yatırım türü olarak öne çıkmaktadır. Diğer yatırım sınıflarıyla düşük korelasyona sahip olan sanat yatırımı, bu yönüyle ekonomik kriz dönemlerinde dahi istikrarını koruma potansiyeline sahiptir. Ek olarak son dönemde geleneksel yatırım araçlarının beklenen getirileri sunmaması da yatırımcıları sanat alanına yönlendiren önemli bir faktördür. Artiox, sanat yatırımı süreçlerini finans teknolojisi alanındaki yeniliklerle birleştirerek daha pratik ve erişilebilir hale getirmeyi hedefliyor. Bu yaklaşımla, giriş sermayesi bariyeri, saklama maliyeti ve orijinallik endişesi olmadan yatırımcıların hızlı ve güvenli bir şekilde sanat yatırımı yapabilmelerini sağlar.'
          },
          {
            TITLE: 'Eserler nerede tutuluyor?',
            BODY: 'Artiox’ta listelenen eserler fiziksel durumlarının korunması için sanat eserlerini muhafaza etmek üzere tasarlanmış güvenli depolarda saklanır.'
          },

          {
            TITLE: 'Varlığa dayalı token nedir?',
            BODY: 'Varlığa dayalı token(asset-backed tokens), fiziksel bir varlığın dijital temsilidir. Bu varlık, sanat eseri, gayrimenkul, hisse senedi, emtia veya başka herhangi bir fiziksel varlık olabilir. Varlığa dayalı tokenler, blok zinciri teknolojisi kullanılarak oluşturulur. Bu, tokenlerin güvenli ve şeffaf bir şekilde saklanmasına, transfer edilmesine veya temsil ettiği fiziksel varlığa paydaş olunabilmesine olanak tanır.' +
              'Varlığa dayalı tokenler (asset-backed tokens), gerçek dünya varlıklarını kripto para veya dijital varlık dünyasında entegre eden araçlardır. Bölünebilirlik ve şeffaflık gibi çeşitli faydalar sunmakla birlikte fiziksel varlıklara yatırım yapmayı sağladıkları için daha güvenilirlerdir.'
          },

          {
            TITLE: 'Artiox müzayededen aldığım eserin orijinalliğinden nasıl emin olabilirim?',
            BODY: 'Artiox’ta arz edilen eserlerin orjinallikleri bağımsız eksperler tarafından onaylanmıştır. Eksper raporlarını kolay-al sat sayfasının solundaki panelde görebilirsiniz.'
          },

          {
            TITLE: 'Eserler sigortalı mı?',
            BODY: 'Artiox tarafından tokenize edilen eserlerin tamamı genel satışa çıkmadan önce  alanında uzman eksperler tarafından belirlenen değeri üzerinden sigortalanır.'
          },

          {
            TITLE: 'Artiox’taki eserlerin fiyatları nasıl belirlenir?',
            BODY: 'Artiox’ta listelenen ve müzayedeye çıkan eserlerin fiyatları bağımsız eksperler tarafından tür, sanatçının bilinirliği, eserin durumu, özgünlüğü ve piyasa rayici gibi faktörler göz önünde bulundurularak belirlenir.'
          },

          {
            TITLE: 'Yatırım sınıfı sanat eseri nedir?',
            BODY: 'Yatırım sınıfı sanat eseri, orta/uzun vadede değer kazanması beklenen sanat eseridir. Bu eserler tanınmış sanatçılar tarafından yapılmış, iyi korunmuş ve nadir bulunan eserlerdir. Yatırım sınıfı sanat eserleri yüksek fiyatlı olsa da, orta/uzun vadede değer kazanarak yatırımcısına kar sağlama potansiyeline sahiptir.' +
              'Yatırım sınıfı sanat eserleri, diğer sanat eserlerinden farklı olarak finansal yatırım amacı taşıyan özellikleriyle ayrılır ve yatırımcılar için alternatif bir varlık sınıfı sunar. Bunlar, finansal değer artışı potansiyeline sahip olan ve portföy çeşitlendirmek amacıyla kullanılan sanat eserleridir.'
          },

          {
            TITLE: 'Artiox tokenlerinin türü nedir?',
            BODY: 'Artiox, eserleri tokenize ederken Ethereum blok zincirinde oldukça yaygın olan ERC-20 standardını kullanır.'
          },

          {
            TITLE: 'ERC - 20 tokeni nedir?',
            BODY: 'ERC-20 standardı kullanılarak oluşturulan ERC-20 tokenleri , Ethereum blok zincirinde yaygın olarak kullanılan bir token standardını temsil eder. ERC-20 tokenleri transfer edilebilen, bölünebilen, aynı akıllı kontrata tabi olan her birinin birbiri yerine geçtiği(fungible) ve Ethereum zincirinin merkeziyetsizlik, değiştirilemezlik ve şeffaflık özelliklerini taşıyan tokenlerdir.'
          },

          {
            TITLE: 'Artiox ne zaman kuruldu?',
            BODY: 'Mayıs 2019’da aldığı tohum öncesi yatırım ile Ankara’da kurulan Artiox, 2020 yılında ODTÜ Teknokent firması olarak faaliyetlerini sürdürmeye başladı.'
          },

          {
            TITLE: 'Online sanat marketinin büyüklüğü nedir?',
            BODY: 'Sanat dünyası ile ilgili analizler ve öngörülerde bulunan Art Basel’ın güncel raporuna göre 2023 yılında online sanat piyasasının tahmini değeri 10.8 milyar dolar olarak kabul edilmekte ve 2030 yılına kadar 23.79 milyar dolara kadar büyümesi öngörülmektedir.'
          },

          {
            TITLE: 'Artiox tokenlerinin NFT\'den farkı nedir?',
            BODY: 'Artiox’taki sanat eserlerini temsil eden tokenler varlığa dayalı tokenlerdir ve ERC-20(fungible) standardındadır. Diğer bir deyişle bir eseri temsil eden tokenlerin hepsi birbiri ile aynıdır ve eserin değerini eşit olarak temsil ederler. Bu standarttaki tokenler gerçek dünya varlıklarını, gayrimenkullerden emtialara ya da para birimlerine(USD, EUR) kadar farklı türlerdeki değerleri yansıtarak finansal piyasalarda kullanılır ve bölünebilirler. NFT\'ler ise benzersiz dijital veya fiziksel varlıkları temsil ederler ve temsil ettikleri varlığın tamamını ya da benzersiz bir bölümünü ifade ederler. Örneğin, Artiox’taki her bir eserin bir adet kontratı ve ona özel token sembolü olmakla beraber, eseri parçalara/hisselere ayırmakta kullanılan bu tokenlerin her biri birbirinin aynısıdır, bu şekilde ortak olunabilir ve aktarabilirler. NFT’ler ise benzersiz tokenlerdir. Yani her bir NFT ikamesi olmadan tek başına benzersiz bir token olarak blok zincirde kendine yer bulur.' +
              'Artiox’taki tokenler varlığa dayalı tokenlerdir, bunlar gerçek dünya varlıklarını, gayrimenkullerden emtialara kadar farklı türlerdeki değerleri yansıtarak finansal piyasalarda kullanılır ve genellikle bölünebilirdir. NFT\'ler ise benzersiz dijital veya fiziksel varlıkları temsil ederler. Örneğin, Artiox’taki her bir eserin bir adet kontratı olmakla beraber, eseri hisselere ayırmakta kullanılan tokenlerin her biri birbirinin aynısıdır, bu şekilde takas edilebilirler. NFT’ler ise benzersiz tokenlerdir. Yani her bir NFT değiştirilemez, benzersiz bir token olarak blok zincirde kendine yer bulur.'
          },

          {
            TITLE: 'Artiox\'ta sadece Türkiyeden sanatçıların eserleri mi var?',
            BODY: 'Artiox’ta farklı ülkelerden sanatçıların eserleri Müzayedede satılır veya tokenize edilir.'
          },

          {
            TITLE: 'Eserler nasıl tokenize ediliyor?',
            BODY: 'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir. Belirlenen ticari değer blok zincir üstünde tokenize edilir. Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.'
          },

          {
            TITLE: 'Token fiyatları nasıl belirleniyor?',
            BODY: 'Artiox’ta arz edilen eserlerin token fiyatları, genel satış sürecinde sigorta fiyatı üzerinden belirlenir. Bu da örneğin 500.000 TL’lik sigorta fiyatı olan bir eser için 500.000 adet özel token oluşturularak eseri temsil eden her bir tokeninin 1 TL’ye alınabileceği anlamına gelir. Alım satım için listelenmiş eserlere ait tokenlerin fiyatı ise Artiox kullanıcılarının arz ve talebine göre belirlenmektedir.'
          },

        ]
      },
      {
        HEADER: 'Çekme/Yatırma Hakkında',
        BODY: 'Artiox\'a para nasıl yatırılır? Artiox\'tan para nasıl çekilir?',
        SVG_PATH: './assets/images/v2/ic_wallet.svg#ic_wallet',
        PATH: 'deposit-withdrawal',
        QUESTIONS: [
          {
            TITLE: 'Para yatırma/çekme limiti nedir?',
            BODY: 'Artiox’ta para yatırma/çekme üst limitleri aylık ve günlük olarak hesap seviyenize göre belirlenmektedir. Para yatırma için alt limit bulunmamaktadır. Para çekme tarafında ise en az 100 TL alt limiti vardır.'
          },


          {
            TITLE: 'Artiox’ta 7/24 işlem yapabilir miyim?',
            BODY: 'Artiox\'ta alım-satım işlemlerinizi 7/24 gerçekleştirebilirsiniz.'
          },


          {
            TITLE: 'Artiox komisyon oranları nelerdir?',
            BODY: 'Artiox\'ta üyelik tamamen ücretsizdir. Alış ve satış komisyonları Türk Lirası ile yapılan işlemlerde %0,5(binde beş)\'tir. Tether (USDT) işlemlerinde ise %1 olarak belirlenmiştir.'
          },


          {
            TITLE: 'Türk lirası yatırma kuralları nelerdir?',
            BODY: 'Kredi kartı ve ATM üzerinden yapılan transferler kabul edilmemektedir. Transfer yaparken alıcı unvanını "Artiox Kripto Varlık Alım Satım Platformu A.Ş." olarak belirtmeyi unutmayınız. Şirketimize ait IBAN numarasını doğru bir şekilde girmeniz gerekmektedir. Ayrıca, size özel olarak verilen transfer kodunu açıklama bölümüne eklemeniz önemlidir ve bu kod dışında herhangi bir açıklama eklememeniz gerekmektedir. Eksik veya hatalı bilgiler nedeniyle onaylanamayan transferler, banka komisyonları düşülerek 5 iş günü içinde geri gönderilecektir. Havale ile gerçekleştirilen transferler 7/24 hesaplarınıza yansıtılırken, EFT ile gerçekleştirilen transferler sadece mesai saatleri içinde işlenmektedir.' +
              'Papara veya diğer elektronik para şirketleri üzerinden yapılan yatırmalar geçerli değildir ve bu tür transferler, banka komisyonları düşülerek 5 iş günü içinde geri gönderilecektir. Bu şekilde yapılan transferlerle ilgili iade işlemleri için transferinize ilişkin dekont ile birlikte <a>support@artiox.com</a> adresine ulaşmanızı rica ederiz.'
          },


          {
            TITLE: 'Türk lirası çekme kuralları nelerdir?',
            BODY: 'Lütfen para çekme işlemleri için kullanacağınız banka hesabının, Artiox\'a verdiğiniz kimlik bilgilerinizle uyumlu ve sizin adınıza kayıtlı olmasına özen gösterin. Aksi takdirde çekme talebiniz onaylanmayabilir. Çekme talebinizi işleme koyabilmemiz için e-posta onayı vermeyi unutmamanız önemlidir. Onaylanmayan talepler 24 saat sonra zaman aşımına uğrar ve ilgili miktar kullanılabilir bakiyenize geri yatırılır. Çekmek istediğiniz tutar, kullanılabilir bakiyenizin miktarını aşmamalıdır. Minimum para çekme limiti 100 Türk Lirası olarak belirlenmiştir ve her çekme işlemi için 4 TL komisyon kesilir. Garanti Bankası\'na ait hesabınıza 7/24 çekme talimatı verebilirsiniz, ancak diğer banka hesapları için talepler EFT işlem saatleri içerisinde gerçekleştirilecektir.'
          },


          {
            TITLE: 'Emir defteri nedir?',
            BODY: 'Üye girişi yaptıktan sonra "Al-Sat" bölümüne erişerek "Gelişmiş" sekmesine tıklayın. Bu sekme altında, emir defteri adı verilen elektronik liste yer almaktadır. Emir defteri, işlem çiftine ilişkin verilen alım ve satım emirlerinin fiyat sırasına göre kaydedildiği bir sistemdir. İçerdiği bilgilere göre, alıcıların ve satıcıların ilgi seviyelerini yansıtır. Emir defteri, işlem çiftinin alım-satım işlemlerinin izlenmesi ve fiyat hareketlerinin takip edilmesi açısından önemlidir.'
          },


          {
            TITLE: 'Emir defteri nasıl çalışır?',
            BODY: 'Satış emirleri, sağ tarafta en düşük fiyattan en yükseğe doğru sıralanmıştır, ve alış emirleri sol tarafta en yüksek fiyattan en düşüğe doğru sıralanmıştır. Her bir fiyat seviyesindeki emirlerin yanında belirtilen miktar, o seviyede bulunan alım veya satım emirlerinin toplam adedini ifade eder. "Son fiyat" terimi, en son gerçekleşen işlemin fiyatını temsil eder ve bu fiyat, emir defterindeki satış ve alış emirlerinin son eşleşmesini yansıtır. Aynı fiyat seviyesinde, farklı zamanlarda verilen emirler kronolojik sıraya göre kaydedilir ve bu nedenle eşleşen emirlerin sıralaması, emirlerin veriliş sırasını izler.'
          },


          {
            TITLE: 'Limit emri nedir?',
            BODY: 'Limit emri vererek ticaret yapmak istediğiniz token için belirlediğiniz fiyat ve miktarı girerek açık emir oluşturabilirsiniz. Bu işlem, siz bilgisayar ya da mobil cihazınızın başında olmasanız bile emrinizdeki şartlar gerçekleştiğinde otomatik olarak gerçekleşir. Oluşturduğunuz alış veya satış emirlerini, fiyat ve miktar bilgilerini girerek açık emirler bölümünde detaylı olarak inceleyebilir ve istediğiniz zaman iptal edebilirsiniz.'
          },


          {
            TITLE: 'Limit emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
            BODY: 'Piyasa fiyatının üçte birinden daha düşük bir fiyattan alış emri veya 3 katından fazla bir fiyattan satış emri giremezsiniz. Ayrıca, açık emrinizin bir kısmı gerçekleşmiş olsa bile, emri iptal etmek istediğinizde sadece gerçekleşmemiş miktar iptal edilir, gerçekleşen kısım geri alınamaz. Kullanılabilir bakiye, açık emirleriniz olduğunda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır.'
          },


          {
            TITLE: 'Piyasa emri nedir?',
            BODY: '"Piyasa" işlemlerinde; ticaretini yapmak istediğiniz tokenin miktarını girmeniz yeterlidir. Fiyat bilgisi piyasa olarak otomatik doldurulur. Adetini girdiğiniz token ardından “AL” butonuna bastığınızda emir defterinde bulunan en düşük fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar alım gerçekleşecektir. Satış yaparken de aynı şekilde emir defterinde bulunan en yüksek fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar satım gerçekleşir. Kısacası işlemleriniz en iyi fiyattan gerçekleşmiş olacaktır. İşlem geçmişinizden ticareti gerçekleşen ortalama işlem fiyatını inceleyebilirsiniz.'
          },


          {
            TITLE: 'Piyasa emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
            BODY: 'Alış veya satış yapmak istediğiniz miktarı belirledikten ve “AL” ya da “SAT” butonuna tıkladıktan sonra bu işlemi geri almanız mümkün değildir. Gerçekleşen işlemler geri alınamaz ve bu nedenle dikkatle işlem yapmanız önemlidir. Yaklaşık ifadesi, emir defterine göre hesaplandığı için anlık fiyat değişikliklerinde küçük farklılıklar yaşanabilir. Kullanılabilir bakiye, alış ya da satış işlemleri için kullanabileceğiniz miktarı ifade eder. Ancak bu miktar, açık emirleriniz olduğu durumda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır. Bu nedenle hesabınızdaki bakiye ve açık emirleriniz arasındaki ilişkiyi göz önünde bulundurmalısınız.'
          },

        ]
      },
      {
        HEADER: 'Müzayede Hakkında',
        BODY: 'Artiox müzayede nedir? Müzayededen nasıl eser alınır?',
        SVG_PATH: './assets/images/v2/ic_auction.svg#ic_auction',
        PATH: 'auction',
        QUESTIONS: [
          {
            TITLE: 'Artiox müzayede nedir?',
            BODY: 'Artiox Müzayede, açık artırmayla online eser satın almak için kullanabileceğiniz bir platformdur. Artiox Müzayede’de günlük ve katolog olmak üzere iki müzayede türü vardır. Katolog Müzayedeler toplu olarak açık artırmaya çıkarılan eserlerin tümü satılıncaya dek devam eder. Günlük Müzayede’de ise eserler tek tek açık artırmaya çıkarılır ve her gün bir müzayede olacak şekilde devam eder. Bu şekilde Artiox’ta her gün açık artımaya katılma fırsatınız olur.'
          },

          {
            TITLE: 'Artiox Müzayede’de nasıl eser satabilirim?',
            BODY: 'Artiox Müzayede sayfasında bulunan Eser Kabul formunu doldurup eserin görseli, sertifikası, ebatları vb. bilgileri ilettikten sonra eseriniz değerlendirmeye alınır. Değerlendirme aşamasından geçen eserler Artiox tarafından müzayedede listelenir. Bu sayede eserlerinizi Artiox Müzayede’de satabilirsiniz.'
          },

          {
            TITLE: 'Sanatçı olarak Artiox\'u nasıl kullanırım?',
            BODY: 'Sanatçı olarak Artiox’u eserlerinizi müzayedeyle satmak için kullanabilirsiniz. Bunun için Eser Kabul formunu doldurmanız gerekmektedir. Bu sayede eseriniz Artiox tarafından değerlendirmeye alınacaktır. Değerlendirme aşamasını geçen eserler Artiox Müzayede’de listelenir.'
          },

          {
            TITLE: 'Artiox\'ta resim/tablo dışında sanat eseri alıp satabilir miyim?',
            BODY: 'Artiox’ta şu an yalnızca resim/tablo türündeki yatırım sınıfı sanat eserlerinin alım-satımı mümkündür.'
          },

          {
            TITLE: 'Artiox Müzayede’de "alıcıya maliyet" nedir?',
            BODY: 'Artiox Müzayede’de teklif verirken ve müzayede bittiğinde size bildirilen “alıcıya maaliyet” eserin son fiyatını, komisyonu ve bunların toplamı üzerinden hesaplanan KDV’yi içerir.'
          },

          {
            TITLE: 'Koleksiyoner olarak Artiox Müzayede’de eser satabilir miyim?',
            BODY: '\n' +
              'Artiox Müzayede sayfasında yer alan Eser Kabul formunu doldurduğunuzda eserleriniz Artiox tarafından değerlendirmeye alınır. Müzayedede listelenmeye uygun görülen eserler kabul edilir. Bu şekilde sahip olduğunuz sanat eserlerini satabilirsiniz.'
          },
        ]
      },
      {
        HEADER: 'Alım-Satım İşlemleri',
        BODY: 'Basit al-sat, gelişmiş al-sat, emir defteri, genel satış işlemleri nedir?',
        SVG_PATH: './assets/images/v2/ic_buy_sell.svg#ic_buy_sell',
        PATH: 'buy-sell',
        QUESTIONS: [
          {
            TITLE: 'Emir defteri nedir?',
            BODY: 'Üye girişi yaptıktan sonra "Al-Sat" bölümüne erişerek "Gelişmiş" sekmesine tıklayın. Bu sekme altında, emir defteri adı verilen elektronik liste yer almaktadır. Emir defteri, işlem çiftine ilişkin verilen alım ve satım emirlerinin fiyat sırasına göre kaydedildiği bir sistemdir. İçerdiği bilgilere göre, alıcıların ve satıcıların ilgi seviyelerini yansıtır. Emir defteri, işlem çiftinin alım-satım işlemlerinin izlenmesi ve fiyat hareketlerinin takip edilmesi açısından önemlidir.'
          },

          {
            TITLE: 'Emir defteri nasıl çalışır?',
            BODY: 'Satış emirleri, sağ tarafta en düşük fiyattan en yükseğe doğru sıralanmıştır, ve alış emirleri sol tarafta en yüksek fiyattan en düşüğe doğru sıralanmıştır. Her bir fiyat seviyesindeki emirlerin yanında belirtilen miktar, o seviyede bulunan alım veya satım emirlerinin toplam adedini ifade eder. "Son fiyat" terimi, en son gerçekleşen işlemin fiyatını temsil eder ve bu fiyat, emir defterindeki satış ve alış emirlerinin son eşleşmesini yansıtır. Aynı fiyat seviyesinde, farklı zamanlarda verilen emirler kronolojik sıraya göre kaydedilir ve bu nedenle eşleşen emirlerin sıralaması, emirlerin veriliş sırasını izler.'
          },

          {
            TITLE: 'Limit emri nedir?',
            BODY: 'Limit emri vererek ticaret yapmak istediğiniz token için belirlediğiniz fiyat ve miktarı girerek açık emir oluşturabilirsiniz. Bu işlem, siz bilgisayar ya da mobil cihazınızın başında olmasanız bile emrinizdeki şartlar gerçekleştiğinde otomatik olarak gerçekleşir. Oluşturduğunuz alış veya satış emirlerini, fiyat ve miktar bilgilerini girerek açık emirler bölümünde detaylı olarak inceleyebilir ve istediğiniz zaman iptal edebilirsiniz.'
          },

          {
            TITLE: 'Limit emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
            BODY: 'Piyasa fiyatının üçte birinden daha düşük bir fiyattan alış emri veya 3 katından fazla bir fiyattan satış emri giremezsiniz. Ayrıca, açık emrinizin bir kısmı gerçekleşmiş olsa bile, emri iptal etmek istediğinizde sadece gerçekleşmemiş miktar iptal edilir, gerçekleşen kısım geri alınamaz. Kullanılabilir bakiye, açık emirleriniz olduğunda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır.'
          },

          {
            TITLE: 'Piyasa emri nedir?',
            BODY: '"Piyasa" işlemlerinde; ticaretini yapmak istediğiniz tokenin miktarını girmeniz yeterlidir. Fiyat bilgisi piyasa olarak otomatik doldurulur. Adetini girdiğiniz token ardından “AL” butonuna bastığınızda emir defterinde bulunan en düşük fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar alım gerçekleşecektir. Satış yaparken de aynı şekilde emir defterinde bulunan en yüksek fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar satım gerçekleşir. Kısacası işlemleriniz en iyi fiyattan gerçekleşmiş olacaktır. İşlem geçmişinizden ticareti gerçekleşen ortalama işlem fiyatını inceleyebilirsiniz.'
          },

          {
            TITLE: 'Piyasa emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
            BODY: 'Alış veya satış yapmak istediğiniz miktarı belirledikten ve “AL” ya da “SAT” butonuna tıkladıktan sonra bu işlemi geri almanız mümkün değildir. Gerçekleşen işlemler geri alınamaz ve bu nedenle dikkatle işlem yapmanız önemlidir. Yaklaşık ifadesi, emir defterine göre hesaplandığı için anlık fiyat değişikliklerinde küçük farklılıklar yaşanabilir. Kullanılabilir bakiye, alış ya da satış işlemleri için kullanabileceğiniz miktarı ifade eder. Ancak bu miktar, açık emirleriniz olduğu durumda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır. Bu nedenle hesabınızdaki bakiye ve açık emirleriniz arasındaki ilişkiyi göz önünde bulundurmalısınız.'
          },
        ]
      },
      {
        HEADER: 'Üyelik işlemleri Hakkında',
        BODY: 'Üyelik nasıl açılır? Şifremi unuttum, şifre değiştirme. Artiox\'a üyelik ücretli mi?',
        SVG_PATH: './assets/images/v2/ic_user.svg#ic_user',
        PATH: 'membership-processes',
        QUESTIONS: [
          {
            TITLE: 'Artiox’a nasıl üye olurum?',
            BODY: 'Artiox Web sitesine veya uygulamasına girdikten sonra üye ol / başla butonuna tıklayıp gerekli bilgileri doldurarak üye olabilirsiniz.'
          },

          {
            TITLE: 'Artiox\'a üyelik ücretli mi?',
            BODY: 'Artiox’a üye olmak tamamen ücretsizdir.'
          },

          {
            TITLE: 'Şifremi nasıl değiştiririm?',
            BODY: 'Üye girişi yaptıktan sonra profil ikonu altından "Güvenlik" kısmına girerek mevcut hesap şifrenizi girin. Daha sonra oluşturmak istediğiniz yeni şifrenizi belirleyin. Yeni şifreniz en az 8 karakter uzunluğunda olmalı ve içinde en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter bulundurmalıdır. Yeni şifrenizi tekrar girerek doğrulayın ve değişiklikleri kaydetmek için "Değişiklikleri Kaydet" butonuna tıklayın. Böylece şifreniz güncellenmiş olacaktır.'
          },

          {
            TITLE: 'Hesap seviyesi nedir?',
            BODY: 'Artiox’a ilk defa üye olduğunuzda hesabınız otomatik olarak Seviye 0 şeklinde kaydedilir. Seviye 0 hesabınızı kullanarak Artiox’ta listelenen sanat eserlerini ve fiyat bilgilerini inceleyebilirsiniz. Artiox’ta yatırım yapabilmek için hesabınız en az Seviye 1 olmalıdır. Kimlik bilgilerinizi doğruladığınızda hesabınız Seviye 1’e yükseltilir. Seviye 1 hesap size günlük 50.000 TL ve ve aylık 1.000.000 TL yatırma/çekme limiti sağlar. KYC Doğrulama Ekranı’nı kullanarak hesabınızı Seviye 2’ye yükselttiğinizde limitleriniz günlük 1.000.000 TL ve aylık 10.000.000 TL olmak üzere artırılır. Ek olarak Seviye 2 hesapla kripto para kullanarak da yatırma/çekme işlemleri yapabilirsiniz.'
          },

          {
            TITLE: 'Artiox e-mail listesinden nasıl çıkabilirim?',
            BODY: 'Artiox tarafından size gönderilen emaillerde bulunan abonelikten çıkma bağlantısına tıklayarak Artiox e-mail listesinden çıkabilirsiniz.'
          },
        ]
      },
      {
        HEADER: 'Güvenlik İşlemleri Hakkında',
        BODY: 'Artiox kullanıcı güvenliğini nasıl sağlar? Sözleşmelere nasıl ulaşırım? Blockchain nedir?',
        SVG_PATH: './assets/images/v2/ic_security.svg#ic_security',
        PATH: 'security-procedures',
        QUESTIONS: [
          {
            TITLE: 'İşlem yapmak için neden kişisel bilgileri vermek zorundayım?',
            BODY: 'Dijital ortamda yapılan finansal  işlemler için yasal düzenlemelere uyum ve güvenlik nedeniyle işlem yapmak isteyen kullanıcıların kimlik bilgilerine ihtiyaç duyulur. Artiox’ta  yapılan işlemler de diğer finansal piyasalara benzer şekilde kimlik doğrulama ve müşteri tanıma (KYC) süreçlerine tabidir. Bu, hem yasal gereksinimleri yerine getirmek hem de finansal dolandırıcılık ve kötü niyetli faaliyetleri engellemek için önemlidir. Kimlik bilgileri, müşterilerin gerçek kişiler olduğunu ve yasal olarak işlem yapma yetkilerinin olduğunu doğrulamak için kullanılır. Aynı zamanda hesap güvenliği ve risk yönetimi için de kritik bir rol oynar.'
          },

          {
            TITLE: 'Eserlerin eksper onaylı olması ne anlama geliyor?',
            BODY: 'Sanat eserlerinin eksper onaylı olması, bağımsız bir sanat eksperinin, sanatçının kendisinin/ailesinin veya sanatçıyı temsil eden galerinin eseri inceleyerek orijinalliğini ve eserin kondisyon durumunun iyi olduğunu teyit ettiği anlamına gelir. Bu onay, eserin gerçek veya sahte olduğunu tespitten sanatçı kimliğini doğrulamaya kadar farklı bir çok konuyu kapsar. Eksper onayı, sahte eserlerin piyasaya sürülmesini engellerken aynı zamanda koleksiyonerler için önemli bir referans noktasıdır.'
          },

          {
            TITLE: 'Yatırım yaptığım eserin eksper onayını nereden görebilirim?',
            BODY: 'Yatırım yaptığınız eserin eksper onaylarını eserin Kolay Al-Sat sayfasının solundaki panelde görebilirsiniz.'
          },

          {
            TITLE: 'Yatırım yaptığım eserin sigorta bilgilerini nereden görebilirim?',
            BODY: 'Yatırım yaptığınız eserin sigorta raporunu eserin Kolay Al-Sat sayfasının solundaki panelde görebilirsiniz.'
          },
        ]
      },
    ],
    ALTERNATIVE_HEADER: 'Alternatif Destek Hattı',
    ALTERNATIVE_SUPPORT: [
      {
        HEADER: 'Artiox Mail',
        BODY: 'info@artiox.com',
        SVG_PATH: './assets/images/v2/ic_mail.svg#ic_mail',
      },

      {
        HEADER: 'Artiox Linkedin',
        BODY: '@artioxofficial',
        SVG_PATH: '/assets/images/v2/ic_linkedin.svg#ic_linkedin',
      },

      {
        HEADER: 'Artiox Instagram',
        BODY: '@artioxofficial',
        SVG_PATH: '/assets/images/v2/ic_instagram.svg#ic_instagram',
      },

      {
        HEADER: 'Artiox Twitter',
        BODY: '@artiox_official',
        SVG_PATH: '/assets/images/v2/ic_twitter.svg#ic_twitter',
      },
    ]

  },
  PAYMENT: {
    HEADER: 'Kazanılan Eser Bilgileri',
    MANDATORY_FIELDS: '<span class="asterix">* Zorunlu alanları belirtmektedir</span>',
    EDIT: 'Düzenle',
    DELETE: 'Sil',
    DELIVERY_OPTIONS: {
      HEADER: 'Teslimat Seçeneği <span class="asterix">*</span>',
      HEADER_WITHOUT_ASTERIX: 'Teslimat Seçeneği',
      INFORMATION: 'Kargo Bilgileri',
      SHIPPING: 'Kargo',
      SHIPPING_TEXT: 'Kargo bedelı "Alıcı" tarafından ödenmektedir.',
      PICK_UP: 'Elden Teslimat',
      PICK_UP_TEXT: 'Elden teslimat ücretsizdir.'
    },
    DELIVERY_ADDRESS: {
      HEADER: 'Teslimat Adresi <span class="asterix">*</span>',
      HEADER_RAW: 'Teslimat Adresi',
      TEXT: 'Teslimat Adresi Ekle',
      PICK_UP_HEADER: 'Teslimat Adresi',
      PICK_UP_ADDRESS: 'Kızılırmak Mah. YDA Center A/2 Kat: 8 No: 287 Artiox Çankaya/Ankara'
    },
    BILLING_ADDRESS: {
      HEADER: 'Fatura Adresi <span class="asterix">*</span>',
      HEADER_RAW: 'Fatura Adresi',
      TEXT: 'Fatura Adresi Ekle'
    },
    PAYMENT_INFO: {
      HEADER: 'Ödeme Bilgieri',
      MONEY_ORDER: 'Havale/EFT',
      CREDIT_CARD: 'Kredi Kartı (Yakında)',
      BANK_NAME: 'Garanti Bankası',
      ACCOUNT_TITLE: '<span class="payment-info-subtitles">Hesap ismi: </span> Artiox Kripto Varlık Alım Satım Platformu A.Ş.',
      IBAN: '<span class="payment-info-subtitles">IBAN: </span> TR750006200114500006899270',
      MESSAGE: '<span class="payment-info-subtitles">Açıklama: </span> ',
      INFO: 'Lütfen <span class="payment-info-underline" >yatırma kurallarına</span> uyduğunuzdan emın olun.',
      COMPLETE: 'Ödemeyi Tamamla',
      ACCOUNT_TITLE_TEXT: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş.',
      IBAN_NO: 'TR750006200114500006899270',
    },
    PAYMENT_STATUS: {
      HEADER: 'Ödeme Süreci',
      STATUS: {
        PENDING: {
          HEADER: 'Ödeme Bekleniyor',
          TEXT: 'Ödemenizin sistemlerimizde onaylanması beklenmektedir. Ödeme onayladıktan sonra teslimat süreci başlayacaktır',
          IMAGE: './assets/images/waiting_payment.svg#waiting_payment',
        },
        CONFIRMING: {
          HEADER: 'Onay Bekleniyor',
          TEXT: 'Ödemenizin sistemlerimizde onaylanması beklenmektedir. Ödeme onayladıktan sonra teslimat süreci başlayacaktır',
          IMAGE: './assets/images/waiting_approval.svg#waiting_approval',
        },
        APPROVED: {
          HEADER: 'Ödeme Onaylandı',
          TEXT: 'Ödemen sistemimiz tarafından onaylanmıştır. Eserinin kargolanma süreci başlayacaktır',
          IMAGE: './assets/images/approve_icon_colored.svg#approve_icon_colored',
        },
        PACKED: {
          HEADER: 'Eserin kargoya verildi',
          TEXT: 'Eseriniz kargo şirketine teslim edilmiştir.  \n' +
            '<br>Kargo Şirketi: $cargo_firm\n' +
            '<br>Kargo Numarası: $cargo_number\n' +
            '<br>Kargo Link: <a href="$cargo_link" target="_blank">$cargo_link</a>',
          IMAGE: './assets/images/in_transit.svg#in_transit',
        },
        DELIVERED: {
          HEADER: 'Teslim Edildi',
          TEXT: 'Eserinin sana ulaştığını mutlulukla belirtmek isterim. ',
          IMAGE: './assets/images/delivered.svg#delivered',
        },
      }
    }
  },
  USER_AGREEMENT: {
    HEADING: 'Artiox Kullanıcı Sözleşmesi Güncellendi',
    TEXT: '<div> <a href="assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank" class="purple-highlight">Artiox Kullanıcı Sözleşmesi</a>ni sizin için daha güvenli hale getirdik. Yeni kullanıcı sözleşmesini onaylayarak Artiox’taki işlemlerinize devam edebilirsiniz:  </div>',
    LINK: 'Artiox Kullanıcı Sözleşmesi',
    AGREEMENT: 'Yenilenen Artiox Kullanıcı Sözleşmesini  okudum, kabul ediyorum.',
    ACCEPT: 'Onaylıyorum',
    REJECT: 'Vazgeç',
  },
  CITIES: {
    Adana: ['Aladağ', 'Ceyhan', 'Çukurova', 'Feke', 'İmamoğlu', 'Karaisalı', 'Karataş', 'Kozan', 'Pozantı', 'Saimbeyli', 'Sarıçam', 'Seyhan', 'Tufanbeyli', 'Yumurtalık', 'Yüreğir'],
    Adıyaman: ['Besni', 'Çelikhan', 'Gerger', 'Gölbaşı', 'Kahta', 'Merkez', 'Samsat', 'Sincik', 'Tut'],
    Afyonkarahisar: ['Başmakçı', 'Bayat', 'Bolvadin', 'Çay', 'Çobanlar', 'Dazkırı', 'Dinar', 'Emirdağ', 'Evciler', 'Hocalar', 'İhsaniye', 'İscehisar', 'Kızılören', 'Merkez', 'Sandıklı', 'Sinanpaşa', 'Sultandağı', 'Şuhut'],
    Ağrı: ['Diyadin', 'Doğubayazıt', 'Eleşkirt', 'Hamur', 'Merkez', 'Patnos', 'Taşlıçay', 'Tutak'],
    Aksaray: ['Ağaçören', 'Eskil', 'Gülağaç', 'Güzelyurt', 'Merkez', 'Ortaköy', 'Sarıyahşi'],
    Amasya: ['Göynücek', 'Gümüşhacıköy', 'Hamamözü', 'Merkez', 'Merzifon', 'Suluova', 'Taşova'],
    Ankara: ['Akyurt', 'Altındağ', 'Ayaş', 'Bala', 'Beypazarı', 'Çamlıdere', 'Çankaya', 'Çubuk', 'Elmadağ', 'Etimesgut', 'Evren', 'Gölbaşı', 'Güdül', 'Haymana', 'Kalecik', 'Kahramankazan', 'Keçiören', 'Kızılcahamam', 'Mamak', 'Nallıhan', 'Polatlı', 'Pursaklar', 'Sincan', 'Şereflikoçhisar', 'Yenimahalle'],
    Antalya: ['Akseki', 'Aksu', 'Alanya', 'Demre', 'Döşemealtı', 'Elmalı', 'Finike', 'Gazipaşa', 'Gündoğmuş', 'İbradı', 'Kaş', 'Kemer', 'Kepez', 'Konyaaltı', 'Korkuteli', 'Kumluca', 'Manavgat', 'Muratpaşa', 'Serik'],
    Ardahan: ['Çıldır', 'Damal', 'Göle', 'Hanak', 'Merkez', 'Posof'],
    Artvin: ['Ardanuç', 'Arhavi', 'Borçka', 'Hopa', 'Merkez', 'Murgul', 'Şavşat', 'Yusufeli'],
    Aydın: ['Bozdoğan', 'Buharkent', 'Çine', 'Didim', 'Efeler', 'Germencik', 'İncirliova', 'Karacasu', 'Karpuzlu', 'Koçarlı', 'Köşk', 'Kuşadası', 'Kuyucak', 'Merkez', 'Nazilli', 'Söke', 'Sultanhisar', 'Yenipazar'],
    Balıkesir: ['Altıeylül', 'Ayvalık', 'Balya', 'Bandırma', 'Bigadiç', 'Burhaniye', 'Dursunbey', 'Edremit', 'Erdek', 'Gömeç', 'Gönen', 'Havran', 'İvrindi', 'Karesi', 'Kepsut', 'Manyas', 'Marmara', 'Savaştepe', 'Sındırgı', 'Susurluk'],
    Bartın: ['Amasra', 'Kurucaşile', 'Merkez', 'Ulus'],
    Batman: ['Beşiri', 'Gercüş', 'Hasankeyf', 'Kozluk', 'Merkez', 'Sason'],
    Bayburt: ['Aydıntepe', 'Demirözü', 'Merkez'],
    Bilecik: ['Bozüyük', 'Gölpazarı', 'İnhisar', 'Merkez', 'Osmaneli', 'Pazaryeri', 'Söğüt', 'Yenipazar'],
    Bingöl: ['Adaklı', 'Genç', 'Karlıova', 'Kiğı', 'Merkez', 'Solhan', 'Yayladere', 'Yedisu'],
    Bitlis: ['Adilcevaz', 'Ahlat', 'Güroymak', 'Hizan', 'Merkez', 'Mutki', 'Tatvan'],
    Bolu: ['Dörtdivan', 'Gerede', 'Göynük', 'Kıbrıscık', 'Mengen', 'Merkez', 'Mudurnu', 'Seben', 'Yeniçağa'],
    Burdur: ['Ağlasun', 'Altınyayla', 'Bucak', 'Çavdır', 'Çeltikçi', 'Gölhisar', 'Karamanlı', 'Kemer', 'Merkez', 'Tefenni', 'Yeşilova'],
    Bursa: ['Büyükorhan', 'Gemlik', 'Gürsu', 'Harmancık', 'İnegöl', 'İznik', 'Karacabey', 'Keles', 'Kestel', 'Mudanya', 'Mustafakemalpaşa', 'Nilüfer', 'Orhaneli', 'Orhangazi', 'Osmangazi', 'Yenişehir', 'Yıldırım'],
    Çanakkale: ['Ayvacık', 'Bayramiç', 'Biga', 'Bozcaada', 'Çan', 'Eceabat', 'Ezine', 'Gelibolu', 'Gökçeada', 'Lapseki', 'Merkez', 'Yenice'],
    Çankırı: ['Atkaracalar', 'Bayramören', 'Çerkeş', 'Eldivan', 'Ilgaz', 'Kızılırmak', 'Korgun', 'Kurşunlu', 'Merkez', 'Orta', 'Şabanözü', 'Yapraklı'],
    Çorum: ['Alaca', 'Bayat', 'Boğazkale', 'Dodurga', 'İskilip', 'Kargı', 'Laçin', 'Mecitözü', 'Merkez', 'Oğuzlar', 'Ortaköy', 'Osmancık', 'Sungurlu', 'Uğurludağ'],
    Denizli: ['Acıpayam', 'Babadağ', 'Baklan', 'Bekilli', 'Beyağaç', 'Bozkurt', 'Buldan', 'Çal', 'Çameli', 'Çardak', 'Çivril', 'Güney', 'Honaz', 'Kale', 'Merkez', 'Pamukkale', 'Sarayköy', 'Serinhisar', 'Tavas'],
    Diyarbakır: ['Bağlar', 'Bismil', 'Çermik', 'Çınar', 'Çüngüş', 'Dicle', 'Eğil', 'Ergani', 'Hani', 'Hazro', 'Kayapınar', 'Kocaköy', 'Kulp', 'Lice', 'Silvan', 'Sur', 'Yenişehir'],
    Düzce: ['Akçakoca', 'Cumayeri', 'Çilimli', 'Gölyaka', 'Gümüşova', 'Kaynaşlı', 'Merkez', 'Yığılca'],
    Edirne: ['Enez', 'Havsa', 'İpsala', 'Keşan', 'Lalapaşa', 'Meriç', 'Merkez', 'Süloğlu', 'Uzunköprü'],
    Elazığ: ['Ağın', 'Alacakaya', 'Arıcak', 'Baskil', 'Karakoçan', 'Keban', 'Kovancılar', 'Maden', 'Merkez', 'Palu', 'Sivrice'],
    Erzincan: ['Çayırlı', 'İliç', 'Kemah', 'Kemaliye', 'Merkez', 'Otlukbeli', 'Refahiye', 'Tercan', 'Üzümlü'],
    Erzurum: ['Aşkale', 'Aziziye', 'Çat', 'Hınıs', 'Horasan', 'İspir', 'Karaçoban', 'Karayazı', 'Köprüköy', 'Narman', 'Oltu', 'Olur', 'Palandöken', 'Pasinler', 'Pazaryolu', 'Şenkaya', 'Tekman', 'Tortum', 'Uzundere', 'Yakutiye'],
    Eskişehir: ['Alpu', 'Beylikova', 'Çifteler', 'Günyüzü', 'Han', 'İnönü', 'Mahmudiye', 'Merkez', 'Mihalgazi', 'Mihalıççık', 'Odunpazarı', 'Sarıcakaya', 'Seyitgazi', 'Sivrihisar', 'Tepebaşı'],
    Gaziantep: ['Araban', 'İslahiye', 'Karkamış', 'Nizip', 'Nurdağı', 'Oğuzeli', 'Şahinbey', 'Şehitkamil', 'Yavuzeli'],
    Giresun: ['Alucra', 'Bulancak', 'Çamoluk', 'Çanakçı', 'Dereli', 'Doğankent', 'Espiye', 'Eynesil', 'Görele', 'Güce', 'Keşap', 'Merkez', 'Piraziz', 'Şebinkarahisar', 'Tirebolu', 'Yağlıdere'],
    Gümüşhane: ['Kelkit', 'Köse', 'Kürtün', 'Merkez', 'Şiran', 'Torul'],
    Hakkari: ['Çukurca', 'Merkez', 'Şemdinli', 'Yüksekova'],
    Hatay: ['Altınözü', 'Antakya', 'Arsuz', 'Belen', 'Defne', 'Dörtyol', 'Erzin', 'Hassa', 'İskenderun', 'Kırıkhan', 'Kumlu', 'Payas', 'Reyhanlı', 'Samandağ', 'Yayladağı'],
    Iğdır: ['Aralık', 'Karakoyunlu', 'Merkez', 'Tuzluca'],
    Isparta: ['Aksu', 'Atabey', 'Eğirdir', 'Gelendost', 'Gönen', 'Keçiborlu', 'Merkez', 'Senirkent', 'Sütçüler', 'Şarkikaraağaç', 'Uluborlu', 'Yalvaç', 'Yenişarbademli'],
    İstanbul: ['Adalar', 'Arnavutköy', 'Ataşehir', 'Avcılar', 'Bağcılar', 'Bahçelievler', 'Bakırköy', 'Başakşehir', 'Bayrampaşa', 'Beşiktaş', 'Beykoz', 'Beylikdüzü', 'Beyoğlu', 'Büyükçekmece', 'Çatalca', 'Çekmeköy', 'Esenler', 'Esenyurt', 'Eyüp', 'Fatih', 'Gaziosmanpaşa', 'Güngören', 'Kadıköy', 'Kağıthane', 'Kartal', 'Küçükçekmece', 'Maltepe', 'Pendik', 'Sancaktepe', 'Sarıyer', 'Silivri', 'Sultanbeyli', 'Sultangazi', 'Şile', 'Şişli', 'Tuzla', 'Ümraniye', 'Üsküdar', 'Zeytinburnu'],
    İzmir: ['Aliağa', 'Balçova', 'Bayındır', 'Bayraklı', 'Bergama', 'Beydağ', 'Bornova', 'Buca', 'Çeşme', 'Çiğli', 'Dikili', 'Foça', 'Gaziemir', 'Güzelbahçe', 'Karabağlar', 'Karaburun', 'Karşıyaka', 'Kemalpaşa', 'Kınık', 'Kiraz', 'Konak', 'Menderes', 'Menemen', 'Narlıdere', 'Ödemiş', 'Seferihisar', 'Selçuk', 'Tire', 'Torbalı', 'Urla'],
    Kahramanmaraş: ['Afşin', 'Andırın', 'Çağlayancerit', 'Dulkadiroğlu', 'Ekinözü', 'Elbistan', 'Göksun', 'Merkez', 'Nurhak', 'Onikişubat', 'Pazarcık', 'Türkoğlu'],
    Karabük: ['Eflani', 'Eskipazar', 'Karabük', 'Ovacık', 'Safranbolu', 'Yenice'],
    Karaman: ['Ayrancı', 'Başyayla', 'Ermenek', 'Karaman', 'Kazımkarabekir', 'Sarıveliler'],
    Kars: ['Akyaka', 'Arpaçay', 'Digor', 'Kağızman', 'Kars', 'Sarıkamış', 'Selim', 'Susuz'],
    Kastamonu: ['Abana', 'Ağlı', 'Araç', 'Azdavay', 'Bozkurt', 'Cide', 'Çatalzeytin', 'Daday', 'Devrekani', 'Doğanyurt', 'Hanönü', 'İhsangazi', 'İnebolu', 'Kastamonu', 'Küre', 'Pınarbaşı', 'Seydiler', 'Şenpazar', 'Taşköprü', 'Tosya'],
    Kayseri: ['Akkışla', 'Bünyan', 'Develi', 'Felahiye', 'Hacılar', 'İncesu', 'Kocasinan', 'Melikgazi', 'Özvatan', 'Pınarbaşı', 'Sarıoğlan', 'Sarız', 'Talas', 'Tomarza', 'Yahyalı', 'Yeşilhisar'],
    Kırıkkale: ['Bahşılı', 'Balışeyh', 'Çelebi', 'Delice', 'Karakeçili', 'Keskin', 'Kırıkkale', 'Sulakyurt', 'Yahşihan'],
    Kırklareli: ['Babaeski', 'Demirköy', 'Kırklareli', 'Kofçaz', 'Lüleburgaz', 'Pehlivanköy', 'Pınarhisar', 'Vize'],
    Kırşehir: ['Akçakent', 'Akpınar', 'Boztepe', 'Çiçekdağı', 'Kaman', 'Kırşehir', 'Mucur'],
    Kilis: ['Elbeyli', 'Merkez', 'Musabeyli', 'Polateli'],
    Kocaeli: ['Başiskele', 'Çayırova', 'Darıca', 'Derince', 'Dilovası', 'Gebze', 'Gölcük', 'İzmit', 'Kandıra', 'Karamürsel', 'Kartepe', 'Körfez'],
    Konya: ['Ahırlı', 'Akören', 'Akşehir', 'Altınekin', 'Beyşehir', 'Bozkır', 'Cihanbeyli', 'Çeltik', 'Çumra', 'Derbent', 'Derebucak', 'Doğanhisar', 'Emirgazi', 'Ereğli', 'Güneysınır', 'Hadim', 'Halkapınar', 'Hüyük', 'Ilgın', 'Kadınhanı', 'Karapınar', 'Karatay', 'Kulu', 'Meram', 'Sarayönü', 'Selçuklu', 'Seydişehir', 'Taşkent', 'Tuzlukçu', 'Yalıhüyük', 'Yunak'],
    Kütahya: ['Altıntaş', 'Aslanapa', 'Çavdarhisar', 'Domaniç', 'Dumlupınar', 'Emet', 'Gediz', 'Hisarcık', 'Kütahya', 'Pazarlar', 'Şaphane', 'Simav', 'Tavşanlı'],
    Malatya: ['Akçadağ', 'Arapgir', 'Arguvan', 'Battalgazi', 'Darende', 'Doğanşehir', 'Doğanyol', 'Hekimhan', 'Kale', 'Kuluncak', 'Merkez', 'Pütürge', 'Yazıhan', 'Yeşilyurt'],
    Manisa: ['Ahmetli', 'Akhisar', 'Alaşehir', 'Demirci', 'Gölmarmara', 'Gördes', 'Kırkağaç', 'Köprübaşı', 'Kula', 'Merkez', 'Salihli', 'Sarıgöl', 'Saruhanlı', 'Selendi', 'Soma', 'Turgutlu', 'Yunusemre'],
    Mardin: ['Artuklu', 'Dargeçit', 'Derik', 'Kızıltepe', 'Mazıdağı', 'Midyat', 'Nusaybin', 'Ömerli', 'Savur', 'Yeşilli'],
    Mersin: ['Akdeniz', 'Anamur', 'Aydıncık', 'Bozyazı', 'Çamlıyayla', 'Erdemli', 'Gülnar', 'Mezitli', 'Mut', 'Silifke', 'Tarsus', 'Toroslar', 'Yenişehir'],
    Muğla: ['Bodrum', 'Dalaman', 'Datça', 'Fethiye', 'Kavaklıdere', 'Köyceğiz', 'Marmaris', 'Milas', 'Ortaca', 'Seydikemer', 'Ula', 'Yatağan'],
    Muş: ['Bulanık', 'Hasköy', 'Korkut', 'Malazgirt', 'Merkez', 'Varto'],
    Nevşehir: ['Acıgöl', 'Avanos', 'Derinkuyu', 'Gülşehir', 'Hacıbektaş', 'Kozaklı', 'Merkez', 'Ürgüp'],
    Niğde: ['Altunhisar', 'Bor', 'Çamardı', 'Çiftlik', 'Merkez', 'Ulukışla'],
    Ordu: ['Akkuş', 'Altınordu', 'Aybastı', 'Çamaş', 'Çatalpınar', 'Çaybaşı', 'Fatsa', 'Gölköy', 'Gülyalı', 'Gürgentepe', 'İkizce', 'Kabadüz', 'Kabataş', 'Korgan', 'Kumru', 'Mesudiye', 'Perşembe', 'Ulubey', 'Ünye'],
    Osmaniye: ['Bahçe', 'Düziçi', 'Hasanbeyli', 'Kadirli', 'Merkez', 'Sumbas', 'Toprakkale'],
    Rize: ['Ardeşen', 'Çamlıhemşin', 'Çayeli', 'Derepazarı', 'Fındıklı', 'Güneysu', 'Hemşin', 'İkizdere', 'İyidere', 'Kalkandere', 'Merkez', 'Pazar'],
    Sakarya: ['Akyazı', 'Arifiye', 'Erenler', 'Ferizli', 'Geyve', 'Hendek', 'Karapürçek', 'Karasu', 'Kaynarca', 'Kocaali', 'Merkez', 'Pamukova', 'Sapanca', 'Serdivan', 'Söğütlü', 'Taraklı'],
    Samsun: ['Alaçam', 'Asarcık', 'Atakum', 'Ayvacık', 'Bafra', 'Canik', 'Çarşamba', 'Havza', 'İlkadım', 'Kavak', 'Ladik', 'Salıpazarı', 'Tekkeköy', 'Terme', 'Vezirköprü', 'Yakakent'],
    Siirt: ['Ayıpsa', 'Baykan', 'Eruh', 'Kurtalan', 'Merkez', 'Pervari', 'Şirvan'],
    Sinop: ['Ayancık', 'Boyabat', 'Dikmen', 'Durağan', 'Erfelek', 'Gerze', 'Merkez', 'Saraydüzü', 'Türkeli'],
    Sivas: ['Akıncılar', 'Altınyayla', 'Divriği', 'Doğanşar', 'Gemerek', 'Gölova', 'Hafik', 'İmranlı', 'Kangal', 'Koyulhisar', 'Merkez', 'Suşehri', 'Şarkışla', 'Ulaş', 'Yıldızeli', 'Zara'],
    Şanlıurfa: ['Akçakale', 'Birecik', 'Bozova', 'Ceylanpınar', 'Eyyübiye', 'Halfeti', 'Haliliye', 'Harran', 'Hilvan', 'Karaköprü', 'Siverek', 'Suruç', 'Viranşehir'],
    Şırnak: ['Beytüşşebap', 'Cizre', 'Güçlükonak', 'İdil', 'Merkez', 'Silopi', 'Uludere'],
    Tekirdağ: ['Çerkezköy', 'Çorlu', 'Ergene', 'Hayrabolu', 'Kapaklı', 'Malkara', 'Marmaraereğlisi', 'Muratlı', 'Saray', 'Şarköy'],
    Tokat: ['Almus', 'Artova', 'Başçiftlik', 'Erbaa', 'Merkez', 'Niksar', 'Pazar', 'Reşadiye', 'Sulusaray', 'Turhal', 'Yeşilyurt', 'Zile'],
    Trabzon: ['Akçaabat', 'Araklı', 'Arsin', 'Beşikdüzü', 'Çarşıbaşı', 'Çaykara', 'Dernekpazarı', 'Düzköy', 'Hayrat', 'Köprübaşı', 'Maçka', 'Merkez', 'Of', 'Sürmene', 'Tonya', 'Vakfıkebir', 'Yomra'],
    Tunceli: ['Çemişgezek', 'Hozat', 'Mazgirt', 'Merkez', 'Nazımiye', 'Ovacık', 'Pertek'],
    Uşak: ['Banaz', 'Eşme', 'Karahallı', 'Merkez', 'Sivaslı', 'Ulubey'],
    Van: ['Başkale', 'Çaldıran', 'Çatak', 'Edremit', 'Erciş', 'Gevaş', 'Gürpınar', 'İpekyolu', 'Muradiye', 'Özalp', 'Saray', 'Tuşba'],
    Yalova: ['Altınova', 'Armutlu', 'Çınarcık', 'Çiftlikköy', 'Merkez', 'Termal'],
    Yozgat: ['Akdağmadeni', 'Aydıncık', 'Boğazlıyan', 'Çandır', 'Çayıralan', 'Çekerek', 'Kadışehri', 'Merkez', 'Saraykent', 'Sarıkaya', 'Sorgun', 'Şefaatli', 'Yenifakılı', 'Yerköy'],
    Zonguldak: ['Alaplı', 'Çaycuma', 'Devrek', 'Ereğli', 'Gökçebey', 'Karadeniz Ereğli', 'Kilimli', 'Kozlu', 'Merkez']
  },
  BALANCE: {
    HEADING: 'Cüzdan',
    SUMMARY: 'Hesap Özeti',
    SUMMARY_MOBILE: 'Hesap<br> Özeti',
    USER_TRADE_HISTORY: 'İşlem Geçmişi',
    USER_TRADE_HISTORY_UPPERCASE: 'İŞLEM GEÇMİŞİ',
    USER_TRADE_HISTORY_MOBILE: 'İşlem<br> Geçmişi',
    USER_OPERATION_HISTORY: 'Yatırma-Çekme Geçmişi',
    USER_OPERATION_HISTORY_UPPERCASE: 'YATIRMA-ÇEKME GEÇMİŞİ',
    USER_OPERATION_HISTORY_MOBILE: 'Yatırma-Çekme<br> Geçmişi',
    TOTAL_BALANCE: 'Toplam Bakiye',
    TL_BALANCE: 'TL Bakiye',
    CRYPTO_BALANCE: 'Tether(USDT)',
    ETHEREUM_BALANCE: 'Ethereum(ETH)',
    ART_PRODUCTS: 'Sanat Eserleri',
    TL: 'Türk Lirası',
    ART_PRODUCT: 'Sanat Eseri',
    PORTFOLIO: 'Portföy',
    PORTFOLIO_UPPERCASE: 'PORTFÖY',
    SYMBOL: 'Sembol',
    AVAILABLE_AMOUNT: 'Kullanılabilir',
    IN_ORDER: 'Emirde',
    TOTAL_AMOUNT: 'Toplam',
    VALUE: 'Değer',
    NO_TOKEN: 'Kullanıcıya ait token bulunmamaktadır.',
    TRADE: 'Al-Sat',
    DEPOSIT: 'Yatır',
    WITHDRAW: 'Çek',
    PAYING: 'Yatırma',
    WITHDRAWAL: 'Çekme',
    USER_OPEN_ORDERS: 'Açık Emirler',
    USER_OPEN_ORDERS_UPPERCASE: 'AÇIK EMİRLER',
    ORDER_TYPE: 'İşlem Yönü',
    OPERATION_TYPE: 'Operasyon Yönü',
    OPERATION_TYPE_MOBILE: 'Operasyon',
    PENDING: 'Mail onayı bekliyor',
    ONGOING: 'Devam ediyor',
    QUEUED: 'Sıraya alındı',
    WAITING: 'İşleniyor',
    SIGNING: 'İmzalanıyor',
    CANCELED: 'İptal Edildi',
    EXECUTING: 'İşleniyor',
    COMPLETED: 'Tamamlandı',
    EXPIRED: 'Süresi Doldu',
    ERROR: 'Hata',
    AMOUNT: 'Miktar',
    KIND: 'Tür',
    PAIR: 'Çift',
    ALL: 'Tümü',
    FILTER: 'Göster',
    BUY: 'Alış',
    SELL: 'Satış',
    DATE: 'Tarih',
    STATUS: 'Durum',
    EXPLANATION: 'Bilgi',
    READ_ACCEPTED: 'Okudum, onaylıyorum',
    NEXT: 'İlerle',
    INSPECT: 'İncele',
  },
  SECURITY: {
    HEADING: 'GÜVENLİK',
    HEADING_MOBILE: 'Güvenlik',
    CHANGE_PASSWORD: {
      HEADING: 'Şifre Değiştirme',
      HEADING_MOBILE: 'Şifre<br>Değiştirme',
      FORM_HEADING: 'ŞİFRE DEĞİŞTİRME FORMU',
      FORM_HEADING_MOBILE: 'Şifre Değiştirme Formu',
      CURRENT_PASSWORD: 'Mevcut Şifre',
      NEW_PASSWORD: 'Yeni Şifre',
      NEW_PASSWORD_AGAIN: 'Yeni Şifre (Tekrar)',
      PASSWORD_RULES: '(En az 8 karakter, 1 büyük harf, 1 küçük harf, 1 sayı ve 1 özel karakter içermelidir.)',
      SAVE_CHANGES: 'Değişiklikleri Kaydet'
    },
    TWO_FACTOR_AUTHENTICATOR: {
      HEADING: 'Çift Aşamalı Doğrulama',
      HEADING_REMOVE: 'ÇİFT AŞAMALI DOĞRULAMA (GOOGLE 2FA) KALDIRMA',
      HEADING_MOBILE: 'Çift Aşamalı<br>Doğrulama',
      FORM_HEADING: 'ÇİFT AŞAMALI DOĞRULAMA (GOOGLE 2FA)',
      FORM_HEADING_MOBILE: 'Çift Aşamalı Doğrulama (Google 2FA)',
      DOWNLOAD_2FA_APP: '1. Google Authenticator uygulamasını indirin.',
      INSERT_PASSWORD: '2. Google Authenticator için oluşturulan kare kodu görebilmek için lütfen şifrenizi girin.',
      INSERT_AUTH_PASSWORD: '3. Google Authenticator tarafından üretilen doğrulama anahtarını girin.',
      QR_CODE: 'Kare Kod',
      SECRET_KEY: 'Kare Kod(Gizli Anahtar)',
      AUTHENTICATION_WARNING: 'Kare kodunuzu kaybetmeniz durumunda doğrulama anahtarınıza ulaşamazsınız.' +
        'Bu nedenle kare kodunuzu yedeklemeyi unutmayınız! Aksi taktirde, hesabınıza giriş yapamazsınız.',
      CURRENT_PASSWORD: 'Mevcut Şifre',
      APPLY: 'Başvur',
      AUTH_PASSWORD: 'Doğrulama Anahtarı',
      ACTIVE: 'Hesabınızda Çift Aşamalı Doğrulama (Google 2FA) bulunmaktadır. <strong>Kaldırmak istiyorsanız:</strong>',
      INSERT_PASSWORD_TO_REMOVE: '1. Hesabınızdan Çift Aşamalı Doğrulama (Google 2FA) kaldırmak için lütfen mevcut hesap şifrenizi girin.',
      INSERT_AUTH_PASSWORD_TO_REMOVE: '2. Kaldırma işlemini tamamlamak için Google Authenticator tarafından üretilen kodu girin.',
      COMPLETE: 'Tamamla',
      REMOVE: 'Kaldır',
    },
    IDENTITY_VERIFICATION: {
      HEADING: 'Kimlik Doğrulama',
      HEADING_MOBILE: 'Kimlik<br>Doğrulama',
      LIMIT_INFO: 'Limit<br>Bilgileriniz',  // obsolete
      LIMITS: {
        DEPOSIT: 'Yatırma',
        WITHDRAW: 'Çekme',
        LEVEL: 'Seviye ',
        DAY: 'Gün',
        MONTH: 'Ay',
        TL: {
          HEADING: 'Türk Lirası',
          LEVEL_0_DESCRIPTION: 'Seviye 1 Doğrulama Gerekli',
          LEVEL_1_DAILY_LIMIT: '50,000.00₺',
          LEVEL_1_MONTHLY_LIMIT: '1,000,000.00₺',
          LEVEL_2_DAILY_LIMIT: '1,000,000.00₺',
          LEVEL_2_MONTHLY_LIMIT: '10,000,000.00₺',
        },
        CRYPTO: {
          HEADING: 'Kripto Para',
          DESCRIPTION: 'Seviye 2 Doğrulama Gerekli',
          LEVEL_2_DAILY_LIMIT: '100,000.00₮',
          LEVEL_2_MONTHLY_LIMIT: '1,000,000.00₮',
        },
        ETHEREUM: {
          HEADING: 'Ethereum',
          DESCRIPTION: 'Seviye 2 Doğrulama Gerekli',
          LEVEL_2_DAILY_LIMIT: '30 ETH',
          LEVEL_2_MONTHLY_LIMIT: '300 ETH',
        }
      },
      KYC_LEVEL_ONE: {
        FORM_HEADING: 'SEVİYE 1 KİMLİK DOĞRULAMA FORMU',
        NAME: 'İsim',
        SURNAME: 'Soyisim',
        BIRTH_YEAR: 'Doğum Yılı',
        TC: 'TC Kimlik Numarası',
        TOWN: 'Şehir',
        ADDRESS: 'Adres',
        COMPLETE: 'Tamamla',
      },
      KYC_LEVEL_TWO: {
        FORM_HEADING: 'SEVİYE 2 KİMLİK DOĞRULAMA FORMU',
        UPLOAD_FILE: 'Yüklediğiniz fotoğrafta',
        ID_CARD_EXPLANATION: 'Kimlik kartınızın ön yüzünün tamamının net göründüğü bir fotoğraf ekleyin.',
        ID_CARD_RULE: 'Kimlik kartınızdaki bilgiler, Seviye 1 için beyan ettiğiniz bilgiler ile aynı olmalıdır.',
        FACE_EXPLANATION: 'Kimlik kartınızın ön yüzünü şekildeki gibi tuttuğunuz bir fotoğraf ekleyin.',
        FACE_RULE: 'Kimlik kartınız ve yüzünüz net bir şekilde görünür olmalıdır. ',
        SELECT_FILE: 'Dosya Seç',
        COMPLETE: 'Tamamla',
        WAITING_FOR_APPROVAL: '<strong>Seviye 2</strong> için kimlik doğrulama başvurunuz onay beklemektedir.',
        COMPLETED: 'Hesabınız en üst seviyededir.'
      }
    }
  },
  DEPOSIT: {
    NO_KYC_HEADING: 'Hesabınız Seviye 1 olmalıdır',
    NO_KYC_DESCRIPTION: 'Banka yatırma ve çekme işlemlerinde güvenlik sebebiyle "Kimlik Beyanı" yapmanız gerekmektedir.',
    LEVEL_2_NEEDED_HEADING: 'Hesabınız Seviye 2 olmalıdır',
    LEVEL_2_NEEDED_DESCRIPTION: 'Kripto yatırma ve çekme işlemlerinde güvenlik sebebiyle "Kimlik Doğrulama" yapmanız gerekmektedir.',
    LEVEL_UP: 'Şimdi Yükselt',
    HEADING: 'Yatırma',
    BANK: 'Banka',
    TETHER: 'Tether(USDT)',
    ETHEREUM: 'Ethereum(ETH)',
    LIMITS: '1. Yatırma Limitleri',
    RULES: '2. Yatırma Kuralları',
    RULES_TITLE: 'Yatırma Kuralları',
    TRANSACTION: 'Yatırma İşlemi',
    PREREQUIREMENTS: 'Yatırma Koşulları',
    TL_DEPOSIT_HISTORY: 'TL Yatırma Geçmişi',
    TL_DEPOSIT_REQUIRED: 'En az 1 kez TL yatırma işlemi gerçekleştirmelisiniz',
    TL_DEPOSIT_ACCOMPLISHED: 'TL yatırma işlemi tamamlandı',
    TL_DEPOSIT_HISTORY_INVALID: 'Yatırma geçmişiniz bulunmamaktadır',
    TL_DEPOSIT_HISTORY_VALID: 'Yatırma geçmişiniz geçerlidir',
    DEPOSIT_NOW: 'Şimdi Yatır',
    DEPOSIT_COMPLETED: 'Şimdi Yatır',
    DAILY_DEPOSIT_LIMIT: 'Günlük Kalan Yatırma Limitiniz',
    DAILY_DEPOSIT_LIMIT_MOBILE: 'Günlük Kalan<br>Yatırma Limitiniz',
    MONTHLY_DEPOSIT_LIMIT: 'Aylık Kalan Yatırma Limitiniz',
    MONTHLY_DEPOSIT_LIMIT_MOBILE: 'Aylık Kalan<br>Yatırma Limitiniz',
    CRYPTO_DEPOSIT_DESCRIPTION: 'Lütfen işlem yapmak istediğiniz kripto birimini seçin ve kurallara uyduğunuzdan emin olun.',
    DEPOSIT_RULES: ['- Para yatırma işlemi için kullanacağınız banka hesabı şahsınıza ait olmalıdır. Aksi durumda transferiniz onaylanmayacaktır.',
      '- Kredi kartı ve ATM\'lerden yapılan transferler onaylanmayacaktır.',
      '- Lütfen transfer yaparken alıcı ünvanına \'Artiox Kripto Varlık Alım Satım Platformu A.Ş.\' yazmayı unutmayınız.',
      '- Transfer yaparken şirketimize ait IBAN numarasını doğru girdiğinizden emin olunuz.',
      '- Lütfen transfer yaparken açıklama bölümüne size özel oluşturduğumuz kodu girmeyi unutmayınız ve bu kod dışında herhangi bir açıklama eklemeyiniz.',
      '- Tarafımıza ulaşan ancak eksik ya da hatalı bilgilerden dolayı onaylayamadığımız transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.',
      '- Havale ile gerçekleştirdiğiniz transferler 7/24 hesabınıza yansıtılırken, EFT yolu ile gerçekleşen transferler sadece mesai saatleri içinde hesabınıza yansıtılabilmektedir.',
      '- Papara üzerinden yapılan yatırmalar geçerli değildir. Bu kanaldan yapılan transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.'],  // obsolete
    FIAT_DEPOSIT_RULES: ['- Para yatırma işlemi için kullanacağınız banka hesabı şahsınıza ait olmalıdır. Aksi durumda transferiniz onaylanmayacaktır.',
      '- Kredi kartı ve ATM\'lerden yapılan transferler onaylanmayacaktır.',
      '- Lütfen transfer yaparken alıcı ünvanına \'Artiox Kripto Varlık Alım Satım Platformu A.Ş.\' yazmayı unutmayınız.',
      '- Transfer yaparken şirketimize ait IBAN numarasını doğru girdiğinizden emin olunuz.',
      '- Lütfen transfer yaparken açıklama bölümüne size özel oluşturduğumuz kodu girmeyi unutmayınız ve bu kod dışında herhangi bir açıklama eklemeyiniz.',
      '- Tarafımıza ulaşan ancak eksik ya da hatalı bilgilerden dolayı onaylayamadığımız transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.',
      '- Havale ile gerçekleştirdiğiniz transferler 7/24 hesabınıza yansıtılırken, EFT yolu ile gerçekleşen transferler sadece mesai saatleri içinde hesabınıza yansıtılabilmektedir.',
      '- Papara üzerinden yapılan yatırmalar geçerli değildir. Bu kanaldan yapılan transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.'],
    CRYPTO_DEPOSIT_RULES: [
      '- Yatırmak istediğiniz kripto para cinsini seçiniz.',
      '- Gönderim yapmak istediğiniz tarafa, Artiox hesabınızdaki cüzdan adresinizi adresi kopyala-yapıştır yöntemiyle giriniz.' +
      ' Kopyaladığınız adresin yapıştırdığınız alanda doğruluğunu mutlaka kontrol ediniz.',
      '- Yatırma adresine sadece Ethereum blok zinciri üzerinden ERC20 tabanlı Tether (USDT) yatırabilirsiniz.',
      '- Bu adrese USDT\'den başka coin ya da token göndermek, gönderdiklerinizin kaybolmasıyla sonuçlanır.',
      '- Yatırılabilen en az tutar 50 USDT\'dir. 50 USDT altındaki yatırma işlemleri geçerli değildir ve iade edilmeyecektir.',
      '- Bu cüzdan adresine yapılacak olan geçerli tüm transferler Artiox bakiyenize otomatik olarak yansıtılır.'
    ],
    ETHEREUM_DEPOSIT_RULES: [
      '- Yatırmak istediğiniz kripto para cinsini seçiniz.',
      '- Gönderim yapmak istediğiniz tarafa, Artiox hesabınızdaki cüzdan adresinizi adresi kopyala-yapıştır yöntemiyle giriniz. Kopyaladığınız adresin yapıştırdığınız alanda doğruluğunu mutlaka kontrol ediniz.',
      '- Yatırma adresine sadece Ethereum blok zinciri üzerinden ETH yatırabilirsiniz.',
      '- Bu adrese ETH’den başka coin ya da token göndermek, gönderdiklerinizin kaybolmasıyla sonuçlanır.',
      '- Yatırılabilen en az tutar 0.005 ETH’dir. 0.005 ETH altındaki yatırma işlemleri geçerli değildir ve iade edilmeyecektir.',
      '- Bu cüzdan adresine yapılacak olan geçerli tüm transferler Artiox bakiyenize otomatik olarak yansıtılır.'],
    CRYPTO_DEPOSIT_PREREQUIREMENTS: [
      'Tether(USDT) yatırma işlemi yapabilmek için aşağıdaki kurallara uyduğunuzdan emin olun.',
      '- Hesabınız "Seviye 2" olmalıdır.',
      '- En az 1 kez Türk Lirası yatırma işlemi yapmanız gerekmektedir.'
    ],
    ETHEREUM_DEPOSIT_PREREQUIREMENTS: [
      'Ethereum(ETH( çekme işlemi yapabilmek için aşağıdaki kurallara uyduğunuzdan emin olun.',
      '- Hesabınız "Seviye 2" olmalıdır.',
      '- En az 1 kez Türk Lirası yatırma işlemi yapmanız gerekmektedir.'
    ],
    BANK_NAME: 'Banka İsmi',
    ACCOUNT_ADDRESS: 'Cüzdan Adresi',
    WALLET_OF: 'Cüzdanı',
    COPY_ADDRESS: 'Adresi kopyala',
    ACCOUNT_NAME: 'Hesap İsmi',
    IBAN: 'IBAN',
    DEPOSIT_REF_CODE: 'Açıklama',
    OBEY_RULES: 'Lütfen yatırma kurallarına uyduğunuzdan emin olun.',
    COPIED: 'Başarıyla Kopyalandı',
    COPY: 'Kopyala',
    DEPOSIT_WARNING: 'Lütfen yatırma kurallarına uyduğunuzdan emin olun.',
    DEPOSIT_HISTORY: 'YATIRMA GEÇMİŞİ',
    DEPOSIT_HISTORY_MOBILE: 'Yatırma Geçmişi',
    TL_SUSPENSION: {
      SUSPENDED: false,
      TITLE: '',
      DESCRIPTION: '',
    },
    TETHER_SUSPENSION: {
      SUSPENDED: false,
      TITLE: 'Birleştirme sebebi ile Askıya Alındı',
      DESCRIPTION: 'Ethereum ağındaki Birleştirme sebebi ile, Tether(USDT) yatırma ve çekme işlemleri <strong>20 Eylül 2022</strong> tarihine kadar askıya alınmıştır.<br>Anlayışınız için teşekkür ederiz.',
    },
  },
  WITHDRAWAL: {
    HEADING: 'Çekme',
    BANK: 'Banka',
    TETHER: 'Tether(USDT)',
    ETHEREUM: 'Ethereum(ETH)',
    LIMITS: '1. Çekme Limitleri',
    BANK_ACCOUNT: '2. Hesap Bilgileri',
    ACCOUNT_ADDRESS: 'Cüzdan Adresi',
    SAVED_BANK_ACCOUNTS_PLACEHOLDER: 'Kayıtlı Hesaplarınız',
    SAVED_CRYPTO_ACCOUNTS_PLACEHOLDER: 'Kayıtlı Cüzdanlarınız',
    NO_SAVED_BANK_ACCOUNT: 'Kayıtlı hesabınız bulunmamaktadır.',
    NO_SAVED_CRYPTO_ACCOUNT: 'Kayıtlı cüzdanınız bulunmamaktadır.',
    ADD_NEW_BANK_ACCOUNT: 'Yeni Hesap Ekle',
    ADD_NEW_CRYPTO_ACCOUNT: 'Yeni Cüzdan Ekle',
    PLEASE_SELECT_BANK: 'Çekme işlemi için lütfen paranın yatırılacağı hesabı seçiniz.',
    PLEASE_SELECT_WALLET: 'Çekme işlemi için lütfen paranın yatırılacağı cüzdanı seçiniz.',
    RULES_TITLE: 'Çekme Kuralları',
    TRANSACTION: '3. Çekmek İstediğiniz Tutar:',
    TRANSACTION_MOBILE: 'Çekmek İstediğiniz Tutar:',
    DAILY_WITHDRAWAL_LIMIT: 'Günlük Kalan Çekme Limitiniz',
    DAILY_WITHDRAWAL_LIMIT_MOBILE: 'Günlük Kalan<br>Çekme Limitiniz',
    MONTHLY_WITHDRAWAL_LIMIT: 'Aylık Kalan Çekme Limitiniz',
    MONTHLY_WITHDRAWAL_LIMIT_MOBILE: 'Aylık Kalan<br>Çekme Limitiniz',
    WITHDRAWAL_RULES: ['- Sadece şahsınıza ait hesaplara para çekim işlemi yapabilirsiniz.',  // obsolete
      '- Çekmek istediğiniz tutar kullanılabilir bakiyenizden düşecektir.',
      '- En az para çekme tutarı 100 Türk Lirasıdır.',
      '- Her çekme işleminde 4 lira komisyon kesilmektedir.',
      'Uyarı: Çekme talebinizin gerçekleşmesi için e-mail onayı vermeniz gerekmektedir.' +
      ' Onaylanmayan istekler 24 saat sonra zaman aşımına uğrayacak, ilgili miktar kullanılabilir bakiyenize yansıtılacaktır.' +
      ' (Mailiniz bazı durumlarda Spam Kutusu\'na düşebilir.)'],
    FIAT_WITHDRAWAL_RULES: ['- Sadece şahsınıza ait hesaplara para çekim işlemi yapabilirsiniz.',
      '- Çekmek istediğiniz tutar kullanılabilir bakiyenizden düşecektir.',
      '- En az para çekme tutarı 100 Türk Lirasıdır.',
      '- Her çekme işleminde 4 lira komisyon kesilmektedir.',
      'Uyarı: Çekme talebinizin gerçekleşmesi için e-mail onayı vermeniz gerekmektedir.' +
      ' Onaylanmayan istekler 24 saat sonra zaman aşımına uğrayacak, ilgili miktar kullanılabilir bakiyenize yansıtılacaktır.' +
      ' (Mailiniz bazı durumlarda Spam Kutusu\'na düşebilir.)'],
    CRYPTO_WITHDRAWAL_RULES: [
      '- Kripto para çekebilmek için öncelikle çekim yapmak istediğiniz cüzdan adresini tanımlamalısınız.',
      '- Tanımladığınız cüzdan adresinin Ethereum ağına ait bir cüzdan adresi olduğuna emin olunuz. ',
      '- Daha önce tanımladığınız adresleri kullanabilir veya yeni çekme adresi ekleyebilirsiniz.',
      '- Çekmek istediğiniz tutar kullanılabilir USDT bakiyenizden düşecektir.',
      '- En az çekme tutarı 50 USDT’dir.',
      '- Her çekme işleminde Ethereum ağının anlık durumuna göre hesaplanan komisyon tutarı çekmek istediğiniz miktardan düşülecektir.',
      '- Talep edilen komisyon tutarı transfer işlemi için madencilere ödenen miktar olup, Artiox’un buna müdahale etmesi mümkün değildir.',
      '- Çekme talebinizin işleme alınabilmesi için size gönderdiğimiz e-posta aracılığıyla bu talebinize onay vermeniz gerekmektedir. ',
      '- Onaylanmayan talepler 24 saat sonra zaman aşımına uğrayacak, ilgili miktar kullanılabilir USDT bakiyenize yansıtılacaktır. (Onay e-postası bazı durumlarda Spam Kutusu\'na düşebilir.)',
      '- Çekme talebinize onay verdikten kısa bir süre sonra transferiniz başlatılır ve transferin durdurulması veya iptali teknik olarak mümkün değildir.',
      '- Yanlış veya hatalı cüzdan adreslerine yapılan transferler kullanıcıların sorumluluğunda olup, transferi gerçekleşen fonların iadesi mümkün değildir.',
    ],
    ETHEREUM_WITHDRAWAL_RULES: [
      '- Kripto para çekebilmek için öncelikle çekim yapmak istediğiniz cüzdan adresini tanımlamalısınız.',
      '- Tanımladığınız cüzdan adresinin Ethereum ağına ait bir cüzdan adresi olduğuna emin olunuz.',
      '- Daha önce tanımladığınız adresleri kullanabilir veya yeni çekme adresi ekleyebilirsiniz.',
      '- Çekmek istediğiniz tutar kullanılabilir ETH bakiyenizden düşecektir.',
      '- En az çekme tutarı 0.005 ETH’dir.',
      '- Her çekme işleminde Ethereum ağının anlık durumuna göre hesaplanan komisyon tutarı çekmek istediğiniz miktardan düşülecektir.',
      '- Talep edilen komisyon tutarı transfer işlemi için madencilere ödenen miktar olup, Artiox’un buna müdahale etmesi mümkün değildir.',
      '- Çekme talebinizin işleme alınabilmesi için size gönderdiğimiz e-posta aracılığıyla bu talebinize onay vermeniz gerekmektedir.',
      '- Onaylanmayan talepler 24 saat sonra zaman aşımına uğrayacak, ilgili miktar kullanılabilir ETH bakiyenize yansıtılacaktır. (Onay e-postası bazı durumlarda Spam Kutusu’na düşebilir.)',
      '- Çekme talebinize onay verdikten kısa bir süre sonra transferiniz başlatılır ve transferin durdurulması veya iptali teknik olarak mümkün değildir.',
      '- Yanlış veya hatalı cüzdan adreslerine yapılan transferler kullanıcıların sorumluluğunda olup, transferi gerçekleşen fonların iadesi mümkün değildir.',
    ],
    BANK_NAME: 'Banka İsmi',
    ACCOUNT_NAME: 'Hesap İsmi',
    IBAN: 'IBAN',
    OBEY_RULES: 'Lütfen çekme kurallarına uyduğunuzdan emin olun.',
    COPIED: 'Başarıyla Kopyalandı',
    COPY: 'Kopyala',
    WITHDRAWAL_WARNING: 'Lütfen çekme kurallarına uyduğunuzdan emin olun.',
    APPROVE_MAIL_WARNING: 'Çekme talebinizi gönderdiğimiz mail ile onaylayın.',
    WITHDRAWAL_HISTORY: 'ÇEKME GEÇMİŞİ',
    WITHDRAWAL_HISTORY_MOBILE: 'Çekme Geçmişi',
    REMOVE_BANK_ACCOUNT: 'Hesabı Kaldır',
    REMOVE_CRYPTO_ACCOUNT: 'Cüzdanı Kaldır',
    REMOVE_BANK_ACCOUNT_MOBILE: 'Kaldır',
    REMOVE_CRYPTO_ACCOUNT_MOBILE: 'Kaldır',
    MINIMUM_WITHDRAWAL_AMOUNT: 'Minimum çekim tutarı 100₺’dir',  // obsolete
    MINIMUM_FIAT_WITHDRAWAL_AMOUNT: 'Minimum çekim tutarı 100₺’dir',
    MINIMUM_CRYPTO_WITHDRAWAL_AMOUNT: 'Minimum çekim tutarı 50 USDT’dir',
    MINIMUM_ETHEREUM_WITHDRAWAL_AMOUNT: 'Minimum çekim tutarı 0.005 ETH’dir',
    ADD_BANK_ACCOUNT: {
      HEADING: 'YENİ BANKA HESABI KAYDETME',
      IBAN: 'IBAN',
      IBAN_ERROR_LIST: {
        IBAN_NOT_ALPHA_NUMERIC: 'IBAN Kodu Alfanümerik Olmalıdır.',
        IBAN_COUNTRY_CODE: 'Ulke Kodu Büyük Harfle Yazılmalı ve 2 Adet Harften Oluşmalıdır.',
        IBAN_NOT_TURKEY: 'Ülke Kodu Türkiye Değil.',
        IBAN_CONTROL_CODE: 'Kontrol Sayısı Rakamlardan Oluşmalıdır.',
        IBAN_LONGER_THAN: 'IBAN Kod Uzunluğu Türkiye Standartından(26 karakterden) uzun',
        IBAN_SHORTER_THAN: 'IBAN Kod Uzunluğu Türkiye Standartından(26 karakterden) kısa',
        IBAN_BANK_CODE: 'Banka Kodu Sadece Sayılardan Oluşmalıdır.',
      },
      NAME_SURNAME: 'Ad ve Soyad',
      ACCOUNT_NAME: 'Hesab Adı',
      SAVE_ACCOUNT: 'Hesabı Kaydet',
      FILL_THE_FORM: 'Lütfen formu doldurunuz!'
    },
    ADD_CRYPTO_ACCOUNT: {
      HEADING: 'YENİ KRİPTO CÜZDANI KAYDETME',
      BLOCK_CHAIN: 'Blok Zinciri',
      ACCOUNT_ADDRESS: 'Cüzdan Adresi',
      CRYPTO_ACCOUNT_NAME: 'Cüzdan Adı',
      SAVE_CRYPTO_ACCOUNT: 'Cüzdanı Kaydet',
      SELECT_BLOCK_CHAIN: 'Blok Zinciri Seçiniz',
    },
    AVAILABLE_TRY: 'Kullanılabilir: ',
    AVAILABLE: 'Kullanılabilir',
    WITHDRAWAL_REQUEST: 'Çekme Talebinde Bulun',
    WITHDRAWAL_REQUEST_SUM_HEADING: 'Talep Özetiniz',
    PLEASE_SELECT_BANK_ACCOUNT: 'Banka hesabınızı seçmelisiniz',
    PLEASE_SELECT_CRYPTO_ACCOUNT: 'Kripto cüzdanınızı seçmelisiniz',
    WITHDRAWAL_AMOUNT: 'Çekmek İstediğiniz Miktar',
    WITHDRAWAL_FEE: 'İşlem Ücreti',
    WITHDRAWAL_SUM: 'Hesabınıza Yatacak Tutar',
    WITHDRAWAL_ACCOUNT: 'Hesap Bilgileri:',
    WITHDRAWAL_WALLET: 'Cüzdan Bilgileri:',
    STATUS_ICONS: {
      PENDING: './assets/images/pending.svg#pending',
      QUEUED: './assets/images/queued.svg#queued',
      WAITING: './assets/images/executing.svg#executing',
      EXECUTING: './assets/images/executing.svg#executing',
      COMPLETED: './assets/images/blue_tick_icon_no_size.svg#blue_tick_icon_no_size',
      EXPIRED: './assets/images/withdrawal_expired.svg#withdrawal_expired',
      ERROR: './assets/images/error_icon.svg#error_icon',
      CANCELED: './assets/images/withdrawal_expired.svg#withdrawal_expired',
      SIGNING: './assets/images/blog.svg#blog',
    },
    TL_SUSPENSION: {
      SUSPENDED: false,
      TITLE: '',
      DESCRIPTION: '',
    },
    TETHER_SUSPENSION: {
      SUSPENDED: false,
      TITLE: 'Birleştirme sebebi ile Askıya Alındı',
      DESCRIPTION: 'Ethereum ağındaki Birleştirme sebebi ile, Tether yatırma ve çekme işlemleri <strong>20 Eylül 2022</strong> tarihine kadar askıya alınmıştır.<br>Anlayışınız için teşekkür ederiz.',
    },
  },
  WITHDRAWAL_CONFIRMED: {
    HEADING: 'ÇEKME TALEBİNİZ ONAYLANDI',
    DESCRIPTION: 'Çekme işleminiz sıraya alındı. Talebiniz en kısa sürede gerçekleşecektir.',
    IMG_SRC: './assets/images/withdrawal_approved.webp',
    IMG_ALT: 'Çekme işleminiz sıraya alındı. Talebiniz en kısa sürede gerçekleşecektir.',
    BACK_TO_HOME: 'Anasayfaya Dön'
  },
  WITHDRAWAL_ALREADY_CONFIRMED: {
    HEADING: 'BU TALEBİNİZ DAHA ÖNCE ONAYLANDI',
    DESCRIPTION: 'Talebiniz hala gerçekleşmediyse support@artiox.com adresi üzerinden bizimle iletişime geçebilirsiniz.',
    IMG_SRC: './assets/images/withdrawal_approved.webp',
    IMG_ALT: 'Talebiniz hala gerçekleşmediyse support@artiox.com adresi üzerinden bizimle iletişime geçebilirsiniz.',
    BACK_TO_HOME: 'Anasayfaya Dön'
  },
  WITHDRAWAL_EXPIRED: {
    HEADING: 'PARA ÇEKME TALEBİNİZ ZAMAN AŞIMINA UĞRADI',
    DESCRIPTION: 'Çekme işleminiz, talep oluşturduktan sonra 24 saat içinde e-mail onayı vermediğiniz için zaman aşımına uğradı.' +
      ' Talebinizin iptali ile ilgili tutar, kullanılabilir bakiyenize yeniden yansıtılmıştır.' +
      ' Çekme işlemini gerçekleştirmek için yeniden talepte bulunabilirsiniz.',
    IMG_SRC: './assets/images/withdrawal_expired.webp',
    IMG_ALT: 'Çekme işleminiz, talep oluşturduktan sonra 24 saat içinde e-mail onayı vermediğiniz için zaman aşımına uğradı.',
    BACK_TO_HOME: 'Anasayfaya Dön'
  },
  CURRENCY_CONVERTER: {
    BUY: 'AL',
    SELL: 'SAT',
    LAST_PRICE: 'Son Fiyat :',
    AMOUNT_TO_BUY: {STRING: 'Alınacak $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
    AMOUNT_TO_SELL: {STRING: 'Satılacak $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
    TL_AMOUNT: 'Yaklaşık TRY Karşılığı',
    SUMMARY_TITLE: 'Talep Özetiniz',
    FEE: 'İşlem Ücreti',
    NET_AMOUNT: 'Aktarılacak Miktar',
    TOTAL_BUY_PRICE: 'Toplam Ödeyeceğiniz Ücret',
    TOTAL_SELL_PRICE: 'Toplam Alacağınız Ücret',
  },
  BANK_ACCOUNT_IMG_SRCS: {
    'TC Merkez Bankası': './assets/images/banks/TC Merkez Bankası.webp',
    'İller Bankası': './assets/images/banks/İller Bankası.webp',
    'Ziraat Bankası': './assets/images/banks/Ziraat Bankası.webp',
    'Halkbank': './assets/images/banks/Halkbank.webp',
    'Sınai Kalkınma Bankası': './assets/images/banks/Sınai Kalkınma Bankası.webp',
    'Vakıflar Bankası': './assets/images/banks/Vakıflar Bankası.webp',
    'Eximbank': './assets/images/banks/Eximbank.webp',
    'Kalkınma Bankası': './assets/images/banks/Kalkınma Bankası.webp',
    'Birleşik Fon Bankası': './assets/images/banks/Birleşik Fon Bankası.webp',
    'Türk Ekonomi Bankası': './assets/images/banks/Türk Ekonomi Bankası.webp',
    'Akbank': './assets/images/banks/Akbank.webp',
    'Şekerbank': './assets/images/banks/Şekerbank.webp',
    'Garanti Bankası': './assets/images/banks/Garanti Bankası.webp',
    'İş Bankası': './assets/images/banks/İş Bankası.webp',
    'Yapı ve Kredi Bankası': './assets/images/banks/Yapı ve Kredi Bankası.webp',
    'Arap Türk Bankası': './assets/images/banks/Arap Türk Bankası.webp',
    'Citibank': './assets/images/banks/Citibank.webp',
    'Bank Mellat': './assets/images/banks/Bank Mellat.webp',
    'Turkish Bank': './assets/images/banks/Turkish Bank.webp',
    'JPMorgan Chase Bank': './assets/images/banks/JPMorgan Chase Bank.webp',
    'ING Bank': './assets/images/banks/ING Bank.webp',
    'Adabank': './assets/images/banks/Adabank.webp',
    'Fibabanka': './assets/images/banks/Fibabanka.webp',
    'Turkland Bank': './assets/images/banks/Turkland Bank.webp',
    'ICBC Turkey Bank': './assets/images/banks/ICBC Turkey Bank.webp',
    'Finansbank': './assets/images/banks/Finansbank.webp',
    'Deutsche Bank': './assets/images/banks/Deutsche Bank.webp',
    'Pasha Yatırım Bankası': './assets/images/banks/Pasha Yatırım Bankası.webp',
    'Standard Chartered Yatırım Bankası': './assets/images/banks/Standard Chartered Yatırım Bankası.webp',
    'Societe Generale': './assets/images/banks/Societe Generale.webp',
    'HSBC': './assets/images/banks/HSBC.webp',
    'Alternatif Bank': './assets/images/banks/Alternatif Bank.webp',
    'Burgan Bank': './assets/images/banks/Burgan Bank.webp',
    'Merrill Lynch Yatırım Bank': './assets/images/banks/Merrill Lynch Yatırım Bank.webp',
    'Takasbank': './assets/images/banks/Takasbank.webp',
    'Denizbank': './assets/images/banks/Denizbank.webp',
    'Anadolu Bank': './assets/images/banks/Anadolu Bank.webp',
    'Rabobank': './assets/images/banks/Rabobank.webp',
    'Dilerbank': './assets/images/banks/Dilerbank.webp',
    'GSD Bank': './assets/images/banks/GSD Bank.webp',
    'Nurol Yatırım Bankası': './assets/images/banks/Nurol Yatırım Bankası.webp',
    'Bankpozitif Kredi ve Kalkınma Bankası': './assets/images/banks/Bankpozitif Kredi ve Kalkınma Bankası.webp',
    'Aktif Yatırım Bankası': './assets/images/banks/Aktif Yatırım Bankası.webp',
    'Odea Bank': './assets/images/banks/Odea Bank.webp',
    'Bank of Tokyo-Mitsubishi UFJ Turkey': './assets/images/banks/Bank of Tokyo-Mitsubishi UFJ Turkey.webp',
    'Intesa Sanpaolo S.p.A': './assets/images/banks/Intesa Sanpaolo S.p.A.webp',
    'Albaraka Türk Katılım Bankası': './assets/images/banks/Albaraka Türk Katılım Bankası.webp',
    'Kuveyt Türk Katılım Bankası': './assets/images/banks/Kuveyt Türk Katılım Bankası.webp',
    'Türkiye Finans Katılım Bankası': './assets/images/banks/Türkiye Finans Katılım Bankası.webp',
    'Ziraat Katılım Bankası': './assets/images/banks/Ziraat Katılım Bankası.webp',
    'Vakıf Katılım Bankası': './assets/images/banks/Vakıf Katılım Bankası.webp',
    'Merkezi Kayıt Kuruluşu': './assets/images/banks/Merkezi Kayıt Kuruluşu.webp',
  },
  CRYPTO_TOKEN_IMG_SRCS: {
    'Tether': './assets/images/tokens/Tether.webp',
    'USD Coin': './assets/images/tokens/USD Coin.webp',
  },
  CURRENCY_NAMES: {
    0: 'Türk Lirası',
    1: 'Tether'
  },
  CRYPTO_ACCOUNT_IMG_SRCS: {
    'Ethereum Main Net': './assets/images/networks/Ethereum.png',
    // 'Ethereum Goerli-Prater Test Net': './assets/images/networks/Ethereum.png',
    'Ethereum Sepolia Test Net': './assets/images/networks/Ethereum.png',
  },
  SIGN_UP_OR_LOGIN: {
    HEADING_BALANCE: 'Varlıklarınızı görebilmek için:',
    HEADING_USER_OPERATIONS: 'Açık Emrilerinizi ve İşlemlerinizi görebilmek için:',
    SIGN_UP: 'Üye Ol',
    OR: ' veya ',
    LOGIN: 'Giriş Yap'
  },
  FAQ: {
    HEADING: 'SIKÇA SORULAN SORULAR (S.S.S.)',
    HEADING_2: 'DUYURULAR',
    TOPICS: [
      {
        HEADING: 'Artiox Nedir?',
        INFO: 'Artiox, değerli sanat eserlerine parçalı yatırım yapabileceğiniz ve' +
          ' pörtföyünüzü kolayca yönetebileceğiniz yeni nesil sanat yatırım platformudur.',
      },
      {
        HEADING: 'Artiox Nasıl Çalışır?',
        INFO: 'Uzman sanat eksperlerinin kararları doğrultusunda seçilen değerli eserler,' +
          ' eksperlerin belirlediği fiyatlar üzerinden sigortalanır ve blockzincir üzerinde tokenize(ERC20) edilir.' +
          ' Akıllı Kontrat(Smart Contract) teknolojisi ile arzı sabitlenen eserler, Ön Satış ve Satış sürecinin ardından' +
          ' platformda listelenerek 7/24 ticarete hazır hale gelir.'
      },
      {
        HEADING: 'Komisyonlar ve Ücretler Nelerdir?',
        INFO: 'Artiox\'a üye olmak tamamen ücretsizdir. Alış ve satış komisyonları işlem başına %0,5 olarak belirlenmiştir.',
      },
    ]
  },
  BASIC: {
    ART_PRODUCTS: 'ESERLER',
    PAIR_HEADER: {
      LAST_PRICE: 'Son Fiyat',
      ART_PRODUCT_MARKET_VALUE: 'Eserin Piyasa Değeri',
      VOLUME_24_HOUR_DESKTOP: '24 Saatlik Hacim(₺): ',
      CHANGE_24_HOUR_DESKTOP: '24 Saatlik Değişim: ',
      VOLUME_24_HOUR_MOBILE: '24S Hacim: ',
      CHANGE_24_HOUR_MOBILE: '24S Değişim: '
    },
    TRADE: {
      AMOUNT_TO_BUY_MOBILE: {STRING: 'Alınacak<br> $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
      AMOUNT_TO_SELL_MOBILE: {STRING: 'Satılacak<br> $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
      AMOUNT_TO_BUY_DESKTOP: {STRING: 'Alınacak $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
      AMOUNT_TO_SELL_DESKTOP: {STRING: 'Satılacak $SYMBOL Miktarı', TOKENS: ['$SYMBOL']},
      AVAILABLE_AMOUNT: {STRING: 'Kul. $SYMBOL: ', TOKENS: ['$SYMBOL']},
      AVAILABLE_AMOUNT_ADVANCE: {STRING: 'Kull. $AMOUNT $SYMBOL ', TOKENS: ['$AMOUNT', '$SYMBOL']},
      SWAP: 'Değiştir',
      AVERAGE_COST_MOBILE: {STRING: 'Yaklaşık<br> $SYMBOL Tutarı', TOKENS: ['$SYMBOL']},
      AVERAGE_COST_DESKTOP: {STRING: 'Yaklaşık $SYMBOL Tutarı', TOKENS: ['$SYMBOL']},
      BUY: 'AL',
      SELL: 'SAT',
    }
  },
  PRE_SALE: {
    PRE_SALE_TOKEN_LEFT: 'Ön Satış Kalan Miktar',
    PUBLIC_SALE_TOKEN_LEFT: 'Genel Satış Kalan Miktar',
    LOCKED: 'Kilitli',
    PRE_SALE: 'Ön Satış',
    PUBLIC_SALE: 'Genel Satış',
    PRE_SALE_NOT_STARTED: 'Satış Henüz Başlamadı',
    BUY: 'AL',
    BUY_SELL: 'AL-SAT',
    OVER: 'BİTTİ',
    TOKENMETRICS: {
      HEADING: 'TOKENMETRİK BİLGİLERİ',
      NAME: 'Ad',
      SYMBOL: 'Sembol',
      TOTAL_SUPPLY: 'Toplam Arz',
      CIRCULATING_SUPPLY: 'Dolaşımdaki Arz',
      PRE_SALE_CIRCULATING_SUPPLY: 'Ön Satış Miktarı',
      PRE_SALE_PRICE: 'Ön Satış Fiyatı',
      PUBLIC_SALE_CIRCULATING_SUPPLY: 'Genel Satış Miktarı',
      PUBLIC_SALE_PRICE: 'Genel Satış Fiyatı',
      LISTING_DATE: 'Listelenme Tarihi',
      CONTRACT_ADDRESS: 'Kontrat Adresi',
    },
    PUBLIC_SALE_ARTWORKS: 'GENEL SATIŞTAKİ ESERLER:',
  },
  ADVANCED: {
    PAIR: 'Çift',
    PRICE: 'Fiyat',
    CHANGE: '24S Değişim',
    LIMIT: 'Limit',
    MARKET: 'Piyasa',
    MARKET_ALTER: 'Market',
    LOW_24_HOUR: '24S En Düşük',
    HIGH_24_HOUR: '24S En Yüksek',
    BALANCE: 'Varlıklar',
    TRADE: {
      HEADER_BUY: {STRING: '$SYMBOL AL', TOKENS: ['$SYMBOL']},
      HEADER_SELL: {STRING: '$SYMBOL SAT', TOKENS: ['$SYMBOL']},
      PRICE: {STRING: 'Fiyat($SYMBOL):', TOKENS: ['$SYMBOL']},
      AMOUNT: {STRING: 'Miktar($SYMBOL):', TOKENS: ['$SYMBOL']},
      APPROX_COST: {STRING: 'Yaklaşık($SYMBOL):', TOKENS: ['$SYMBOL']},
      TOTAL: {STRING: 'Toplam($SYMBOL):', TOKENS: ['$SYMBOL']},
    },
    USER_BALANCE: {
      AVAILABLE: 'Kullanılabilir:',
      IN_ORDER: 'Emirde:',
      TOTAL: 'Toplam:',
      TOTAL_BALANCE: 'Toplam Bakiye:'
    },
    TRADE_CHART: {
      ONE_MIN: '1DK',
      FIVE_MINS: '5DK',
      FIFTEEN_MINS: '15DK',
      THIRTY_MINS: '30DK',
      ONE_HOUR: '1S',
      FOUR_HOURS: '4S',
      TWELVE_HOURS: '12S',
      DAY: 'G',
      WEEK: 'H',
      MONTHLY: 'A',
      BID: 'Alış',
      ASK: 'Satış',
      PRICE: 'Fiyat',
    },
    ORDER_BOOK: {
      HEADER: 'Emir Defteri',
      PRICE: 'Fiyat',
      AMOUNT: 'Miktar',
      TOTAL: 'Toplam',
      LAST_PRICE: 'Son Fiyat: ',
    },
    TRADE_HISTORY: {
      HEADER: 'Son İşlemler',
      PRICE: 'Fiyat',
      AMOUNT: 'Miktar',
      TIME: 'Zaman'
    },
    USER_OPEN_ORDERS: {
      HEADING: 'Açık Emirler',
      PAIR: 'Çift',
      PRICE: 'Fiyat',
      TYPE: 'İşlem Tipi',
      AMOUNT: 'Miktar',
      CANCEL: 'İptal Et',
      MORE: 'Detay',
      BUY: 'Alış',
      SELL: 'Satış',
      TIME: 'Tarih',
      FILLED_AMOUNT: 'Gerçekleşen Miktar',
      FILLED_VOLUME: 'Gerçekleşen Hacim',
      FILLED_PERCENTAGE: 'Gerçekleşen(%)',
      TOTAL_AMOUNT: 'Toplam Miktar',
      TOTAL_VOLUME: 'Toplam Hacim',
    },
    USER_TRADE_HISTORY: {
      HEADING: 'İşlem Geçmişi',
      PAIR: 'Çift',
      PRICE: 'Fiyat',
      TYPE: 'İşlem Tipi',
      TYPE_MOBILE: 'İşlem Tipi',
      AMOUNT: 'Miktar',
      MORE: 'Detay',
      BUY: 'Alış',
      SELL: 'Satış',
      TIME: 'Tarih',
      TOTAL: 'Toplam',
    }
  },
  AUCTION: auction_config_tr,
  GUIDEBOOK: {
    // HEADING: 'Rehber',
    HEADING: 'ARTIOX',
    DESCRIPTION: 'Artiox ile ilgili detaylı tüm bilgilere rehber ile ulaşabilirsiniz.',
    HEADER: {
      ARTIOX: 'Artiox Rehberi',
      LEGAL: 'Yasal',
      CAREER: 'Kariyer',
    },
    GUIDEBOOK: [
      {
        HEADING: 'Hakkımızda',
        LINKS: [
          {
            TEXT: 'Kısaca Biz',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414402456465-K%C4%B1saca-Biz',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'Hikayemiz',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414417569937-Hikayemiz',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'İletişim',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4423184358161-%C4%B0leti%C5%9Fim',
              is_inner: false,
              target: '_blank'
            },
          },
        ]
      },
      {
        HEADING: 'Artiox Nedir?',
        LINKS: [
          {
            TEXT: 'Artiox Nedir?',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414430588433-Artiox-Nedir-',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'Neden Artiox?',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414417314193-Neden-Artiox-',
              is_inner: false,
              target: '_blank'
            },
          },
        ]
      },
      {
        HEADING: 'Nasıl Çalışır?',
        LINKS: [
          {
            TEXT: 'Nasıl Çalışır?',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414402601361-Nas%C4%B1l-%C3%87al%C4%B1%C5%9F%C4%B1r-',
              is_inner: false,
              target: '_blank'
            },
          },
        ]
      },
    ],
    LEGAL: [
      {
        HEADING: 'Limitler ve Kurallar',
        LINKS: [
          {
            TEXT: 'Limitler ve Kurallar',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar',
              is_inner: false,
              target: '_blank'
            },
          }
        ]
      },
      {
        HEADING: 'Komisyonlar',
        LINKS: [
          {
            TEXT: 'Komisyonlar',
            LINK: {
              url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar',
              is_inner: false,
              target: '_blank'
            },
          },
        ]
      },
      {
        HEADING: 'Hukuki Metinler',
        LINKS: [
          {
            TEXT: 'Kullanıcı Sözleşmesi',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'Kişisel Verilerin Korunması Politikası',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Politikas%C4%B1.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'Bilgi Güvenliği Politikası',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox%20Bilgi%20G%C3%BCvenli%C4%9Fi%20Politikas%C4%B1.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'İmha Politikası',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Saklama%20ve%20%C4%B0mha%20Politikas%C4%B1.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'KVKK İlgili Kişi Başvuru Formu',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Sahibi%20Ba%C5%9Fvuru%20Formu.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
          {
            TEXT: 'Çerez Aydınlatma Metni',
            LINK: {
              url: 'https://www.artiox.com/assets/pdfs/Artiox%20%C3%87erez%20Ayd%C4%B1nlatma%20Metni.pdf',
              is_inner: false,
              target: '_blank'
            },
          },
        ]
      },
    ],
  },
  LEGAL: {
    HEADING: 'Yasal',
    INSPECT: 'İncele',
    // SHOW_AS_PDF: '.pdf olarak görüntüle',
    // TEXTS: [
    //   {
    //     HEADING: 'Kullanıcı Sözleşmesi',
    //     SRC: './assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'KVKK Politikası',
    //     SRC: './assets/pdfs/Artiox KVKK Politikası.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Bilgi Güvenliği Politikası',
    //     SRC: './assets/pdfs/Artiox Bilgi Güvenliği Politikası.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Bilgi%20G%C3%BCvenli%C4%9Fi%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Kişisel Veri Saklama ve İmha Politikası',
    //     SRC: './assets/pdfs/Artiox Kişisel Veri Saklama ve İmha Politikası.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Saklama%20ve%20%C4%B0mha%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Kişisel Veri Sahibi Başvuru Formu',
    //     SRC: './assets/pdfs/Artiox Kişisel Veri Sahibi Başvuru Formu.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Sahibi%20Ba%C5%9Fvuru%20Formu.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Çerez Aydınlatma Metni',
    //     SRC: './assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20%C3%87erez%20Ayd%C4%B1nlatma%20Metni.pdf', is_inner: false, target: '_blank'},
    //   },
    //
    //   {
    //     HEADING : 'KVKK Aydınlatma Metni',
    //     SRC: './assets/pdfs/Artiox KVKK Aydınlatma Metni.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Ayd%C4%B1nlatma%20Metni.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Pazarlama Açık Rıza Metni',
    //     SRC: './assets/pdfs/Artiox Pazarlama Açık Rıza Metni.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Pazarlama%20Ac%CC%A7%C4%B1k%20R%C4%B1za%20Metni.pdf', is_inner: false, target: '_blank'},
    //   },
    //   {
    //     HEADING : 'Yurtdışı Açık Rıza Metni',
    //     SRC: './assets/pdfs/Artiox Yurtdışı Açık Rıza Metni.pdf',
    //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Yurtd%C4%B1s%CC%A7%C4%B1%20Ac%CC%A7%C4%B1k%20R%C4%B1za%20Metni.pdf', is_inner: false, target: '_blank'},
    //   },
    // ],
    TEXT_NAMES: [
      'Kullanıcı Sözleşmesi',
      'KVKK Politikası',
      'Bilgi Güvenliği Politikası',
      'Kişisel Veri Saklama ve İmha Politikası',
      'Kişisel Veri Sahibi Başvuru Formu',
      'Çerez Aydınlatma Metni',
      'KVKK Aydınlatma Metni',
      'Pazarlama Açık Rıza Metni',
      'Yurtdışı Açık Rıza Metni',
      'Mesafeli Satış Sözleşmesi',
      'Müzayede İptal ve İade Koşulları',
      'Müzayede Kullanıcı Sözleşmesi',
      'Müzayede Kuralları',
      'Komisyonlar',
      'Limitler'
    ],
  },
  ACCOUNT_LEVEL: {
    HEADING: 'Hesap Seviyesi',
    DESCRIPTION_INTRO: 'Hesap seviyeniz ',
    DESCRIPTION_LEVEL0: '/2\'dir. "Kimlik Doğrulama" ile hesap seviyenizi yükseltip limitlerinizi arttırabilirsiniz.',
    DESCRIPTION_LEVEL1: '/2\'dir. "KYC Doğrulama" ile hesap seviyenizi yükseltip limitlerinizi arttırabilirsiniz.',
    DESCRIPTION_LEVEL2: '/2\'dir. Hesabınız en üst seviyededir.',
    LIMIT: 'Limit',
    REMAINING_LIMIT: 'Kalan Limit Bilgileriniz:',
    REMAINING_DAILY_LIMIT: 'Kalan Günlük Limit Bilgileriniz:',
    REMAINING_MONTHLY_LIMIT: 'Kalan Aylık Limit Bilgileriniz:',
    REMAINING_LIMIT_MOBILE: 'Kalan Limitler',
    YOUR_LEVEL_LIMIT: 'Hesap seviyenizin limit bilgileri:',
    LEVEL_1_LIMIT: 'Seviye 1 Limit Bilgileri',
    LEVEL_2_LIMIT: 'Seviye 2 Limit Bilgileri',
    LEVEL_2_REQUIRED: 'Seviye 2 Doğrulama Gerekli',
    LEVEL_2_VALID: 'Seviye 2 Doğrulama Tamamlandı',
    DAILY_LIMIT: 'Günlük Limit',
    MONTHLY_LIMIT: 'Aylık Limit',
    DAILY_REMAINING: 'Günlük Kalan',
    DAILY: 'Günlük',
    MONTHLY_REMAINING: 'Aylık Kalan',
    DEPOSIT_WITHDRAWAL: 'Yatırma/Çekme',
    MONTHLY: 'Aylık',
    LEVEL_UP: 'Şimdi Yükselt',
    LEVEL_UP_MOBILE: 'Yükselt',
  },
  SERVICE: {
    HEADER: {
      HEADING: 'HİZMET',
      HEADING_MOBILE: 'Hizmet',
      RULES: 'Limitler ve Kurallar',
      COMMISSIONS: 'Komisyonlar',
    },
    RULES: {
      HEADING: 'Limitler ve Kurallar',
      SUMMARY: 'Günlük ve aylık yatırma/çekme limitlerini aşağıdaki tablodan inceleyebilirsiniz.',
      LIMITS: 'Limitler',
      DAILY: 'Günlük',
      MONTHLY: 'Aylık',
      DEPOSIT: 'Yatırma',
      WITHDRAWAL: 'Çekme',
      DAILY_DEPOSIT_WITHDRAWAL: 'Günlük<br>Yatırma/Çekme',
      MONTHLY_DEPOSIT_WITHDRAWAL: 'Aylık<br>Yatırma/Çekme',
      SIGN_UP: 'Üyelik<br>Oluşturma',
      IDENTITY_DESCRIPTION: 'Kimlik<br>Beyanı',
      IDENTITY_VERIFICATION: 'Kimlik<br>Doğrulama',
      LEVEL: 'Seviye',
      LEVEL_0_DESCRIPTION: 'Sadece e-posta adresi onayı ile oluşturulmuş hesap seviyesidir.',
      LEVEL_1_DESCRIPTION: 'AD, SOYAD, TCKN, DOĞUM YILI bilgileriniz ile NVİ sistemiyle otomatik olarak yapılan doğrulama işlemidir. Doğrulama işlemi anında gerçekleşir.',
      LEVEL_2_DESCRIPTION: 'Sistemimize yüklediğiniz tarih detayı içeren kimlik belgeniz ve özçekiminiz ile ekibimizce doğrulanmış hesap seviyesidir. Doğrulama en geç 24 saat içinde gerçekleşmektedir.',
      TL_DEPOSIT_RULES_HEADING: 'Türk Lirası Yatırma Kuralları',
      TL_DEPOSIT_RULES: [
        'Kredi kartı ve ATM’lerden yapılan transferler onaylanmayacaktır.',
        'Lütfen transfer yaparken alıcı ünvanına "Artiox Kripto Varlık Alım Satım Platformu A.Ş." yazmayı unutmayınız.',
        'Transfer yaparken şirketimize ait IBAN numarasını doğru girdiğinizden emin olunuz.',
        'Lütfen transfer yaparken açıklama bölümüne size özel oluşturduğumuz kodu girmeyi unutmayınız. Ve bu kod dışında herhangi bir açıklama eklemeyiniz.',
        'Tarafımıza ulaşan ancak eksik ya da hatalı bilgilerden dolayı onaylayamadığımız transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.',
        'Havale ile gerçekleştirdiğiniz transferler 7/24 hesabınıza yansıtılırken, EFT yolu ile gerçekleşen transferler sadece mesai saatleri içinde hesabınıza yansıtılabilmektedir.',
        'Papara üzerinden yapılan yatırmalar geçerli değildir. Bu kanaldan yapılan transferler 5 iş günü içinde banka komisyonları düşülerek geri gönderilecektir.',
      ],
      TL_WITHDRAWAL_RULES_HEADING: 'Türk Lirası Çekme Kuralları',
      TL_WITHDRAWAL_RULES: [
        'Para çekme işlemi için kullanacağınız banka hesabı Artiox’a beyan ettiğiniz kimlik bilgileriniz ile eşleşmeli ve şahsınıza ait olmalıdır. Aksi durumda çekme işleminiz onaylanmayacaktır.',
        'Lütfen çekme talebinizi işleme koyabilmemiz için e-posta onayı vermeyi unutmayınız.',
        'Onaylanmayan istekler 24 saat sonra zaman aşımına uğrayacak, ilgili miktar kullanılabilir bakiyenize yansıtılacaktır. ',
        'Çekmek istediğiniz tutar en fazla kullanılabilir bakiyeniz kadar olabilir.',
        'En az para çekme limiti 100 Türk Lirası olarak belirlenmiştir.',
        'Her çekme işleminde 4 lira komisyon kesilmektedir.',
        'Garanti Bankası\'na ait hesabınıza 7/24 çekme talimatı verebilirsiniz.',
        'Diğer banka hesaplarınız için mesai saatleri içinde iletilen talepleriniz o gün içinde, aksi durumlarda bir sonraki iş gününde gerçekleştirilecektir.',
      ],
    },
    COMMISSIONS: {
      HEADING: 'Komisyonlar',
      SUMMARY: 'Artiox\'ta üyelik tamamen ücretsizdir. Alış ve satış komisyonları ise %0,5 olarak belirlenmiştir.',
    },
  },
  CORPORATE: {
    HEADER: {
      HEADING: 'KURUMSAL',
      ABOUT: 'Hakkımızda',
      ANNOUNCEMENTS: 'Duyurular',
      CAREER: 'Kariyer',
      CONTACT: 'İletişim',
    },
    ABOUT: {
      HEADING: 'Hakkımızda',
      DESCRIPTION: '2019 yılında kurulan şirketimiz ODTÜ Teknokent Bölgesinde faaliyetlerine devam etmektedir.' +
        ' Genç ve dinamik ODTÜ’lüler ve alanlarında tecrübeli iş insanlarından oluşan ekibimize ait detaylı bilgileri önümüzdeki süreçte bu sayfayı güncelleyerek sizlerle paylaşacağız.<br> ' +
        ' <strong>Artiox Kripto Varlık Alım Satım Platformu A.Ş.</strong>',
    },
    ANNOUNCEMENTS: {},
    CAREER: {
      HEADING: 'Kariyer',
      DESCRIPTION: 'CV\'nizi <strong>career@artiox.com</strong> adresine ileterek uygun pozisyonlar açıldığında sizinle iletişime geçmemizi sağlayabilirsiniz.',
      CURRENT_JOBS: 'Güncel İş İlanları',
      INSPECT: 'İncele',
      JOB_ADVERTISEMENTS: [
        {
          HEADING: 'Business Development Specialist',
          GENERAL_HEADING: 'Job Description',
          GENERAL: 'We are looking for a Business Development Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
          RESPONSIBILITIES_HEADING: 'Responsibilities',
          RESPONSIBILITIES: [
            'Analyze business performance, organize the schedule',
            'Design workflow processes to include the most effective ways to complete',
            'Assist our business and improve our strategic positioning by establishing new partnerships, doing market research, and building new product initiatives',
          ],
          QUALIFICATIONS_HEADING: 'Qualifications',
          QUALIFICATIONS: [
            'Bachelor Degree, Business Administration, Industrial Engineering, Management Information Systems or relevant fields',
            'Proficiency with Google Drive Tools and MS Office Tools, ability to analyze meetings and collect and review data',
            'Excellent communication in English (both written and spoken)',
            'The interest in blockchain technology and NFT’s is a BIG plus.',
          ],
          ADVANTAGEOUS_HEADING: '',
          ADVANTAGEOUS: [],
          ADVANTAGES_HEADING: 'Advantages',
          ADVANTAGES: [
            'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
            'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
            'Private Health Insurance',
            'Access to various learning & development opportunities',
            'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
            'Comfortable working environment (We do not have a solid hierarchical order in our company)',
            'Flexible working hours (while preserving total working hours per day)',
            'HPV vaccinations of female employees to be paid by Artiox',
            'Exclusive time off policy; birthday leave',
          ],
          WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
          LUNCH: 'Lunch: Multinet',
        },
        {
          HEADING: 'Performance Marketing Specialist ',
          GENERAL_HEADING: 'Job Description',
          GENERAL: 'We are looking for a Performance Marketing Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
          RESPONSIBILITIES_HEADING: 'Responsibilities',
          RESPONSIBILITIES: [
            'Developing, monitoring, optimizing campaigns and budget across multiple advertising platforms for our apps',
            'Managing creative asset production for campaigns',
            'Planning, testing and delivering new approaches based on A/B or multivariate testings',
            'Directing and overseeing ASO efforts',
            'Optimizing various steps in user funnel to increase the effectiveness of user acquisition campaigns',
            'Preparing detailed reports on our marketing campaign’s overall performance',
            'Evaluating important metrics that affect our CPI, CAC, LTV, ROI.',
            'Identifying the latest trends and technologies affecting app and digital marketing industries',
          ],
          QUALIFICATIONS_HEADING: 'Qualifications',
          QUALIFICATIONS: [
            'Min 2 year of performance marketing experience (app experience is a must)',
            'Experience in digital marketing and platforms like Google Ads, Tiktok Ads, Apple Search Ads, Meta Ads, Appsflyer, Adjust…',
            'Strong drive for A/B testing',
            'Research and analyze competitors, industry trends, and markets',
            'Provide reports, analyses, and recommendations for improving performance while making data-driven decisions',
            'Being curious, creative, and relentless in pursuit of growth',
            'Excellent level of written and spoken English',
            'University degree from a reputable University preferably in Engineering, Management or Economics',
            'Excellent communication in English (both written and spoken)',
            'The interest in blockchain technology and NFT’s is a BIG plus',
          ],
          ADVANTAGEOUS_HEADING: '',
          ADVANTAGEOUS: [],
          ADVANTAGES_HEADING: 'Advantages',
          ADVANTAGES: [
            'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
            'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
            'Private Health Insurance',
            'Access to various learning & development opportunities',
            'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
            'Comfortable working environment (We do not have a solid hierarchical order in our company)',
            'Flexible working hours (while preserving total working hours per day)',
            'HPV vaccinations of female employees to be paid by Artiox',
            'Exclusive time off policy; birthday leave',
          ],
          WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
          LUNCH: 'Lunch: Multinet',
        },
        {
          HEADING: 'Digital Marketing Specialist',
          GENERAL_HEADING: 'Job Description',
          GENERAL: 'We are looking for a Digital Marketing Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks' +
            ' via blockchain technology for the first time in the world and placed under ODTÜ Teknokent.',
          RESPONSIBILITIES_HEADING: 'Responsibilities',
          RESPONSIBILITIES: [
            'Plan and execute a comprehensive digital marketing strategy in line with the goals',
            'Support marketing activities broadly including content marketing, event marketing, social media marketing, email marketing',
            'Set up & optimize digital campaigns within multiple platforms including Google, Facebook/Instagram, Twiter, LinkedIn',
            'Provide reports, analyses, and recommendations for improving performance while making data-driven decisions',
            'Tracking campaign performance daily, weekly and monthly',
            'Research and analyze competitors, industry trends, and markets'
          ],
          QUALIFICATIONS_HEADING: 'Qualifications',
          QUALIFICATIONS: [
            'The desire to learn is a must-have!',
            'Excellent communication in English (both written and spoken)',
            'Min 2 years experience in digital media, content creation and/or agency management',
            'Experience in performance marketing channels',
            'B2C marketing experience is a BIG plus',
            'The interest in blockchain technology and NFT’s is a BIG plus',
          ],
          ADVANTAGEOUS_HEADING: '',
          ADVANTAGEOUS: [],
          ADVANTAGES_HEADING: 'Advantages',
          ADVANTAGES: [
            'Opportunity to work by taking responsibility in a fast-growing startup.',
            'Opportunity to work on a global scale soon.',
            'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Subway',
            'Flexible working hours (while preserving total working hours per day)',
            'Comfortable working environment (We do not have a solid hierarchical order in our company)',
            'Co-Zone benefits',
          ],
          WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
          LUNCH: 'Lunch: Multinet',
        },
        {
          HEADING: 'Graphic Designer',
          GENERAL_HEADING: 'Job Description',
          GENERAL: 'We are looking for a Graphic Designer for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
          RESPONSIBILITIES_HEADING: 'Responsibilities',
          RESPONSIBILITIES: [
            'Following the latest design trends in the art & finance',
            'Work with marketing team on defining the brand and product identities',
            'Design creative content for blogs, video diaries, social media',
            'Optimizing the visuals upon user reviews and activities',
            'Creating engaging visuals for marketing campaigns',
          ],
          QUALIFICATIONS_HEADING: 'Qualifications',
          QUALIFICATIONS: [
            'Interest in video editing and motion graphics',
            'The ability to create inspired, original designs',
            'Proficiency in Adobe Creative Suite programs (After Effects, Premiere Pro, XD, Photoshop, Illustrator, InDesign)',
            'Ability to adhere to deadlines and production schedules',
            'Passion for spotting trends within design, marketing, and social media',
            'Passion about art, crypto and blockchain technology',
            'Portfolio attached to the CV',
            'Bonus: Animation and 3D',
          ],
          ADVANTAGEOUS_HEADING: '',
          ADVANTAGEOUS: [],
          ADVANTAGES_HEADING: 'Advantages',
          ADVANTAGES: [
            'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
            'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
            'Private Health Insurance',
            'Access to various learning & development opportunities',
            'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
            'Comfortable working environment (We do not have a solid hierarchical order in our company)',
            'Flexible working hours (while preserving total working hours per day)',
            'HPV vaccinations of female employees to be paid by Artiox',
            'Exclusive time off policy; birthday leave',
          ],
          WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
          LUNCH: 'Lunch: Multinet',
        },
        {
          HEADING: 'Test Engineer',
          GENERAL_HEADING: 'Job Description',
          GENERAL: 'We are looking for a Test Engineer for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
          RESPONSIBILITIES_HEADING: 'Responsibilities',
          RESPONSIBILITIES: [
            'Writing test plans and creating test cases for the product',
            'Designing and implementing the tests that ensure the quality and the functionality of a product',
            'Effectively applying general test knowledge',
          ],
          QUALIFICATIONS_HEADING: 'Qualifications',
          QUALIFICATIONS: [
            'Bachelor’s degree in engineering, computer science, or computer engineering',
            'Previous work experience as a test engineer',
            'Familiarity with testing tools',
            'Knowledge of diagramming software',
            'Extensive knowledge of engineering and testing procedures',
            'Understanding test execution methods and tools',
          ],
          ADVANTAGEOUS_HEADING: '',
          ADVANTAGEOUS: [],
          ADVANTAGES_HEADING: 'Advantages',
          ADVANTAGES: [
            'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
            'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
            'Private Health Insurance',
            'Access to various learning & development opportunities',
            'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
            'Comfortable working environment (We do not have a solid hierarchical order in our company)',
            'Flexible working hours (while preserving total working hours per day)',
            'HPV vaccinations of female employees to be paid by Artiox',
            'Exclusive time off policy; birthday leave',
          ],
          WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
          LUNCH: 'Lunch: Multinet',
        },
        {
          HEADING: 'Flutter Developer',
          GENERAL_HEADING: 'Genel Nitelikler ve İş Tanımı',
          GENERAL: 'ODTÜ Teknokent\'te bulunan şirketimiz için yazılım ekibimize Flutter(cross-platform) uygulama geliştiricisi arıyoruz.',
          RESPONSIBILITIES_HEADING: '',
          RESPONSIBILITIES: [],
          QUALIFICATIONS_HEADING: 'Aranan Nitelikler',
          QUALIFICATIONS: [
            'Üniversitelerin Bilgisayar Mühendisliği veya ilgili bölümlerinden mezun olmak',
            'Linux/UNIX deneyimi',
            'Döküman yazacak seviyede İngilizce bilmek',
            'Flutter deneyimi',
            'Dart deneyimi',
            'En az 1 yıl boyunca çalışabilecek durumda olmak',
            'JSON ve REST API hakkında bilgi sahibi olmak',
            'Veri yapıları ve algoritmalar üzerine bilgi sahibi olmak',
            'Detaylara önem vermek',
            'Kod yazmayı sevmek',
            'Endüstri seviyesinde temiz kod yazmak',
            'Takım çalışmasına uygun olmak',
            'Gelişmeye ve öğrenmeye istekli olmak',
            'Sorumluluk sahibi ve disiplinli olmak',
          ],
          ADVANTAGEOUS_HEADING: 'Avantaj Sağlayan Nitelikler',
          ADVANTAGEOUS: [
            'Git',
            'Flutter ile en az 1 cross-platform uygulama yayınlamış olmak',
            'Flutter framework\'ü üzerine iş deneyimi sahibi olmak',
            'App Design',
          ],
          ADVANTAGES_HEADING: 'Avantajlar',
          ADVANTAGES: [
            'Co-Zone, ODTÜ Teknokent Bilişim ve İnovasyon Merkezi’ne kolay ulaşım(metro durağı 20m)',
            'Esnek çalışma saatleri (Çalışma süresini koruyacak şekilde giriş ve çıkış saatlerinizi belirleyebilirsiniz)',
            'Rahat çalışma ortamı (Şirketimizde hiyerarşik bir düzen, bürokrasi ve katı kurallar yoktur)',
            'Co-Zone avantajları',
          ],
          WORKING_HOURS: 'Mesai Saatleri: Pazartesi - Cuma, ~9.00 - ~18.00',
          LUNCH: 'Yemek: Multinet',
        },
        {
          HEADING: 'Android Developer',
          GENERAL_HEADING: 'Genel Nitelikler ve İş Tanımı',
          GENERAL: 'ODTÜ Teknokent\'te bulunan şirketimiz için yazılım ekibimize Android uygulama geliştiricisi arıyoruz.',
          RESPONSIBILITIES_HEADING: '',
          RESPONSIBILITIES: [],
          QUALIFICATIONS_HEADING: 'Aranan Nitelikler',
          QUALIFICATIONS: [
            'Üniversitelerin Bilgisayar Mühendisliği veya ilgili bölümlerinden mezun olmak',
            'Linux/UNIX deneyimi',
            'Döküman yazacak seviyede İngilizce bilmek',
            'Android Studio deneyimi',
            'Android SDK deneyimi (26+)',
            'En az 1 yıl boyunca çalışabilecek durumda olmak',
            'JSON ve REST API hakkında bilgi sahibi olmak',
            'Veri yapıları ve algoritmalar üzerine bilgi sahibi olmak',
            'Detaylara önem vermek',
            'Kod yazmayı sevmek',
            'Endüstri seviyesinde temiz kod yazmak',
            'Takım çalışmasına uygun olmak',
            'Gelişmeye ve öğrenmeye istekli olmak',
            'Sorumluluk sahibi ve disiplinli olmak',
          ],
          ADVANTAGEOUS_HEADING: 'Avantaj Sağlayan Nitelikler',
          ADVANTAGEOUS: [
            'Git',
            'En az 1 Android uygulama yayınlamış olmak',
            'Android platformu üzerine iş deneyimi sahibi olmak',
            'App Design',
          ],
          ADVANTAGES_HEADING: 'Avantajlar',
          ADVANTAGES: [
            'Co-Zone, ODTÜ Teknokent Bilişim ve İnovasyon Merkezi’ne kolay ulaşım(metro durağı 20m)',
            'Esnek çalışma saatleri (Çalışma süresini koruyacak şekilde giriş ve çıkış saatlerinizi belirleyebilirsiniz)',
            'Rahat çalışma ortamı (Şirketimizde hiyerarşik bir düzen, bürokrasi ve katı kurallar yoktur)',
            'Co-Zone avantajları',
          ],
          WORKING_HOURS: 'Mesai Saatleri: Pazartesi - Cuma, ~9.00 - ~18.00',
          LUNCH: 'Yemek: Multinet',
        },
        {
          HEADING: 'IOS Developer',
          GENERAL_HEADING: 'Genel Nitelikler ve İş Tanımı',
          GENERAL: 'ODTÜ Teknokent\'te bulunan şirketimiz için yazılım ekibimize iOS uygulama geliştiricisi arıyoruz.',
          RESPONSIBILITIES_HEADING: '',
          RESPONSIBILITIES: [],
          QUALIFICATIONS_HEADING: 'Aranan Nitelikler',
          QUALIFICATIONS: [
            'Üniversitelerin Bilgisayar Mühendisliği veya ilgili bölümlerinden mezun olmak',
            'Linux/UNIX deneyimi',
            'Döküman yazacak seviyede İngilizce bilmek',
            'IOS ve Xcode deneyimi',
            'Objective-C, Swift, ve Cocoa Touch deneyimi',
            'En az 1 yıl boyunca çalışabilecek durumda olmak',
            'JSON ve REST API hakkında bilgi sahibi olmak',
            'Veri yapıları ve algoritmalar üzerine bilgi sahibi olmak',
            'Detaylara önem vermek',
            'Kod yazmayı sevmek',
            'Endüstri seviyesinde temiz kod yazmak',
            'Takım çalışmasına uygun olmak',
            'Gelişmeye ve öğrenmeye istekli olmak',
            'Sorumluluk sahibi ve disiplinli olmak',
          ],
          ADVANTAGEOUS_HEADING: 'Avantaj Sağlayan Nitelikler',
          ADVANTAGEOUS: [
            'Git',
            'En az 1 IOS uygulama yayınlamış olmak',
            'IOS platformu üzerine iş deneyimi sahibi olmak',
            'App Design',
          ],
          ADVANTAGES_HEADING: 'Avantajlar',
          ADVANTAGES: [
            'Co-Zone, ODTÜ Teknokent Bilişim ve İnovasyon Merkezi’ne kolay ulaşım(metro durağı 20m)',
            'Esnek çalışma saatleri (Çalışma süresini koruyacak şekilde giriş ve çıkış saatlerinizi belirleyebilirsiniz)',
            'Rahat çalışma ortamı (Şirketimizde hiyerarşik bir düzen, bürokrasi ve katı kurallar yoktur)',
            'Co-Zone avantajları',
          ],
          WORKING_HOURS: 'Mesai Saatleri: Pazartesi - Cuma, ~9.00 - ~18.00',
          LUNCH: 'Yemek: Multinet',
        },
        {
          HEADING: 'Frontend Developer',
          GENERAL_HEADING: 'Genel Nitelikler ve İş Tanımı',
          GENERAL: 'ODTÜ Teknokent\'te bulunan şirketimiz için yazılım ekibimize Angular 2+ bilen frontend web geliştiricisi arıyoruz.',
          RESPONSIBILITIES_HEADING: '',
          RESPONSIBILITIES: [],
          QUALIFICATIONS_HEADING: 'Aranan Nitelikler',
          QUALIFICATIONS: [
            'Üniversitelerin Bilgisayar Mühendisliği veya ilgili bölümlerinden mezun olmak',
            'Linux/UNIX deneyimi',
            'Döküman yazacak seviyede İngilizce bilmek',
            'Angular 2 veya üzerini kullanmış olmak',
            'NodeJS kullanmış olmak',
            'En az 1 yıl boyunca çalışabilecek durumda olmak',
            'JSON ve REST API hakkında bilgi sahibi olmak',
            'Veri yapıları ve algoritmalar üzerine bilgi sahibi olmak',
            'Detaylara önem vermek',
            'Kod yazmayı sevmek',
            'Endüstri seviyesinde temiz kod yazmak',
            'Takım çalışmasına uygun olmak',
            'Gelişmeye ve öğrenmeye istekli olmak',
            'Sorumluluk sahibi ve disiplinli olmak',
          ],
          ADVANTAGEOUS_HEADING: 'Avantaj Sağlayan Nitelikler',
          ADVANTAGEOUS: [
            'Git',
            'NOSQL veritabanı hakkında bilgi sahibi olmak',
            'Web geliştirme üzerine iş deneyimi sahibi olmak',
            'Responsive Web Design',
          ],
          ADVANTAGES_HEADING: 'Avantajlar',
          ADVANTAGES: [
            'Co-Zone, ODTÜ Teknokent Bilişim ve İnovasyon Merkezi’ne kolay ulaşım(metro durağı 20m)',
            'Esnek çalışma saatleri (Çalışma süresini koruyacak şekilde giriş ve çıkış saatlerinizi belirleyebilirsiniz)',
            'Rahat çalışma ortamı (Şirketimizde hiyerarşik bir düzen, bürokrasi ve katı kurallar yoktur)',
            'Co-Zone avantajları',
          ],
          WORKING_HOURS: 'Mesai Saatleri: Pazartesi - Cuma, ~9.00 - ~18.00',
          LUNCH: 'Yemek: Multinet',
        },
      ],
      APPLY_NOW: 'Hemen Başvur'
    },
    CONTACT: {
      HEADING: 'İletişim Bilgilerimiz',
      MAIL: 'career@artiox.com',
      ADDRESS: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş.<br>' +
        ' Mustafa Kemal Mahallesi Bilişim İnovasyon Merkezi<br>' +
        ' ODTÜ Teknokent 280, D:G, 06510 <br>' +
        ' Çankaya/Ankara',
    },
  },
  ANNOUNCEMENTS: {
    HEADERS: {
      ANNOUNCEMENTS: 'Duyurular',
      DESCRIPTION: 'Artiox\'ta yapılan işlemler hakkındaki güncel haberlere ve listelenen eserlerin sanatçıları hakkında detaylı bilgilere ulaşabilirsiniz.',
      OPERATION_ANNOUNCEMENTS: 'Genel Duyurular',
      OPERATION_ANNOUNCEMENTS_EXPLANATION: 'Artiox\'ta yapılan işlemler hakkındaki güncel haberler',
      OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: './assets/announcements/operation_announcements/0.d.webp',
      OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: './assets/announcements/operation_announcements/0.m.webp',
      ARTISTS_ANNOUNCEMENTS: 'Sanatçılar Hakkında',
      ARTISTS_ANNOUNCEMENTS_EXPLANATION: 'Artiox\'ta listelenen eserlerin sanatçıları hakkında detaylı bilgiler',
      ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: './assets/announcements/artist_announcements/0.d.webp',
      ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: './assets/announcements/artist_announcements/0.m.webp',
    },
    ARTIST_ANNOUNCEMENTS: artists_config_tr,
    OPERATION_ANNOUNCEMENTS: announcements_config_tr,
  },
  ART_PRODUCTS: art_products_config_tr,
  SNACK_BAR: {
    SUCCESS: 'Başarılı İşlem',
    FAIL: 'Başarısız İşlem'
  },
  TOOLTIP: {
    BASIC: {
      ACTIVE_ART_PRODUCT_SYMBOL_TOOLTIP: 'Esere ait tokenin sembolü',
      ACTIVE_PAIR_PRICE_TOOLTIP: 'Gerçekleşen son işlem fiyatı',
      ACTIVE_PAIR_CHANGE_TOOLTIP: 'Son işlem fiyatı ile 24 saat önceki işlem fiyatının değişimi ',
      ACTIVE_PAIR_VOLUME_TOOLTIP: 'Bu işlem çiftinde son 24 saatte gerçekleşen alım ve satım işlemlerinin toplamı',

      ACTIVE_ART_PRODUCT_NAME_TOOLTIP: 'Eserin adı',
      ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH_TOOLTIP: 'Eserin sanatçısı ',
      ART_PRODUCT_MARKET_VALUE_TOOLTIP: 'Bu değer, eserin bütününün güncel değerini ifade eder.' +
        ' Bu esere ait toplam token sayısı ve son işlem fiyatının çarpılması ile elde edilir.',
      ART_PRODUCT_REPORT_LIST_TOOLTIP: 'Listelenen eserlere ait önemli rapor ve bilgiler',

      ART_PRODUCTS_TOOLTIP: 'Markette listelenmekte olan diğer eserler',

      SWITCH_BUTTON_TOOLTIP: 'İşlem yönü değiştirme butonu',
    },
    ADVANCED: {
      ACTIVE_PAIR_PRICE_TOOLTIP: 'Gerçekleşen son işlem fiyatı',
      ACTIVE_PAIR_CHANGE_TOOLTIP: 'Son işlem fiyatı ile 24 saat önceki işlem fiyatının değişimi ',
      ACTIVE_PAIR_VOLUME_TOOLTIP: 'Bu işlem çiftinde son 24 saatte gerçekleşen alım ve satım işlemlerinin toplamı',
      ACTIVE_PAIR_MIN_MAX_TOOLTIP: 'Tokenin son 24 saatte işlem gördüğü en yüksek ve en düşük fiyat',

      BALANCE_TOOLTIP: 'Kullanıcının sahip olduğu tokenleri ve itibari para birimlerini sıralar.' +
        ' Denk ₺ kullanıcının portföyünde bulunan tokenlerin güncel toplam değerini ifade eder.',
      ORDER_BOOK_HEADER_TOOLTIP: 'Bu tokene ait alış ve satış emirlerinin sıralı gösterimidir. ',

      DEPTH_CHART_TOOLTIP: 'Bu buton ile derinlik grafiğini gözlemleyebilirsiniz. Derinlik grafiği, alış ve satış emirlerinin gösterimidir.',

      LIMIT_TOOLTIP: 'Bu bölümden almak ya da satmak istediğiniz token için fiyat ve miktar tercihleri ile emir girebilirsiniz.' +
        ' Girmiş olduğunuz emirler, aşağıda \'açık emirler\' bölümünde sıralanacaktır; iptal etmek için de açık emirler bölümünü kullanabilirsiniz.' +
        ' Ayrıca açık emirleriniz kullanılabilir token miktarınızdan düşecektir.',
      MARKET_TOOLTIP: 'Bu bölümde almak veya satmak istediğiniz token adedini girdiğinizde, emir defterindeki' +
        ' en uygun fiyattan başlanarak girmiş olduğunuz miktar tamamlanana kadar işlemleriniz gerçekleşir.' +
        ' Gerçekleşen işlemler sonucunda ortalama işlem fiyatınızı, aşağıdaki \'işlem geçmişiniz\' bölümünden gözlemleyebilirsiniz.',

      TRADE_HISTORY_HEADER_TOOLTIP: 'Bu işlem çiftinde gerçekleşen işlemleri anlık ve sıralı olarak gösterir.',

      USER_OPEN_ORDERS_HEADING_TOOLTIP: 'Kullanıcının gerçekleşmeyi bekleyen alış ve satış emirlerini gösterir.',
      USER_TRADE_HISTORY_HEADING_TOOLTIP: 'Kullanıcının gerçekleşen alış ve satış emirlerini gösterir.' +
        ' Daha detaylı gözlem yapabilmek için "Cüzdan"ınızdan işlem geçmişi bölümünü inceleyebilirsiniz.',
    },
  },
  PAGE_NOT_FOUND: {
    DESCRIPTION: 'Aradığınız sayfa bulunamadı.',
    GO_HOME: 'Anasayfaya Dön'
  },
  CONNECTION_ERROR: {
    HEADING: 'Tekrar Bağlanıyor',
    DESCRIPTION: 'Bağlantı kurulurken lütfen bekleyin.',
  },
  UPDATE_APP: {
    HEADING: 'Güncelleme Mevcut',
    DESCRIPTION: 'Artiox\'un yeni bir sürümü var.<br>' +
      'Şimdi güncellemek ister misiniz?',
    UPDATE: 'Güncelle',
    CONTINUE: 'Devam Et',
  },
  GENERIC_MODAL: {
    OPEN: false,
    INNER_HTML: '    <div>' +
      '      <div class=" w-100 d-flex flex-flex justify-content-end" style="font-size: 12px;font-weight: 300;color: #9da5b7;">' +
      '        <div onclick="window.closeGenericModal();" >' +
      '          x' +
      '        </div>' +
      '      </div>' +
      '      <div class="d-flex flex-column align-items-center">' +
      '      <span style="margin-top: -8px; font-family: bebas-neue-bold, sans-serif; letter-spacing: 1.7px; text-align: center; color: #424554;">' +
      '        SÜRÜM GÜNCELLEMESİ' +
      '      </span>' +
      '        <div style="width: 160px; height: 160px; margin-top: 32px; padding: 50px; border-radius: 160px; background-color: rgba(80, 191, 210, 0.1)">' +
      '          <svg style="height: 60px; width: 60px;">' +
      '            <use fill="#f9f9f9" xlink:href="https://artiox.com/assets/images/update.svg#update"></use>' +
      '          </svg>' +
      '        </div>' +
      '        <span style="margin-top: 32px; font-family: Roboto, sans-serif; font-size: 11px; letter-spacing: 0.35px; color: #49566b;">' +
      '        Türk Lirası çekme işlemleri için <strong>uygulamanızı güncellemeniz gerekmektedir</strong>.' +
      '        Uygulamanızı daha sonra güncellemeyi tercih ediyorsanız web sitemizden çekme işlemlerinizi yapabilirsiniz.' +
      '      </span>' +
      '        <div class="w-100 d-flex flex-row justify-content-around align-items-center" style="margin-top: 32px; margin-bottom: 8px">' +
      '          <a href="https://artiox.com/" target="_blank" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; letter-spacing: 0.35px; color: #4fc0d2; text-decoration: none;">' +
      '            Web Sitesine Git' +
      '          </a>' +
      '          <button onclick="window.openAppMarket();" style="width: 120px; padding: 8px 16px; border: none; border-radius: 8px; box-shadow: 2px 2px 4px 0 rgba(213, 213, 213, 0.83); background-color: #4fc0d2;">' +
      '            <div style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; letter-spacing: 0.35px; text-align: center; color: #fcfdff;">' +
      '              Güncelle' +
      '            </div>' +
      '          </button>' +
      '        </div>' +
      '      </div>' +
      '    </div>',
    TIME_THRESHOLD: 6000,
    CLOSE_DISABLED: false,
  },
  ONE_PAGER: {
    DESCRIPTION: [
      {TEXT: 'Sadece zenginler mi sanata yatırım yapar?', WEIGHT: 500},
      {TEXT: 'Peki sanat yatırımı maddi kazancın yanında prestij sağlar mı?', WEIGHT: 500},
      {
        TEXT: 'Yaşadığın toplumda prestijli bir yer edinmenin yolu bindiğin araba mı yoksa kültürel bir miras olan sanat eserine sahip olmak mı?',
        WEIGHT: 500
      },
      {TEXT: 'Peki ya Picasso tablosuna ortak olmak sana ne hissettirirdi?', WEIGHT: 600},
      {
        TEXT: 'Sanat yatırımı 3.0’ın ne olduğunu keşfetmek için hemen tıkla ve sanat yatırımı dünyasına bir adım at!',
        WEIGHT: 500
      },
    ],
    BUTTON_TEXT: 'Keşfet',
    BUTTON_LINK: {url: 'https://artiox.onelink.me/wOYr/b7c26bd', is_inner: false, target: '_blank'},
  },
  AWARD: {
    TITLE: 'ARTIOX ÖDÜLLÜ ANKET',
    INFO: 'Formu doldurduğunuz için teşekkür ederiz. ' +
      'Çarkı çevirerek ödülünüzü öğrenebilirsiniz. ' +
      'Kazandığınız miktar aşağıdaki adımları tamamladığınızda 24 saat içerisinde hesabınıza yatırılacaktır.',
    IDIOM: 'Bol Şanslar!',
    EMAIL_INFO: 'Ödülünüzü alabilmeniz için girdiğiniz email adresi ile hesap açmanız gerekmektedir. <span class="text-decoration-underline">Lütfen email adresinizi doğru girdiğinize emin olun!</span>',
    EMAIL_PLACEHOLDER: 'Ankete girdiğiniz email adresi',
    AWARD_DECLARATION: {STRING: '$AWARD TL kazandınız', TOKENS: ['$AWARD']},
    AWARD_INFO: 'Ödülünüzü yukarıda belirtiğiniz email adresi ile açtığınız Artiox hesabınıza 24 saat içerisinde yükleyeceğiz.<br/>Ödülünüzü alabilmek için hesabınızı açtıktan sonra “Hesap Seviyenizi” yükseltmeniz gerekmektedir. Aşağıda bulunan yönergeleri izleyerek hesabınızı oluşturabilir ve yükseltebilirsiniz.',
    STEP: 'Adım',
    STEPS: [
      {
        INFO: 'Artioxa kayıt olup giriş yaptıktan sonra sağ üstteki panelden güvenlik sekmesine tıklayınız.',
        IMAGE: './assets/screen_shots/desktop/frame_1.png'
      },
      {
        INFO: 'Üstteki seçeneklerden kimlik doğrulamaya gidiniz.',
        IMAGE: './assets/screen_shots/desktop/frame_2.png'
      },
      {
        INFO: 'Kimlik doğrulama formunu doldurarak kimlik doğrulama işleminizi tamamlayınız.',
        IMAGE: './assets/screen_shots/desktop/frame_3.png'
      },
    ]
  },
  TEST_ENVIRONMENT: {
    TITLE: 'Önemli Uyarı',
    DESCRIPTION: 'Bu site Artiox tarafından daha iyi hizmet verebilmek için test amacıyla kullanıma açılmıştır.' +
      ' Kimlik doğrulama, çift aşamalı doğrulama, şifre değiştirme vb. işlemler <strong>www.artiox.com</strong>\'dan tamamen bağımsız olup test ortamında gerçekleşmektedir.' +
      ' <strong>test.artiox.com</strong> üzerinden gelen mailler <strong>www.artiox.com</strong> için geçerli değildir.' +
      ' Test ortamına katılmak için support@artiox.com adresine mail atabilirsiniz. ',
    RULES: [
      'Yapılan tüm işlemler test amaçlıdır, gerçeği yansıtmaz.',
      'Üyelik ve kimlik bilgileri kalıcı değildir.',
      'Yatırma ve çekme işlemleri test amaçlı olup tamamen geçersizdir.',
    ],
  },
  // TURKEY_TOWN_LIST: ['İstanbul', 'Ankara', 'İzmir', 'Adana', 'Adıyaman', 'Afyon', 'Aksaray', 'Amasya', 'Antalya', 'Ardahan',
  //   'Artvin', 'Aydın', 'Ağrı', 'Balıkesir', 'Bartın', 'Batman', 'Bayburt', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa',
  //   'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Düzce', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep',
  //   'Giresun', 'Gümüşhane', 'Hakkâri', 'Hatay', 'Isparta', 'Iğdır', 'Kahramanmaraş', 'Karabük', 'Karaman', 'Kars', 'Kastamonu', 'Kayseri',
  //   'Kilis', 'Kocaeli', 'Konya', 'Kütahya', 'Kırklareli', 'Kırıkkale', 'Kırşehir', 'Malatya', 'Manisa', 'Mardin', 'Mersin', 'Muğla', 'Muş',
  //   'Nevşehir', 'Niğde', 'Ordu', 'Osmaniye', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Şanlıurfa', 'Şırnak', 'Tekirdağ',
  //   'Tokat', 'Trabzon', 'Tunceli', 'Uşak', 'Van', 'Yalova', 'Yozgat', 'Zonguldak'],
  MISC: {
    // APP_VERSION: '2.0.0',
    // APP_ANDROID_VERSION: '1.0.5',
    // APP_IOS_VERSION: '1.0.5',
    ANNOUNCEMENT_ACTIVE: false,
    ZOOM_LEVEL: 'SevenDays',
    BUY: 'al',
    SELL: 'sat',
    ONELINK: 'https://artiox.onelink.me/wOYr/b7c26bd',
    LOADING_ANIM_DESKTOP_WEBM: './assets/animations/loading.d.webm',
    LOADING_ANIM_DESKTOP_MP4: './assets/animations/loading.d.mp4',
    LOADING_ANIM_MOBILE_WEBM: './assets/animations/loading.m.webm',
    LOADING_ANIM_MOBILE_MP4: './assets/animations/loading.m.mp4',
    READ_ACCEPTED: 'Okudum, onaylıyorum',
    CONTINUE: 'Devam Et',
    CHANGELOG_HEADER: 'Değişiklikler',
    CHANGELOG_WEB: [
      {
        VERSION: '1.0.5',
        INFO: '<ul>' +
          '  <li>Yeni UI Dizaynı</li>' +
          '  <li>Tamamen Yeni Uygulama Geliştirmesi</li>' +
          '  <li>Yazılım Çerçeve Desteği</li>' +
          '  <li>Geliştirilmiş Performans Ölçütleri</li>' +
          '  <li>Pürüzsüz Animasyonlar</li>' +
          '  <li>Donanım Destekli Görüntüleme</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.1',
        INFO: '<ul>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.0',
        INFO: '<ul>' +
          '  <li>Kripto Para Yatırma Yöneticisi</li>' +
          '  <li>Kripto Para Çekme Yöneticisi</li>' +
          '  <li>Kripto Para Takas Yöneticisi</li>' +
          '  <li>Geri Sayım Aracı Değişikliği</li>' +
          '  <li>Modal Yönetici Değişikliği</li>' +
          '  <li>Operasyon Hizmeti Değişikliği</li>' +
          '  <li>Panel Değişikliği</li>' +
          '  <li>Kullanıcı Para Birimi Limitleri Değişikliği</li>' +
          '  <li>Ethereum Ağı Desteği</li>' +
          '  <li>Olay Yayıcı Yayın Kanalları Değişikliği</li>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.3',
        INFO: '<ul>' +
          '  <li>Güncelleme Hafıza Yöneticisi</li>' +
          '  <li>Farklı Cihazlardan Giriş Düzeltmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.2',
        INFO: '<ul>' +
          '  <li>Çerçeve ve Paket Güncellemeleri</li>' +
          '  <li>Butonlara Ardışık Basma Limiti</li>' +
          '  <li>İstek Limiti</li>' +
          '  <li>Zendesk Eklentisi</li>' +
          '  <li>Çıkış Yapma Hatası Giderme</li>' +
          '  <li>Bağlantı Hatası Giderme</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.1',
        INFO: '<ul>' +
          '  <li>İşlem Geçmişi Grafiği Yerel Tarih Düzeltmesi</li>' +
          '  <li>Üye Olma Navigasyonu</li>' +
          '  <li>Duyuru Kartları Kaydırma</li>' +
          '  <li>Yeni Eser Listeleme</li>' +
          '  <li>Bağlantı Hatası Sayfası</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.8',
        INFO: '<ul>' +
          '  <li>2FA Kaldırma Özelliği</li>' +
          '  <li>Banka Ekleme Otomatik Doldurma Özelliği</li>' +
          '  <li>Üye Olma Formu İyileştirmeler</li>' +
          '  <li>Yatırma-Çekme İşlemleri Yeni Kurallar</li>' +
          '  <li>Çekme İşlemi Zaman Aşımı</li>' +
          '  <li>Mail Geri Bildirim İyileştirmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.7',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Güvenlik için Yeni Şifre Kuralları</li>' +
          '  <li>Kayıt Bildirimleri</li>' +
          '  <li>Blog Deneyimi İyileştirmesi</li>' +
          '  <li>Gelişmiş Al-Sat Grafiği İyileştirmesi</li>' +
          '  <li>Güvenlik Önlemleri</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.6',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Navigasyon Servisi Yeni Routing Kuralları</li>' +
          '  <li>ExchangeResolver İyileştirmesi</li>' +
          '  <li>User LoggedInResolver İyileştirmesi</li>' +
          '  <li>Pre Sale Navigasyon Menü</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.5',
        INFO: '<ul>' +
          '  <li>Mobil Android-IOS Market Derin Link Bağlantısı</li>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Derinlik Grafiği Düzeltmesi</li>' +
          '  <li>Bugfix: Alım-Satım Geçmişi Sıralama Düzeltmesi</li>' +
          '  <li>Kimlik Doğrulama Düzeltmesi</li>' +
          '  <li>Test Ortamı Kurulumu</li>' +
          '  <li>Test Ortamı Hata Kodları</li>' +
          '  <li>Son Güncellenme Tarihi ve Versiyon Numarası</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
    ],
    CHANGELOG_ANDROID: [
      {
        VERSION: '1.0.5',
        INFO: '<ul>' +
          '  <li>Yeni UI Dizaynı</li>' +
          '  <li>Tamamen Yeni Uygulama Geliştirmesi</li>' +
          '  <li>Yazılım Çerçeve Desteği</li>' +
          '  <li>Geliştirilmiş Performans Ölçütleri</li>' +
          '  <li>Pürüzsüz Animasyonlar</li>' +
          '  <li>Donanım Destekli Görüntüleme</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.1',
        INFO: '<ul>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.0',
        INFO: '<ul>' +
          '  <li>Düşük API Düzeyi Desteği</li>' +
          '  <li>Kripto Para Yatırma Yöneticisi</li>' +
          '  <li>Kripto Para Çekme Yöneticisi</li>' +
          '  <li>Kripto Para Takas Yöneticisi</li>' +
          '  <li>Geri Sayım Aracı Değişikliği</li>' +
          '  <li>Modal Yönetici Değişikliği</li>' +
          '  <li>Operasyon Hizmeti Değişikliği</li>' +
          '  <li>Panel Değişikliği</li>' +
          '  <li>Kullanıcı Para Birimi Limitleri Değişikliği</li>' +
          '  <li>Ethereum Ağı Desteği</li>' +
          '  <li>Olay Yayıcı Yayın Kanalları Değişikliği</li>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.3',
        INFO: '<ul>' +
          '  <li>Güncelleme Hafıza Yöneticisi</li>' +
          '  <li>Farklı Cihazlardan Giriş Düzeltmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.2',
        INFO: '<ul>' +
          '  <li>Çerçeve ve Paket Güncellemeleri</li>' +
          '  <li>Butonlara Ardışık Basma Limiti</li>' +
          '  <li>İstek Limiti</li>' +
          '  <li>Zendesk Eklentisi</li>' +
          '  <li>Çıkış Yapma Hatası Giderme</li>' +
          '  <li>Bağlantı Hatası Giderme</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.1',
        INFO: '<ul>' +
          '  <li>İşlem Geçmişi Grafiği Yerel Tarih Düzeltmesi</li>' +
          '  <li>Üye Olma Navigasyonu</li>' +
          '  <li>Duyuru Kartları Kaydırma</li>' +
          '  <li>Yeni Eser Listeleme</li>' +
          '  <li>Bağlantı Hatası Sayfası</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.8',
        INFO: '<ul>' +
          '  <li>2FA Kaldırma Özelliği</li>' +
          '  <li>Banka Ekleme Otomatik Doldurma Özelliği</li>' +
          '  <li>Üye Olma Formu İyileştirmeler</li>' +
          '  <li>Yatırma-Çekme İşlemleri Yeni Kurallar</li>' +
          '  <li>Çekme İşlemi Zaman Aşımı</li>' +
          '  <li>Mail Geri Bildirim İyileştirmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.7',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Güvenlik için Yeni Şifre Kuralları</li>' +
          '  <li>Kayıt Bildirimleri</li>' +
          '  <li>Blog Deneyimi İyileştirmesi</li>' +
          '  <li>Gelişmiş Al-Sat Grafiği İyileştirmesi</li>' +
          '  <li>Güvenlik Önlemleri</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.6',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Navigasyon Servisi Yeni Kurallar</li>' +
          '  <li>ExchangeResolver İyileştirmesi</li>' +
          '  <li>User LoggedInResolver İyileştirmesi</li>' +
          '  <li>Pre Sale Navigasyon Menü</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.2',
        INFO: '<ul>' +
          '  <li>Mobil Android-IOS Market Derin Link Bağlantısı</li>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Derinlik Grafiği Düzeltmesi</li>' +
          '  <li>Bugfix: Alım-Satım Geçmişi Sıralama Düzeltmesi</li>' +
          '  <li>Kimlik Doğrulama Düzeltmesi</li>' +
          '  <li>Bugfix: Firebase Eklenti Düzeltmesi</li>' +
          '  <li>Bugfix: Appsflyer Eklenti Düzeltmesi</li>' +
          '  <li>Bugfix: Facebook Eklenti Düzeltmesi</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
    ],
    CHANGELOG_IOS: [
      {
        VERSION: '1.0.5',
        INFO: '<ul>' +
          '  <li>Yeni UI Dizaynı</li>' +
          '  <li>Tamamen Yeni Uygulama Geliştirmesi</li>' +
          '  <li>Yazılım Çerçeve Desteği</li>' +
          '  <li>Geliştirilmiş Performans Ölçütleri</li>' +
          '  <li>Pürüzsüz Animasyonlar</li>' +
          '  <li>Donanım Destekli Görüntüleme</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.1',
        INFO: '<ul>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.6.0',
        INFO: '<ul>' +
          '  <li>Kripto Para Yatırma Yöneticisi</li>' +
          '  <li>Kripto Para Çekme Yöneticisi</li>' +
          '  <li>Kripto Para Takas Yöneticisi</li>' +
          '  <li>Geri Sayım Aracı Değişikliği</li>' +
          '  <li>Modal Yönetici Değişikliği</li>' +
          '  <li>Operasyon Hizmeti Değişikliği</li>' +
          '  <li>Panel Değişikliği</li>' +
          '  <li>Kullanıcı Para Birimi Limitleri Değişikliği</li>' +
          '  <li>Ethereum Ağı Desteği</li>' +
          '  <li>Olay Yayıcı Yayın Kanalları Değişikliği</li>' +
          '  <li>İkon Değişiklikleri</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.3',
        INFO: '<ul>' +
          '  <li>Güncelleme Hafıza Yöneticisi</li>' +
          '  <li>Farklı Cihazlardan Giriş Düzeltmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.2',
        INFO: '<ul>' +
          '  <li>Çerçeve ve Paket Güncellemeleri</li>' +
          '  <li>Butonlara Ardışık Basma Limiti</li>' +
          '  <li>İstek Limiti</li>' +
          '  <li>Zendesk Eklentisi</li>' +
          '  <li>Çıkış Yapma Hatası Giderme</li>' +
          '  <li>Bağlantı Hatası Giderme</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.5.1',
        INFO: '<ul>' +
          '  <li>İşlem Geçmişi Grafiği Yerel Tarih Düzeltmesi</li>' +
          '  <li>Üye Olma Navigasyonu</li>' +
          '  <li>Duyuru Kartları Kaydırma</li>' +
          '  <li>Yeni Eser Listeleme</li>' +
          '  <li>Bağlantı Hatası Sayfası</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.8',
        INFO: '<ul>' +
          '  <li>2FA Kaldırma Özelliği</li>' +
          '  <li>Banka Ekleme Otomatik Doldurma Özelliği</li>' +
          '  <li>Üye Olma Formu İyileştirmeler</li>' +
          '  <li>Yatırma-Çekme İşlemleri Yeni Kurallar</li>' +
          '  <li>Çekme İşlemi Zaman Aşımı</li>' +
          '  <li>Mail Geri Bildirim İyileştirmesi</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.7',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Güvenlik için Yeni Şifre Kuralları</li>' +
          '  <li>Kayıt Bildirimleri</li>' +
          '  <li>Blog Deneyimi İyileştirmesi</li>' +
          '  <li>Gelişmiş Al-Sat Grafiği İyileştirmesi</li>' +
          '  <li>Güvenlik Önlemleri</li>' +
          '  <li>Performans İyileştirmesi</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '  <li>Bugfix: Giriş Otomatik Doldurma Hatası</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.6',
        INFO: '<ul>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Navigasyon Servisi Yeni Kurallar</li>' +
          '  <li>ExchangeResolver İyileştirmesi</li>' +
          '  <li>User LoggedInResolver İyileştirmesi</li>' +
          '  <li>Pre Sale Navigasyon Menü</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.4.5',
        INFO: '<ul>' +
          '  <li>IOS Kullanıcı Anonim Veri Paylaşımı İzni</li>' +
          '  <li>Mobil Android-IOS Market Derin Link Bağlantısı</li>' +
          '  <li>Navigasyon Servisi Performans İyileştirmesi</li>' +
          '  <li>Derinlik Grafiği Düzeltmesi</li>' +
          '  <li>Bugfix: Alım-Satım Geçmişi Sıralama Düzeltmesi</li>' +
          '  <li>Kimlik Doğrulama Düzeltmesi</li>' +
          '  <li>Bugfix: Firebase Eklenti Düzeltmesi</li>' +
          '  <li>Bugfix: Appsflyer Eklenti Düzeltmesi</li>' +
          '  <li>Bugfix: Facebook Eklenti Düzeltmesi</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
    ],
    CHANGELOG: [  // obsolete
      {
        VERSION: '0.3.8',
        INFO: '<ul>' +
          '  <li>Bugfix: Form Başlangıç Değeri Düzeltmesi</li>' +
          '  <li>Bugfix: Navigasyon Servisi LoggedIn Guard Düzeltmesi</li>' +
          '  <li>Bugfix: Al-Sat Formları Ondalık Nokta Düzeltmesi</li>' +
          '  <li>Bugfix: IOS Snack Bar Animasyon Düzeltmesi </li>' +
          '  <li>Giriş Yapınca İstenilen Yere Devam Etme</li>' +
          '  <li>IPhone Güvenli Alan</li>' +
          '  <li>Uygulama Portre Modu Kısıtlaması</li>' +
          '  <li>Bugfix: Dil Değiştirince Seçilen Duyurular</li>' +
          '  <li>Bugfix: IPhone Fotoğraf Yükleme Hatası</li>' +
          '  <li>Bugfix: Gelişmiş Al-Sat Sayfa Yüklenme Hatası</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.7',
        INFO: '<ul>' +
          '  <li>Giriş Yapınca İstenilen Yere Devam Etme</li>' +
          '  <li>IPhone Güvenli Alan</li>' +
          '  <li>Uygulama Portre Modu Kısıtlaması</li>' +
          '  <li>Bugfix: Dil Değiştirince Seçilen Duyurular</li>' +
          '  <li>Bugfix: IPhone Fotoğraf Yükleme Hatası</li>' +
          '  <li>Bugfix: Gelişmiş Al-Sat Sayfa Yüklenme Hatası</li>' +
          '  <li>Performans Optimizasyonları</li>' +
          '  <li>Stil İyileştirmeler</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.6',
        INFO: '<ul>' +
          '  <li>Bugfix: Navigasyon Servis Hata Gidermesi</li>' +
          '  <li>Bugfix: IOS Platform için Resim Formatı Hata Gidermesi</li>' +
          '  <li>Duyuru ve Kariyer Güncellemesi</li>' +
          '  <li>IOS Platform Status Barı Renk Güncellemesi</li>' +
          '  <li>IOS Platform Overscroll Kaldırıldı</li>' +
          '  <li>Performans İyileştirmeleri</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.5',
        INFO: '<ul>' +
          '  <li>Bugfix: Rehber Yüksekliği</li>' +
          '  <li>Bugfix: WEBP-JPG</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.4',
        INFO: '<ul>' +
          '  <li>Derleme Optimizasyonu İyileştirmesi</li>' +
          '  <li>Navigasyon Servisi Güncellemesi</li>' +
          '  <li>Resim Format İyileştirmesi</li>' +
          '  <li>Bağlantı Yokken Tekrar Deneme</li>' +
          '  <li>Bugfix: Emirdeki TL Miktarı</li>' +
          '  <li>Bugfix: Derinlik Grafiği Geçişi</li>' +
          '  <li>Performans İyileştirmeleri</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.3',
        INFO: '<ul>' +
          '  <li>WebSocket Güncellemesi</li>' +
          '  <li>Navigasyon Servisi Güncellemesi</li>' +
          '  <li>Daha açık bildirimler</li>' +
          '  <li>2FA Şifre Algılama</li>' +
          '  <li>Bugfix: "Socket.io ID gerekli" uyarısı</li>' +
          '  <li>Performans İyileştirmeleri</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.2',
        INFO: '<ul>' +
          '  <li>WebSocket Güncellemesi</li>' +
          '  <li>Stabil Internet Bağlantısı</li>' +
          '  <li>Bugfix: Al-Sat Formları Ondalık Nokta Düzeltmesi</li>' +
          '  <li>Bugfix: Global Saat Düzeltmesi</li>' +
          '  <li>Hata Gidermeler</li>' +
          '</ul>',
      },
      {
        VERSION: '0.3.1',
        INFO: '<ul>' +
          '  <li>Language Change Fix</li>' +
          '  <li>Backend V2 Merge</li>' +
          '  <li>Fix: User Trade History Fix</li>' +
          '  <li>Minor fixes</li>' +
          '</ul>',
      },
    ],
    ANNOUNCEMENTS_TITLES: announcements_titles,
  },
  DELISTING: {
    "BOD": {
      TITLE: '"BOD" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 28.08.2020 tarihinde listelenmiş olan Yalçın Gökçebağ’ın BODRUM eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) BOD/TRY işlem çifti 19.02.24 – 17:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/bodrum-muzayedeye-cikiyor" target="_blank">Daha detaylı bilgi için tıklayınız.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "MAR": {
      TITLE: '"MAR" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 28.08.2020 tarihinde listelenmiş olan Nejad Melih Devrim’ın ABSTRAIT MARRON eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) MAR/TRY işlem çifti 26.04.24 – 16:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "ORC": {
      TITLE: '"ORC" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 22.01.2021 tarihinde listelenmiş olan Adnan Turani’nin ORCHESTRA eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) ORC/TRY işlem çifti 31.07.2024 at 15:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "IST": {
      TITLE: '"IST" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 15.04.2021 tarihinde listelenmiş olan Devrim Erbil’ın SÜLEYMANİYE\'DEN İSTANBUL\'A eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) IST/TRY işlem çifti 26.04.24 – 16:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "APT": {
      TITLE: '"APT" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 07.06.2021 tarihinde listelenmiş olan Nuri Abaç’ın APARTMAN eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) APT/TRY işlem çifti 31.07.2024 - 15:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "ULC": {
      TITLE: '"ULC" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 28.08.2020 tarihinde listelenmiş olan Ömer Uluç’un İsimsiz eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) ULC/TRY işlem çifti 31.07.2024 - 15:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    "MATA": {
      TITLE: '"MATA" MÜZAYEDEYE ÇIKIYOR',
      TEXT: [
        'Artiox’ta 22.03.2023 tarihinde listelenmiş olan Mustafa Ata’nın İsimsiz eseri müzayedeye çıkıyor.',
        'Eser satış politikamız gereğince (Kul. Söz. Madde 6) MATA/TRY işlem çifti 31.07.2024 - 15:00 tarihinde işleme kapatılacak ve son işlem fiyatı baz alınarak eser, müzayedede satışa çıkarılacaktır.',
        '<strong><a class="delisting-text-black" href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Daha önceki eser Exit\'ini incelemek için tıklayınız.</a></strong>',
        '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">Müzayedeyi buradan inceleyebilirsiniz.</a></strong>',
        'Bu süreçle ilgili herhangi bir sorunuz olursa, lütfen <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> adresine e-posta gönderin veya platformumuzdaki canlı destek hizmetimizi kullanın.',
      ],
    },
    BUTTON_TEXT: 'Esere Dön',
    ART_PRODUCTS: ['BOD/TRY', 'MAR/TRY', 'IST/TRY', 'APT/TRY', 'MATA/TRY',]
  },
  RESPONSE_TABLE: {
    101: {code: 101, is_okay: false, message: 'Uygun olmayan parametre hatası.'},
    102: {code: 102, is_okay: true, message: '$currency_id. para birimi eklendi.'},
    103: {code: 103, is_okay: true, message: '$art_product_id. sanat ürünü eklendi.'},
    104: {code: 104, is_okay: true, message: '$auction_product_id. müzayede ürünü eklendi.'},

    201: {code: 201, is_okay: false, message: 'Bu e-posta adresi kullanımda.'},
    202: {code: 202, is_okay: true, message: 'E-posta kaydı başarıyla tamamlandı.'},
    203: {code: 203, is_okay: true, message: 'Kayıt başarıyla tamamlandı.'},
    204: {code: 204, is_okay: false, message: 'E-posta veya şifre yanlış.'},
    205: {code: 205, is_okay: false, message: 'Hesap bloke edildi/E-posta adresinizi doğrulamanız gerekiyor.'},
    206: {code: 206, is_okay: false, message: 'Bu IP kara listeye alındı.'},
    207: {code: 207, is_okay: false, message: '2FA\'nızı doğrulamanız gerekir.'},
    208: {code: 208, is_okay: false, message: 'Geçersiz KOD.'},
    209: {code: 209, is_okay: true, message: 'Giriş başarılı.'},
    210: {code: 210, is_okay: false, message: 'Yeni IP\'ye izin vermelisiniz, lütfen e-postalarınızı kontrol edin.'},
    211: {
      code: 211,
      is_okay: false,
      message: 'IP doğrulama postası yeniden gönderildi, lütfen e-postalarınızı kontrol edin.'
    },
    212: {code: 212, is_okay: false, message: 'Oturum anahtarı gerekli.'},
    213: {code: 213, is_okay: true, message: 'Çıkış başarılı.'},
    214: {
      code: 214,
      is_okay: false,
      message: 'Kimlik doğrulama başarısız oldu. Geçersiz/Süresi dolmuş oturum anahtarı.'
    },
    215: {code: 215, is_okay: false, message: 'Kimlik doğrulama başarısız oldu.'},
    216: {
      code: 216,
      is_okay: false,
      message: 'Yönetici kimlik doğrulaması gerekli. Geçersiz/Süresi dolmuş oturum anahtarı.'
    },
    217: {code: 217, is_okay: false, message: 'Yönetici kimlik doğrulaması başarısız oldu.'},
    218: {code: 218, is_okay: true, message: 'Yönetici Girişi başarılı.'},
    219: {code: 219, is_okay: true, message: 'KYC doğrulandı.'},
    220: {code: 220, is_okay: false, message: 'Bu KYC bilgisi zaten kayıtlı.'},
    221: {code: 221, is_okay: false, message: 'Yanlış kimlik bilgileri.'},
    222: {code: 222, is_okay: false, message: 'KYC bu hesap için zaten onaylandı.'},
    223: {code: 223, is_okay: false, message: 'Yanlış şifre.'},
    224: {code: 224, is_okay: false, message: 'İlgili hesap için 2FA zaten etkin.'},
    225: {
      code: 225,
      is_okay: true,
      message: '2FA kurulumu başarıyla tamamlandı. Doğrulama KODU etkinleştirilmeyi bekliyor.'
    },
    226: {code: 226, is_okay: true, message: '2FA başarıyla doğrulandı.'},
    227: {code: 227, is_okay: false, message: 'Yanlış kod.'},
    228: {code: 228, is_okay: false, message: 'Yanlış şifre.'},
    229: {code: 229, is_okay: true, message: 'Parola başarıyla değiştirildi.'},
    230: {code: 230, is_okay: false, message: 'Geçersiz e-posta adresi.'},
    231: {code: 231, is_okay: true, message: 'Şifre değiştirme isteği gönderildi.'},
    232: {code: 232, is_okay: false, message: 'Geçersiz anahtar.'},
    233: {code: 233, is_okay: true, message: 'İkinci seviye KYC isteği başarıyla sıraya alındı.'},
    234: {code: 234, is_okay: false, message: 'Hesap seviyeniz en az 1 olmalıdır.'},
    235: {code: 235, is_okay: false, message: 'KYC bilgileri banka hesap bilgileriyle eşleşmiyor.'},
    236: {code: 236, is_okay: false, message: 'Banka hesap bilgisi hatası.'},
    237: {code: 237, is_okay: true, message: 'Banka hesabı başarıyla eklendi.'},
    238: {code: 238, is_okay: false, message: 'Maksimum dosya yükleme limiti (16MB) aşıldı.'},
    239: {code: 239, is_okay: false, message: 'Yıl yaşınız 18 yaşından büyük olmalıdır.'},
    240: {code: 240, is_okay: false, message: 'Admin seviyesi yetersiz.'},
    241: {code: 241, is_okay: false, message: 'Bu hesap için 2FA zaten devre dışı bırakılmış.'},
    242: {code: 242, is_okay: true, message: '2FA başarıyla kaldırıldı.'},
    243: {code: 243, is_okay: true, message: 'Parola geçerli.'},
    244: {code: 244, is_okay: false, message: 'Geçersiz kullanıcı ID.'},
    245: {code: 245, is_okay: false, message: 'Geçersiz kullanıcı bilgisi.'},
    246: {
      code: 246,
      is_okay: false,
      message: 'Seyive 2 KYC yapmak için Seviye 1 kullanıcı olmanız gerekmektedir./Güncel Seviye 2 başvurunuz sonuçlanana kadar fotoğraf yükleyemezsiniz.'
    },
    247: {code: 247, is_okay: false, message: 'KYC hatası. Lütfen daha sonra tekrar deneyin.'},
    248: {code: 248, is_okay: false, message: 'Hesabınız en az 2. seviye olmalıdır.'},
    249: {code: 249, is_okay: false, message: 'Geçersiz blok zincir adresi.'},
    250: {code: 250, is_okay: true, message: 'Kripto cüzdanı başarıyla eklendi.'},
    251: {code: 251, is_okay: false, message: 'Geçersiz adres.'},
    252: {code: 252, is_okay: true, message: 'E-posta başarıyla değiştirildi.'},
    253: {code: 253, is_okay: false, message: 'Yanlış e-posta.'},
    254: {code: 254, is_okay: false, message: 'Admin e-postası değiştirilemez.'},
    255: {code: 255, is_okay: false, message: 'Referans kodu bulunamadı.'},
    256: {code: 256, is_okay: true, message: 'Hesap bilgilerin güncellendi.'},
    257: {code: 257, is_okay: true, message: 'Kullanıcı bilgileri güncellendi.'},
    258: {code: 258, is_okay: false, message: 'En fazla 3 fatura adresi eklenebilir.'},
    259: {code: 259, is_okay: false, message: `Fatura türü hatası. Tür 'bireysel' veya 'kurumsal' olmalıdır.`},
    260: {code: 260, is_okay: false, message: 'Fatura bilgisi başlığı güncellenemedi. Başlık yok.'},
    261: {code: 261, is_okay: true, message: 'Fatura bilgisi başarıyla eklendi.'},
    262: {code: 262, is_okay: false, message: 'En fazla 3 tane teslimat adresi ekleyebilirsiniz.'},
    263: {code: 263, is_okay: false, message: 'Teslimat adresi güncellenemedi. ID bulunamadı.'},
    264: {code: 264, is_okay: true, message: 'Teslimat adresi başarıyla eklendi.'},
    265: {code: 265, is_okay: false, message: 'Teslimat bilgisi güncellenemedi'},
    266: {code: 266, is_okay: true, message: 'Teslimat bilgisi başarıyla güncellendi.'},
    267: {code: 267, is_okay: false, message: 'En fazla 10 adet banka hesabı ekleyebilirsiniz.'},
    268: {
      code: 268,
      is_okay: false,
      message: 'Teslimat bilgisi hatası. Teslimat türü \'elden teslimat\' veya \'kargo\' olmalıdır'
    },
    269: {
      code: 269,
      is_okay: false,
      message: 'Teslimat durumu hatası. Durum  \'hazırlanıyor\', \'gönderildi\', \'yolda\', \'dağıtımda\' ya da \'teslim edildi\' olmalıdır'
    },
    270: {
      code: 270,
      is_okay: false,
      message: 'Sipariş durumu error. Dırım \'beklemede\', \'onaylanıyor\', \'onaylandı\', \'hazırlandı\' ya da \'teslim edildi\' olmalıdır'
    },
    271: {code: 271, is_okay: true, message: 'Fatura bilgisi başarıyla güncellendi.'},
    272: {code: 272, is_okay: true, message: 'Teslimat adresi başarıyla güncellendi.'},
    273: {
      code: 273,
      is_okay: true,
      message: 'Hesap doğrulama e-postası yeniden gönderildi, lütfen e-postalarınızı kontrol edin.'
    },
    274: {code: 274, is_okay: true, message: 'Hesap doğrulandı.'},
    275: {code: 275, is_okay: false, message: 'Girdiğiniz aktivasyon kodu hatalı.'},
    276: {code: 276, is_okay: false, message: 'Hesap doğrulama kodunun süresi doldu.'},

    301: {code: 301, is_okay: true, message: 'Sanat Ürünü yatırma işlemi başarıyla tamamlandı.'},
    302: {code: 302, is_okay: true, message: 'Sanat Ürünü çekme işlemi başarıyla tamamlandı.'},
    303: {code: 303, is_okay: false, message: 'Yeterli bakiye yok.'},
    304: {code: 304, is_okay: true, message: 'Para yatırma işlemi başarıyla tamamlandı.'},
    305: {code: 305, is_okay: true, message: 'Para çekme işlemi başarıyla tamamlandı.'},
    306: {code: 306, is_okay: true, message: 'Para çekme talebi başarıyla kaydedildi.'},
    307: {code: 307, is_okay: false, message: 'Banka bilgileri uyuşmuyor.'},
    308: {code: 308, is_okay: false, message: 'Talep ettiğiniz para çekme miktarı, para çekme limitinizi aştı.'},
    309: {code: 309, is_okay: true, message: 'Para çekme talebi başarıyla yürütüldü.'},
    310: {code: 310, is_okay: false, message: 'Para çekme talebi sıraya alınmadı.'},
    311: {code: 311, is_okay: false, message: 'Para çekme talebi bulunamadı.'},
    312: {code: 312, is_okay: true, message: 'Para yatırma işlemi başarıyla gerçekleştirildi.'},
    313: {code: 313, is_okay: false, message: 'Para yatırma miktarı, yatırma limitinizi aştı.'},
    314: {code: 314, is_okay: false, message: 'Kripto cüzdanı bilgisi eşleşmedi.'},
    315: {code: 315, is_okay: false, message: 'Talep edilen blokzinciri kripto cüzdanı ile uyuşmuyor.'},
    316: {code: 316, is_okay: true, message: 'Para çekme işlemi teklif detayları.'},
    317: {code: 317, is_okay: false, message: 'Para çekme işlemi teklifi imzalandı.'},
    318: {code: 318, is_okay: false, message: 'Para çekme işlemi teklifi bulunamadı.'},
    319: {code: 319, is_okay: false, message: 'Para çekme işlemi teklifi iptal edildi'},
    320: {
      code: 320,
      is_okay: false,
      message: 'Para çekme işlemi teklifi için geçersiz statüs. "approve"(onay) ya da "cancel"(iptal) deneyin.'
    },

    401: {code: 401, is_okay: false, message: 'Sanat Ürünü listelenmemiş.'},
    402: {code: 402, is_okay: false, message: 'Tutar sıfırdan büyük olmalıdır.'},
    403: {code: 403, is_okay: false, message: 'Fiyat sıfırdan büyük olmalıdır.'},
    404: {code: 404, is_okay: false, message: 'Maksimum fiyat($max_price) sınırına ulaşıldı.'},
    405: {code: 405, is_okay: false, message: 'Minimum fiyat($min_price) sınırına ulaşıldı.'},
    406: {
      code: 406,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet limit $type emri başarıyla kaydedildi.'
    },
    407: {code: 407, is_okay: false, message: 'Yeterli bakiye yok.'},
    408: {code: 408, is_okay: false, message: 'Maksimum miktar($max_amount) sınırına ulaşıldı.'},
    409: {code: 409, is_okay: true, message: '$symbol için $amount adet market $type emri başarıyla kaydedildi.'},
    410: {code: 410, is_okay: false, message: 'Hata. Ön Satış başlamadı.'},
    411: {code: 411, is_okay: false, message: 'Hata. Satış tamamlandı.'},
    412: {code: 412, is_okay: false, message: 'Hata. Ön Satış tedarik stoğu tamamlandı.'},
    413: {code: 413, is_okay: false, message: 'Hata. Genel Satış tedarik stoğu tamamlandı.'},
    414: {
      code: 414,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet ön satış siparişi başarıyla kaydedildi.'
    },
    415: {code: 415, is_okay: true, message: 'İptal emri başarıyla kaydedildi.'},
    416: {code: 416, is_okay: false, message: 'Hata. Müzayede başlamadı.'},
    417: {code: 417, is_okay: false, message: 'Hata. Müzayede tamamlandı.'},
    418: {code: 418, is_okay: false, message: 'Fiyat açılış fiyatına($opening_value) eşit veya daha büyük olmalı.'},
    419: {
      code: 419,
      is_okay: false,
      message: 'Fiyat güncel fiyattan($current_value) yüksek ve adımın($value_step) tam katları olmalı.'
    },
    420: {code: 420, is_okay: true, message: '$type için $price fiyatından teklif başarıyla kaydedildi.'},
    421: {code: 421, is_okay: true, message: "Delist emri başarıyla kaydedildi."},
    422: {code: 422, is_okay: false, message: "Hata. Sale state 'listing' olmalı."},
    423: {code: 423, is_okay: false, message: "Hata. Delist fiyatı eserin güncel fiyatından fazla olmalı."},
    424: {code: 424, is_okay: false, message: "Hata. İşlem motoru durdurulmuş olmalı."},

    501: {
      code: 501,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet market satın alma emriniz başarıyla tamamlandı.'
    },
    502: {
      code: 502,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet limit satın alma emriniz başarıyla tamamlandı.'
    },
    503: {
      code: 503,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet market satış emriniz başarıyla tamamlandı.'
    },
    504: {
      code: 504,
      is_okay: true,
      message: '$symbol için $price fiyatından $amount adet limit satış emriniz başarıyla tamamlandı.'
    },
    505: {
      code: 505,
      is_okay: true,
      message: '$price fiyatından $amount adet ön satıştan alma emriniz başarıyla tamamlandı.'
    },
    506: {code: 506, is_okay: true, message: '$symbol için $type emrinizin iptali başarıyla tamamlandı.'},
    507: {code: 507, is_okay: true, message: '$symbol için iptal emriniz başarıyla tamamlandı.'},
    508: {code: 508, is_okay: true, message: '$price fiyatından teklifiniz ulaştı.'},
    509: {code: 509, is_okay: false, message: '$price fiyatından teklifiniz geçildi.'},
    510: {code: 510, is_okay: true, message: '$price fiyatından teklifiniz tamamlandı.'},
    511: {code: 511, is_okay: true, message: "$symbol için delist emri başarıyla tamamlandı."},

    602: {code: 602, is_okay: false, message: 'Yeterli stok yok.'},

    801: {code: 801, is_okay: true, message: 'Para birimi başarıyla takaslandı.'},
    802: {code: 802, is_okay: false, message: 'Para birimi takası başarısız oldu. Yeterli stok yok.'},
    803: {code: 803, is_okay: true, message: 'Tedarik stoğu başarıyla eklendi.'},
    804: {code: 804, is_okay: false, message: 'İşlem motoru durduruldu.'},
    805: {code: 805, is_okay: false, message: 'Takas işlem motoru durduruldu.'},
    806: {code: 806, is_okay: true, message: 'İşlem motoru aktif.'},
    807: {code: 807, is_okay: true, message: 'Takas işlem Engine aktif.'},
    808: {code: 808, is_okay: false, message: 'Müzayede motoru durduruldu.'},
    809: {code: 809, is_okay: true, message: 'Müzayede motoru aktif.'},

    901: {code: 901, is_okay: true, message: 'İşlem başarılı.'},
    902: {code: 902, is_okay: false, message: 'İşlem başarısız.'},

    906: {code: 906, is_okay: false, message: 'E-posta bulunamadı.'},
    907: {code: 907, is_okay: false, message: 'E-posta $award ödülü ile zaten kayıtlı.'},
    908: {code: 908, is_okay: true, message: '$user_email $award kazandı.'},

    1001: {cookies_accepted: ''},
    1002: {user_email: ''},
    1003: {auction_agreement_accepted: false},
    1004: {user_agreement_accepted: false},

    1103: {code: 1003, is_okay: false, message: 'Bu bölüm, eserler markette listelendiğinde açılacaktır.'},
    1104: {code: 1004, is_okay: false, message: 'Bu işlemi yapabilmek için giriş yapmanız gerekmektedir.'},


    1201: {code: 1201, is_okay: false, message: 'Sanat eseri bulunamadı.'},
    1202: {code: 1202, is_okay: false, message: 'Para birimi bulunamadı.'},
    1203: {code: 1203, is_okay: false, message: 'Parite çifti bulunamadı.'},
    1204: {code: 1204, is_okay: false, message: 'Para birimi paritesi bulunamadı.'},
    1205: {code: 1205, is_okay: false, message: 'Zum seviyesi bulunamadı.'},
    1206: {code: 1206, is_okay: false, message: 'Limit sınırına ulaşıldı.'},
    1207: {code: 1207, is_okay: false, message: 'Geçersiz IP adresi.'},
    1208: {code: 1208, is_okay: false, message: 'Geçersiz email adresi.'},
    1209: {code: 1209, is_okay: false, message: 'Geçersiz emir türü.'},
    1210: {code: 1210, is_okay: false, message: 'Geçersiz işlem türü.'},
    1211: {code: 1211, is_okay: false, message: 'Geçersiz miktar.'},
    1212: {code: 1212, is_okay: false, message: 'Geçersiz fiyat.'},
    1213: {code: 1213, is_okay: false, message: 'Socket.io ID gerekli.'},
    1214: {code: 1214, is_okay: false, message: 'Hatalı parametre.'},
    1215: {
      code: 1215,
      is_okay: false,
      message: 'Test ortamına üye olmak için lütfen support@artiox.com adresine mail atın.'
    },
    1216: {code: 1216, is_okay: false, message: 'Parola 40 karakterden uzun olamaz.'},
    1217: {code: 1217, is_okay: false, message: 'Geçersiz TC NO.'},
    1218: {code: 1218, is_okay: false, message: 'Geçersiz isim.'},
    1219: {code: 1219, is_okay: false, message: 'Geçersiz soyisim.'},
    1220: {code: 1220, is_okay: false, message: 'Geçersiz birleştirilmiş isim bilgisi.'},
    1221: {code: 1221, is_okay: false, message: 'İstek sınırına ulaşıldı.'},
    1222: {code: 1222, is_okay: false, message: 'Geçersiz argüman.'},
    1223: {code: 1223, is_okay: false, message: 'Geçersiz doğrulayıcı.'},
    1224: {code: 1224, is_okay: false, message: 'Arka uç hatası.'},
    1225: {code: 1225, is_okay: false, message: 'Blokzinciri bulunamadı.'},
    1226: {code: 1226, is_okay: false, message: 'Müzayede eseri bulunamadı.'},

    2000: {code: 2000, is_okay: false, message: 'Captcha hatası, tekrar deneyin.'},
    2001: {code: 2001, is_okay: false, message: 'Giriş yapmanız gerekli.'},
    2002: {code: 2002, is_okay: false, message: 'Parolalar uyuşmuyor.'},
    2003: {code: 2003, is_okay: false, message: 'Gerekli alanlar düzgünce doldurulmalıdır.'},
    2004: {
      code: 2004, is_okay: false, message: 'En az 8 karakter, 1 büyük harf, 1 küçük harf, 1 sayı ve 1 özel karakter.',
      message_long: 'En az 8 karakter, 1 büyük ve küçük harf, 1 sayı ve 1 özel karakter içermelidir.'
    },
    2005: {code: 2005, is_okay: false, message: 'Dosya format hatası.'},
    2006: {code: 2006, is_okay: false, message: 'Geçerli bir e-posta adresi girin.'},
    2007: {code: 2007, is_okay: false, message: 'İşaretli alanların onaylanması zorunludur.'},
    2008: {code: 2008, is_okay: false, message: 'Bu alan boş bırakılamaz.'},
    2009: {code: 2009, is_okay: false, message: 'Aradığınız isim veri tabanında bulunamadı.'},
    2010: {code: 2010, is_okay: false, message: 'Lütfen geçerli bir telefon numarası girin.'},
    2011: {code: 2011, is_okay: false, message: 'Lütfen geçerli bir adres girin.'},
    2012: {code: 2012, is_okay: false, message: 'Lütfen gereken kısımları doldurun: '},
    2013: {code: 2013, is_okay: false, message: 'Lütfen geçerli bir vergi numarası giriniz. '},
    2014: {code: 2014, is_okay: false, message: 'En az 8 karakter içermelidir.'},
    2015: {code: 2015, is_okay: false, message: 'Büyük harf, küçük harf ve rakam içermelidir.'},
    2016: {code: 2016, is_okay: false, message: 'En az 1 büyük harf içermelidir.'},  // deprecated
    2017: {code: 2017, is_okay: false, message: 'Özel karakter içermelidir.'},
    2018: {code: 2018, is_okay: false, message: 'Bu alanın doldurulması zorunludur.'},
    2019: {code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'},
    2020: {code: 2020, is_okay: false, message: 'Kod 6 haneli olmalıdır.'},
  },
  ACTIVITY_TABLE: {
    701: {code: 701, reason: 'Yanlış şifre.', attempt: false},
    702: {code: 702, reason: 'Kara listeye alınmış IP oturum açma girişimi.', attempt: false},
    703: {code: 703, reason: 'Başarılı Giriş.', attempt: true},
    704: {code: 704, reason: 'Yeni IP\'ye izin vermelisiniz.', attempt: false},
    705: {code: 705, reason: 'IP doğrulama postası yeniden gönderildi.', attempt: false},
    706: {code: 706, reason: '2FA başarıyla etkinleştirildi.', attempt: true},
    707: {code: 707, reason: 'Parola başarıyla değiştirildi.', attempt: true},
    708: {code: 708, reason: 'Şifre değiştirme isteği gönderildi.', attempt: false},
    709: {code: 709, reason: 'Yeni IP\'den giriş algılandı.', attempt: false},
    710: {code: 710, reason: '2FA başarıyla kaldırıldı.', attempt: true},
    711: {code: 711, reason: 'E-posta başarıyla değiştirildi.', attempt: true},
  },
};
